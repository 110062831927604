import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';

//Material UI and styled imports
import * as CounterStyle from '../styles/utils/selectionCounterStyle';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const SelectionCounter = (props) => {

  
    const incrementCount = () => {
        props.onCountChange(props.value + 1,props.name);
    };

    const decrementCount = () => {
        if(props.name == 'Rooms' || props.name == 'Adults') {
            if(props.value > 1)
                props.onCountChange(props.value - 1,props.name);
        }
        else {
            if (props.value > 0) {
                props.onCountChange(props.value - 1,props.name);
            }
        }
    };

  

    return (
        <CounterStyle.CounterParent>
            <CounterStyle.CounterButton onClick={decrementCount} variant = "text">
                <RemoveIcon />
            </CounterStyle.CounterButton>
            <span>{props.value}</span>
            <CounterStyle.CounterButton onClick={incrementCount}  variant = "text">
                <AddIcon />
            </CounterStyle.CounterButton>
        </CounterStyle.CounterParent>
    );
};

export default SelectionCounter;