import React from 'react';
import { useSelector } from 'react-redux';

//Style Impports
import * as BookingHIStyle from '../../../../styles/Booking/bookingLPStyle';
import * as MobileHIStyle from '../../../../styles/Booking/mobileLPStyle';

const MobileLPInfo = () => {

    const hotelInfo = useSelector((state) => {
        return state.bookingDetails.hotelDetails;
    })

    const contactInfo = useSelector(state => state.bookingDetails.contactDetails?.find(contact => contact.contact_type == 'Phone'));

    const hotelfacilities = useSelector((state) => {
        return state.bookingDetails.hotelfacilities;
    });
    const hotelImages = useSelector(state => {
        return state.bookingDetails.hotelPhotos;
    });

    const getHotelFacilities = () => {
        let facilities = hotelfacilities.map(facility => {
            return <BookingHIStyle.HotelFacility key = { facility.facility_id }> { facility.facility_name } </BookingHIStyle.HotelFacility>
        });
        return facilities;
    }

    const getMapImage = () => {
        let img = hotelImages.find(img => img.photo_type == 'MAP');
        if(img)
            return img.photo
    }

    const getHotelAddressText = () => {
        if(hotelInfo.address.trim().endsWith(','))
            return `${hotelInfo.address} ${hotelInfo.city}, ${hotelInfo.state} - ${hotelInfo.pincode}`
        else
            return `${hotelInfo.address}, ${hotelInfo.city}, ${hotelInfo.state} - ${hotelInfo.pincode}`
    }

    return (
        <BookingHIStyle.BookingHIParent>
            <BookingHIStyle.AddressandMap>
                <BookingHIStyle.Address>
                    <MobileHIStyle.HotelName>
                        { hotelInfo.name }
                     </MobileHIStyle.HotelName>
                    <MobileHIStyle.HotelAddressBox>
                        <MobileHIStyle.HotelAddressImg></MobileHIStyle.HotelAddressImg>
                        <BookingHIStyle.HotelAdress>
                            { getHotelAddressText() }
                        </BookingHIStyle.HotelAdress>
                    </MobileHIStyle.HotelAddressBox>
                    <MobileHIStyle.HotelAddressBox>
                        <MobileHIStyle.HotelNumberImg></MobileHIStyle.HotelNumberImg>
                        <BookingHIStyle.HotelNumber onClick = {() => window.open(`tel:+91${contactInfo.contact_value}`,'_blank')}>
                            { contactInfo?.contact_value }
                        </BookingHIStyle.HotelNumber>
                    </MobileHIStyle.HotelAddressBox>
                </BookingHIStyle.Address>
                {/* <BookingHIStyle.Map>
                    <BookingHIStyle.MapImage src = { getMapImage() }>

                    </BookingHIStyle.MapImage>
                </BookingHIStyle.Map> */}
            </BookingHIStyle.AddressandMap>
            
            <BookingHIStyle.HotelDesc>
                <BookingHIStyle.HotelDescTypo>
                    { hotelInfo.description }
                </BookingHIStyle.HotelDescTypo>
            </BookingHIStyle.HotelDesc>
        </BookingHIStyle.BookingHIParent>
    )
}

export default MobileLPInfo;