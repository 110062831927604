import styled from "styled-components";

import Box from '@mui/material/Box';

export const AppBox = styled(Box)`
&& {
    display:flex;
    font-family: 'Red Hat Display';
    flex-direction:column;
    width: 100vw;
    background-color:white;
    box-sizing:border-box;
    position:relative;
    // @media (max-width: 576px) {
    //     position:static;
    // }
    height:100vh;
    overflow-y:auto;
    background: #ECECEC;
}`

