
import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//Internal Imports
import { RANDOMKEY } from '../../../../config/constants';

const MapContainer = (props) => {
    const mapStyles = {
      width: 'calc(100% - 2rem)',
      height: '200px', // Set the desired height here,
      borderRadius:'10px'
    };

    const customMarkerIcon = {
      url: 'styles/assets/location.svg',
      scaledSize: new props.google.maps.Size(32, 32) // Adjust the size as needed
    };

    return (
      <Map
        style={mapStyles}
        google={props.google}
        zoom={16}
        initialCenter={{ lat: props.lat, lng: props.lng }} // Default initial center
        disableDefaultUI={true}
      >
        <Marker position={{ lat: props.lat, lng: props.lng }} icon = { customMarkerIcon }/>
      </Map>
    );
}

export default GoogleApiWrapper({
  apiKey: RANDOMKEY
})(MapContainer);
