import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const WhatsAppContainer = styled(Box)`
&& {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    color: white;
    width:600px;
    left: 50%;
    transform: translate(-50%);
    z-index:10;
    @media (max-width: 576px) {
        width:100vw;
        left: unset;
        transform: unset;
    }
}`

export const WhatsAppBox = styled(Box)`
&& {
    position:absolute;
    right:1rem;
    bottom:0px;
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: space-around;
    cursor:pointer;
    align-items: center;
    border-radius: 50%;
    background: rgb(14,187,95);
    background: linear-gradient(180deg, rgba(14,187,95,1) 0%, rgba(7,165,80,1) 53%);
    z-index:2;
    @media (max-width: 576px) {
        width:50px;
        height:50px;
        right:0.5rem;
    }
}`

export const WhatsAppBoxContent = styled(Typography)`
&& {
    font-family:'Red Hat Display';
    font-size:0.8rem;
    text-transform:uppercase;
}`

export const WhatsAPPICON = styled(WhatsAppIcon)`
&& {
    color:white;
    font-size:2.5rem;
    @media (max-width: 576px) {
        font-size:2.5rem;
    }
}`