import React from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
var numeral = require('numeral'); 

//Style and Material UI Imports
import * as RCStyle from '../../../../styles/Booking/cartStyle';

import { 
    updateMealsInRoom,
    removeMealTypeInRoom
 } from '../../../../slices/bookingDetails';

const RoomCart = () => {

    const cart = useSelector(state => {
        return state.bookingDetails.cart;
    })

    const history = useHistory();

    const hotelCategory = useSelector(state => {
        return state.bookingDetails.hotelCategory;
    })

    const dispatch = useDispatch();

    const getCardIcon = (selectedOccupancy) => {
        switch(selectedOccupancy) {
            case "single":
                return (
                    <RCStyle.SingleOccupancyImg />
                )
                break;
            case "double":
                return (
                    <RCStyle.DoubleOccupancyImg />
                )
                break;
            case "triple":
                return (
                    <RCStyle.TripleOccupancyImg />
                )
                break;
        }
    }

    const getCardIconCount = (selectedOccupancy) => {
        switch(selectedOccupancy) {
            case "single":
                return 1;
                break;
            case "double":
                return 2;
                break;
            case "triple":
                return 3;
                break;
        }
    }

    const addMealHandler = (roomInfo,mealType,roomTypeName,occupancy) => {
        let multiplier = 1;
        if(occupancy == 'double')
            multiplier = 2;
        if(occupancy == 'triple')
            multiplier = 3;
        let priceAttr = hotelCategory.find(cat => cat.hotel_room_type_id == roomInfo.roomTypeId).attributes;
        if(roomInfo.mealInfo.hasOwnProperty(mealType)) {
            let mealInfo = { ...roomInfo.mealInfo };
            delete mealInfo[mealType];
            delete mealInfo['Meal'];
            if(mealType == 'Meal')
                mealInfo = {};
            dispatch(removeMealTypeInRoom({
                name:roomTypeName,
                occupancy,
                uuid:roomInfo.id,
                mealInfo
            }));    
        }
        else {
            let mealInfo = { ...roomInfo.mealInfo };
            mealInfo[mealType] = multiplier * (priceAttr[mealType]);
            if(mealInfo['breakfast'] && mealInfo['lunch'] && mealInfo['dinner'])
                mealInfo['Meal'] = (multiplier *  priceAttr['breakfast']) + (multiplier * priceAttr['lunch']) + (multiplier * priceAttr['dinner']);
            if(mealType == 'Meal') {
                mealInfo['Meal'] = (multiplier *  priceAttr['breakfast']) + (multiplier * priceAttr['lunch']) + (multiplier * priceAttr['dinner']);
                mealInfo['breakfast'] = multiplier *  priceAttr['breakfast'];
                mealInfo['lunch'] = multiplier *  priceAttr['lunch'];
                mealInfo['dinner'] =  multiplier *  priceAttr['dinner'];
            }
            dispatch(updateMealsInRoom({
                name:roomTypeName,
                occupancy,
                uuid:roomInfo.id,
                mealInfo
            }))
        }
    }

    const getMealPrice = (roomInfo,mealType) => {
        let priceAttr = hotelCategory.find(cat => cat.hotel_room_type_id == roomInfo.roomTypeId).attributes;
        if(mealType == 'Meal') {
            return numeral(parseInt(priceAttr['breakfast']) + parseInt(priceAttr['lunch']) + parseInt(priceAttr['dinner'])).format('0,0');
        }
        else
            return numeral(priceAttr[mealType]).format('0,0');
    }

    const getTextForMealButton = (roomInfo,mealType) => {
        if(roomInfo.mealInfo) {
            if(roomInfo.mealInfo.hasOwnProperty(mealType))
                return 'REMOVE';
        }
        return 'ADD';
    }

    const getMealsBody = (roomInfo,roomTypeName,suffix) => {
        return (
            <>
                <RCStyle.MealCard paddingRight>
                    <RCStyle.MealTitleParent>
                        <RCStyle.MealTitle>
                            All Meals
                        </RCStyle.MealTitle>
                        <RCStyle.MealPrice>
                            <span>&#x20B9;</span>
                            { getMealPrice(roomInfo,'Meal') }
                            <span> / Person</span>
                        </RCStyle.MealPrice>
                    </RCStyle.MealTitleParent>
                    <RCStyle.MealButton tabIndex={1} onClick =  { () => addMealHandler(roomInfo,'Meal',roomTypeName,suffix) }>
                        { getTextForMealButton(roomInfo,'Meal') }
                    </RCStyle.MealButton>
                </RCStyle.MealCard>
                <RCStyle.MealCard>
                    <RCStyle.MealTitleParent>
                        <RCStyle.MealTitle>
                            Breakfast
                        </RCStyle.MealTitle>
                        <RCStyle.MealPrice>
                            <span>&#x20B9;</span>
                            { getMealPrice(roomInfo,'breakfast') }
                            <span> / Person</span>
                        </RCStyle.MealPrice>
                    </RCStyle.MealTitleParent>
                    <RCStyle.MealButton tabIndex={1} onClick =  { () => addMealHandler(roomInfo,'breakfast',roomTypeName,suffix) }>
                        { getTextForMealButton(roomInfo,'breakfast') }
                    </RCStyle.MealButton>
                </RCStyle.MealCard>
                <RCStyle.MealCard paddingRight>
                    <RCStyle.MealTitleParent>
                        <RCStyle.MealTitle>
                            Lunch
                        </RCStyle.MealTitle>
                        <RCStyle.MealPrice>
                            <span>&#x20B9;</span>
                            { getMealPrice(roomInfo,'lunch') }
                            <span> / Person</span>
                        </RCStyle.MealPrice>
                    </RCStyle.MealTitleParent>
                    <RCStyle.MealButton tabIndex={1} onClick =  { () => addMealHandler(roomInfo,'lunch',roomTypeName,suffix) }>
                        { getTextForMealButton(roomInfo,'lunch') }
                    </RCStyle.MealButton>
                </RCStyle.MealCard>
                <RCStyle.MealCard>
                    <RCStyle.MealTitleParent>
                        <RCStyle.MealTitle>
                            Dinner
                        </RCStyle.MealTitle>
                        <RCStyle.MealPrice>
                            <span>&#x20B9;</span>
                            { getMealPrice(roomInfo,'dinner') }
                            <span> / Person</span>
                        </RCStyle.MealPrice>
                    </RCStyle.MealTitleParent>
                    <RCStyle.MealButton tabIndex={1} onClick =  { () => addMealHandler(roomInfo,'dinner',roomTypeName,suffix) }>
                        { getTextForMealButton(roomInfo,'dinner') }
                    </RCStyle.MealButton>
                </RCStyle.MealCard>
            </>
        )
    }

    

    const getSelectedHotelCard = (roomInfo,suffix,roomTypeName) => {
        return (
            <RCStyle.CardParent>
                <RCStyle.CardHeader>
                    <RCStyle.CardTitleParent>
                        <RCStyle.CardTitle>{ roomTypeName }</RCStyle.CardTitle>
                        <RCStyle.CardIconParent>
                            { getCardIcon(suffix) }
                            {getCardIconCount(suffix)} Adult
                        </RCStyle.CardIconParent>
                    </RCStyle.CardTitleParent>
                    <RCStyle.CardPrice>
                    <span>&#x20B9;</span> {numeral(roomInfo.price).format('0,0')}<br/>
                        <span>(inc. taxes)</span>
                    </RCStyle.CardPrice>
                </RCStyle.CardHeader>
                <RCStyle.CardBody>
                    { getMealsBody(roomInfo,roomTypeName,suffix) }
                </RCStyle.CardBody>
            </RCStyle.CardParent>
        )
    }

    const generateCardsFromCart = () => {
        let TotalRooms = 0;
        let TotalAmount = 0;
        let totalAdults = 0;
        let cards = [];
        for(var o in cart) {
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                TotalRooms += roomsOcc.length;
                if(room == 'single')
                    totalAdults += (roomsOcc.length);
                else if(room == 'double') 
                    totalAdults += (roomsOcc.length * 2);
                else if(room == 'triple')
                    totalAdults += (roomsOcc.length * 3);
                for(let i = 0; i < roomsOcc.length; i ++) {
                    cards.push(getSelectedHotelCard(roomsOcc[i],room,o));
                    TotalAmount += parseInt(roomsOcc[i].price);
                    if(roomsOcc[i].mealInfo['Meal']) {
                        TotalAmount += parseInt(roomsOcc[i].mealInfo['Meal']);
                    }
                    else {
                        for(var mealType in roomsOcc[i].mealInfo) {
                            TotalAmount += parseInt(roomsOcc[i].mealInfo[mealType]);
                        }
                    }
                }
            }
        }
        if(cards.length > 0) {
            cards.push(
                <RCStyle.CartFooter>
                    <RCStyle.FooterTotal>
                        <RCStyle.TotalRooms>
                            <RCStyle.TotalRoomsTitle>Total Rooms</RCStyle.TotalRoomsTitle>
                            <RCStyle.TotalRoomsCount>{ `${TotalRooms} Rooms & ${totalAdults} Adults `}</RCStyle.TotalRoomsCount>
                        </RCStyle.TotalRooms>
                        <RCStyle.TotalAmount>
                            <RCStyle.TotalAmountValue>
                                <span>&#x20B9;</span>
                                { numeral(TotalAmount).format('0,0') }
                            </RCStyle.TotalAmountValue>
                            <RCStyle.TotalAmountValueTax>(Inc. Taxes)</RCStyle.TotalAmountValueTax>
                        </RCStyle.TotalAmount>
                    </RCStyle.FooterTotal>
                    <RCStyle.NextButton onClick = { nextButtonClickHandler }>
                        Make Reservation
                    </RCStyle.NextButton>
                </RCStyle.CartFooter>
            )
        }
        return cards;

    }

    const nextButtonClickHandler = () => {
        setTimeout(() => {
            history.push('/checkout');
        },1000);
    }

    return (
        <RCStyle.CartParent>
            <RCStyle.CartHeader>
                Your Cart
            </RCStyle.CartHeader>
            <RCStyle.CartBody>
                { generateCardsFromCart() }
            </RCStyle.CartBody>
        </RCStyle.CartParent>
    )
}

export default RoomCart;