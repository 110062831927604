import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { createPortal } from "react-dom";
var numeral = require('numeral');
import dayjs from "dayjs";

//Style and Material UI Imports
import * as MobileHRStyle from '../../../../styles/BookingMobile/mobileHRStyle';
// var Carousel = require('react-responsive-carousel').Carousel;
import Carousel from 'react-material-ui-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


//InternalImports
import { HOURLY_STAY, NINE_HOURS, ROOMONLY, RoomsDropDownRange, SIX_HOURS, THREE_HOURS } from "../../../../config/constants";
import CounterComponent from '../../../../utils/CounterComponent';
import { addRoomsToCart,clearCart } from "../../../../slices/bookingDetails";
import MobileHourlyRoomCart from "./MobileHourlyRoomCart";
import DialogBox from "../../../../utils/DialogBox";

const MobileHourlyRoomCategoryInfo = () => {

    const cart = useSelector(state => {
        return state.bookingDetails.cart;
    })

    const [noOfRooms, setNoofRooms] = useState({});

    const [selectedSlot, setSelectedSlot] = useState(THREE_HOURS);

    const[openDialog, setOpenDialog] = useState(false);

    const hourlySelectionInfo = useSelector(state => state.bookingDetails.selectionCriteria.hourlySelectionInfo);
    

    const history = useHistory();
    const dispatch = useDispatch();
    const hotelRoomInfo = useSelector(state => {
        return {
            hotelRooms:state.bookingDetails.hotelRooms,
            hotelCategory:state.bookingDetails.hotelCategory,
            hotelRoomImages:state.bookingDetails.hotelRoomImages
        }
    })

    //rooms added to cart 
    const selectedRooms = useSelector(state => state.bookingDetails.selectedRooms);

    const getDefaultState = () => {
        let roomInfo = {};
        hotelRoomInfo.hotelRooms.map(room => {
            let roomCat = hotelRoomInfo.hotelCategory.find(cat => cat.hotel_room_type_id == room.hotel_room_type_id);
            roomInfo[room.name] = {
                roomTypeId:room.hotel_room_type_id,
                roomTypeCategoryID:room.hotel_room_type_category_id
            };
        })
        return roomInfo;
    }

    const getHotelOptionsSelected = (hotelRoom,suffix) => {
        let selectedValue = noOfRooms[hotelRoom.name.replaceAll(' ','') + suffix] || 0;
        return selectedValue;
    }

    const getRoomPriceForSlot = (occupancy,hotelRoomCat) => {
        if(occupancy == 'single') {
            if(selectedSlot == THREE_HOURS)
                return numeral(hotelRoomCat.attributes.hourlyStayObj.tarrifObj['tarrif3']['singleTarrif']).format('0,0');
            if(selectedSlot == SIX_HOURS)
                return numeral(hotelRoomCat.attributes.hourlyStayObj.tarrifObj['tarrif6']['singleTarrif']).format('0,0');
            if(selectedSlot == NINE_HOURS)
                return numeral(hotelRoomCat.attributes.hourlyStayObj.tarrifObj['tarrif9']['singleTarrif']).format('0,0');
        }
        if(occupancy == 'double') {
            if(selectedSlot == THREE_HOURS)
                return numeral(hotelRoomCat.attributes.hourlyStayObj.tarrifObj['tarrif3']['doubleTarrif']).format('0,0');
            if(selectedSlot == SIX_HOURS)
                return numeral(hotelRoomCat.attributes.hourlyStayObj.tarrifObj['tarrif6']['doubleTarrif']).format('0,0');
            if(selectedSlot == NINE_HOURS)
                return numeral(hotelRoomCat.attributes.hourlyStayObj.tarrifObj['tarrif9']['doubleTarrif']).format('0,0');
        }
        if(occupancy == 'triple') {
            if(selectedSlot == THREE_HOURS)
                return numeral(hotelRoomCat.attributes.hourlyStayObj.tarrifObj['tarrif3']['tripleTarrif']).format('0,0');
            if(selectedSlot == SIX_HOURS)
                return numeral(hotelRoomCat.attributes.hourlyStayObj.tarrifObj['tarrif6']['tripleTarrif']).format('0,0');
            if(selectedSlot == NINE_HOURS)
                return numeral(hotelRoomCat.attributes.hourlyStayObj.tarrifObj['tarrif9']['tripleTarrif']).format('0,0');
        }
    }

    //Code for Selecting Occupancy
    const getOccupancyLabel = (hotelRoom) => {
        let hotelRoomId  = hotelRoom.hotel_room_type_id;
        let name = hotelRoom.name;
        let hotelRoomCat = hotelRoomInfo.hotelCategory.find(cat => cat.hotel_room_type_id == hotelRoomId && cat.hotel_room_type_category_code == ROOMONLY);
        return (
            <MobileHRStyle.OccupancyBody>
                <MobileHRStyle.OccupancyImgParent>
                    <MobileHRStyle.OccupancyParent>
                        <MobileHRStyle.OccupancyLabelParent>
                            <MobileHRStyle.SingleOccupancyImg />
                            <MobileHRStyle.OccupancyLabelHeader>Single Occupancy</MobileHRStyle.OccupancyLabelHeader>
                        </MobileHRStyle.OccupancyLabelParent>
                        <MobileHRStyle.OccupancyPrice>
                            <span>&#x20B9;</span>
                            <p>
                                {/* { numeral(hotelRoomCat.attributes.price).format('0,0') } */}
                                { getRoomPriceForSlot('single',hotelRoomCat) }
                            </p>
                            <span>/slot</span>
                        </MobileHRStyle.OccupancyPrice>
                    </MobileHRStyle.OccupancyParent>
                    <MobileHRStyle.CounterParent>
                        <CounterComponent 
                            name = { name} 
                            count = { getHotelOptionsSelected(hotelRoom,'single') } 
                            maxCount = { RoomsDropDownRange }
                            onCountChange = {(count,name) => {roomNoCounterChangeHandler(count,hotelRoom,'single')}}
                        />
                    </MobileHRStyle.CounterParent>
                </MobileHRStyle.OccupancyImgParent>
                <MobileHRStyle.OccupancyImgParent>
                    <MobileHRStyle.OccupancyParent>
                        <MobileHRStyle.OccupancyLabelParent>
                            <MobileHRStyle.DoubleOccupancyImg />
                            <MobileHRStyle.OccupancyLabelHeader>Double Occupancy</MobileHRStyle.OccupancyLabelHeader>
                        </MobileHRStyle.OccupancyLabelParent>
                        <MobileHRStyle.OccupancyPrice>
                            <span>&#x20B9;</span>
                            <p>
                                {/* { numeral(hotelRoomCat.attributes.doubleOccupancyPrice).format('0,0') } */}
                                { getRoomPriceForSlot('double',hotelRoomCat) }
                            </p>
                            <span>/slot</span>
                        </MobileHRStyle.OccupancyPrice>
                    </MobileHRStyle.OccupancyParent>
                    <MobileHRStyle.CounterParent>
                        <CounterComponent 
                            name = { name} 
                            count = { getHotelOptionsSelected(hotelRoom,'double') } 
                            maxCount = { RoomsDropDownRange }
                            onCountChange = {(count,name) => {roomNoCounterChangeHandler(count,hotelRoom,'double')}}
                        />
                    </MobileHRStyle.CounterParent>
                </MobileHRStyle.OccupancyImgParent>
                <MobileHRStyle.OccupancyImgParent >
                    <MobileHRStyle.OccupancyParent>
                        <MobileHRStyle.OccupancyLabelParent>
                            <MobileHRStyle.TripleOccupancyImg />
                            <MobileHRStyle.OccupancyLabelHeader>Triple Occupancy</MobileHRStyle.OccupancyLabelHeader>
                        </MobileHRStyle.OccupancyLabelParent>
                        <MobileHRStyle.OccupancyPrice>
                            <span>&#x20B9;</span>
                            <p>
                                {/* { numeral(hotelRoomCat.attributes.extraBedPrice).format('0,0') } */}
                                { getRoomPriceForSlot('triple',hotelRoomCat) }
                            </p>
                            <span>/slot</span>
                        </MobileHRStyle.OccupancyPrice>
                    </MobileHRStyle.OccupancyParent>
                    <MobileHRStyle.CounterParent>
                        <CounterComponent 
                            name = { hotelRoom.name} 
                            count = { getHotelOptionsSelected(hotelRoom,'triple') } 
                            maxCount = { RoomsDropDownRange }
                            onCountChange = {(count,name) => {roomNoCounterChangeHandler(count,hotelRoom,'triple')}}
                        />
                    </MobileHRStyle.CounterParent>
                </MobileHRStyle.OccupancyImgParent>
            </MobileHRStyle.OccupancyBody>
        )
    }

    //Code for generating Hotel Room Type and Images
    const getRoomTypeImage = (hotelRoomId) => {
        let images = hotelRoomInfo.hotelRoomImages.filter(img => img.hotel_room_type_id == hotelRoomId);
        if(images?.length > 0) {
            let imagesColl = images.map(img => {
                return (
                    <MobileHRStyle.RoomTypeImage src = { img.photo }  alt = "This is an image for Room Type">
                    </MobileHRStyle.RoomTypeImage>
                );
            })
            return imagesColl;
        } 
    }

    const getRoomType = (hotelRoom) => {
        return (
            <>
                <MobileHRStyle.RoomTypeImageBox>
                    {/* <Carousel showArrows={true}> */}
                    <Carousel autoPlay = { false } indicators = { false } navButtonsAlwaysVisible = { true }>
                        { getRoomTypeImage(hotelRoom.hotel_room_type_id) }
                    </Carousel>
                </MobileHRStyle.RoomTypeImageBox>
                <MobileHRStyle.RoomTypeName>
                    { hotelRoom.name }
                </MobileHRStyle.RoomTypeName>
            </>
        )
    }

    const getRoomCount = () => {
        let roomCount = 0;
        for(var roomName in cart) {
            for(var occ in cart[roomName]) {
                roomCount += cart[roomName][occ].length;
            }
        }
        return roomCount;
    }

    const getRoomPriceBasedOnOcc = (occupancy,attributes) => {
        // if(occupancy == 'single')
        //     return attributes.price;
        // else if(occupancy == 'double')
        //     return attributes.doubleOccupancyPrice;
        // else if(occupancy == 'triple')
        //     return attributes.extraBedPrice;
        if(occupancy == 'single') {
            if(selectedSlot == THREE_HOURS)
                return attributes.hourlyStayObj.tarrifObj['tarrif3']['singleTarrif'];
            if(selectedSlot == SIX_HOURS)
                return attributes.hourlyStayObj.tarrifObj['tarrif6']['singleTarrif'];
            if(selectedSlot == NINE_HOURS)
                return attributes.hourlyStayObj.tarrifObj['tarrif9']['singleTarrif'];
        }
        if(occupancy == 'double') {
            if(selectedSlot == THREE_HOURS)
                return attributes.hourlyStayObj.tarrifObj['tarrif3']['doubleTarrif'];
            if(selectedSlot == SIX_HOURS)
                return attributes.hourlyStayObj.tarrifObj['tarrif6']['doubleTarrif'];
            if(selectedSlot == NINE_HOURS)
                return attributes.hourlyStayObj.tarrifObj['tarrif9']['doubleTarrif'];
        }
        if(occupancy == 'triple') {
            if(selectedSlot == THREE_HOURS)
                return attributes.hourlyStayObj.tarrifObj['tarrif3']['tripleTarrif'];
            if(selectedSlot == SIX_HOURS)
                return attributes.hourlyStayObj.tarrifObj['tarrif6']['tripleTarrif'];
            if(selectedSlot == NINE_HOURS)
                return attributes.hourlyStayObj.tarrifObj['tarrif9']['tripleTarrif'];
        }
    }

    const createRoomCardsTobeAdded = (noOfRooms,hotelRoom,suffix) => {
        let hotelObj;
        if(cart[hotelRoom.name])
            hotelObj = {...cart[hotelRoom.name]};
        else {
            hotelObj = {};
        }
        let roomCards = [];

        let hotelCat = hotelRoomInfo.hotelCategory.find(cat => cat.hotel_room_type_id == hotelRoom.hotel_room_type_id && cat.hotel_room_type_category_code == 'ROOM_ONLY');
        for(let i = 0; i < noOfRooms; i ++) {
            roomCards.push({
                id:uuidv4(),
                price:getRoomPriceBasedOnOcc(suffix,hotelCat.attributes),
                roomTypeId:hotelRoom.hotel_room_type_id,
                roomTypeCategoryID:hotelCat.hotel_room_type_category_id,
                STAY_TYPE:HOURLY_STAY,
                HOURLY_SLOT:selectedSlot
            });
        }
        hotelObj[suffix] = roomCards;
        return hotelObj;
    }

    const roomNoCounterChangeHandler = (count,hotelRoom,suffix) => {
        let propName = hotelRoom.name.replaceAll(' ','') + suffix;
        let roomCards = createRoomCardsTobeAdded(count,hotelRoom,suffix);
        dispatch(addRoomsToCart({
            roomType:hotelRoom.name,
            roomCards
        }));
        setNoofRooms((prevObj) => ({
            ...prevObj,
            [propName]:count
        }));
    }

    const getHotelRooms = () => {
        let rooms = hotelRoomInfo.hotelRooms.map(hotelRoom => {
            return (
                <MobileHRStyle.HotelRoom key = { hotelRoom.name }>
                    <MobileHRStyle.RoomType>
                        { getRoomType(hotelRoom) }
                    </MobileHRStyle.RoomType>
                    <MobileHRStyle.RoomOccupancy>
                        { getOccupancyLabel(hotelRoom) }
                    </MobileHRStyle.RoomOccupancy>
                </MobileHRStyle.HotelRoom>
            )
        });
        console.log(rooms);
        return rooms;
    }

    const showPadding = () => {
        if(selectedRooms.length > 0) 
            return { showPadding : true }
    }

    const closeDialog = () => {
        setOpenDialog(false);
    }

    const isSlotSelected = (name) => {
        if(name == selectedSlot)
            return {
                selected:true
            }
    }

    const slotClickHandler = (name) => {
        if(name != selectedSlot) {
            setSelectedSlot(name);
            setNoofRooms({})
            dispatch(clearCart({}));
        }
    }

    const hourlySlots = () => {
        let room = hotelRoomInfo.hotelCategory[0];
        let slots = [];
        let date;
        let checkOutTime = 19;
        if(hourlySelectionInfo.checkInTime) {
            date = new Date(hourlySelectionInfo.startDate);
            date.setHours(hourlySelectionInfo.checkInTime.split(':')[0]);
        }
        else {
            date = new Date();
        }
        if(room?.attributes.hourlyStayObj.tarrifObj['stay3']) {
            let hours = date.getMinutes() > 0 ? date.getHours() + 1 : date.getHours();
            if((hours + 3) <= checkOutTime) {
                slots.push((
                    <MobileHRStyle.HourlyStaySlot { ...isSlotSelected(THREE_HOURS) } onClick = { () => slotClickHandler(THREE_HOURS) }>Upto 3 Hours</MobileHRStyle.HourlyStaySlot>
                ))
            }
        }
        if(room?.attributes.hourlyStayObj.tarrifObj['stay6']) {
            let hours = date.getMinutes() > 0 ? date.getHours() + 1 : date.getHours();
            if((hours + 6) <= checkOutTime) {
                slots.push((
                    <MobileHRStyle.HourlyStaySlot { ...isSlotSelected(SIX_HOURS) } onClick = { () => slotClickHandler(SIX_HOURS) }>3-6 Hours</MobileHRStyle.HourlyStaySlot>
                ))
            }
        }
        if(room?.attributes.hourlyStayObj.tarrifObj['stay9']) {
            let hours = date.getMinutes() > 0 ? date.getHours() + 1 : date.getHours();
            if((hours + 9) <= checkOutTime) {
                slots.push((
                    <MobileHRStyle.HourlyStaySlot { ...isSlotSelected(NINE_HOURS) } onClick = { () => slotClickHandler(NINE_HOURS) }>6-9 Hours</MobileHRStyle.HourlyStaySlot>
                ))
            }
        }
        return slots;
    }

    const hasHourlySlots = () => {
        let room = hotelRoomInfo.hotelCategory[0];
        let hasSlots = false;
        let date;
        let checkOutTime = 19;

        if(hourlySelectionInfo.checkInTime) {
            date = new Date(hourlySelectionInfo.startDate);
            date.setHours(hourlySelectionInfo.checkInTime.split(':')[0]);
        }
        else {
            date = new Date();
        }

        if(room?.attributes.hourlyStayObj.tarrifObj['stay3']) {
            let hours = date.getMinutes() > 0 ? date.getHours() + 1 : date.getHours();
            if((hours + 3) <= checkOutTime) 
                hasSlots = true;
        }
        if(room?.attributes.hourlyStayObj.tarrifObj['stay6']) {
            let hours = date.getMinutes() > 0 ? date.getHours() + 1 : date.getHours();
            if((hours + 6) <= checkOutTime) 
                hasSlots = true;
        }
        if(room?.attributes.hourlyStayObj.tarrifObj['stay9']) {
            let hours = date.getMinutes() > 0 ? date.getHours() + 1 : date.getHours();
            if((hours + 9) <= checkOutTime) 
                hasSlots = true;
        }
        return hasSlots;
    }

    return (
        <MobileHRStyle.HotelRoomParent { ...showPadding() }>
            <MobileHRStyle.RoomsHeaderParent>
                {
                    hasHourlySlots() ? <MobileHRStyle.RoomsHeaderText>Choose preferred slot</MobileHRStyle.RoomsHeaderText> : <><MobileHRStyle.RoomsHeaderText>Sold Out</MobileHRStyle.RoomsHeaderText></>
                }
                <MobileHRStyle.SlotParent>
                    { hourlySlots() }
                </MobileHRStyle.SlotParent>
            </MobileHRStyle.RoomsHeaderParent>
            {   hasHourlySlots() ?
                <>
                    <MobileHRStyle.RoomsHeaderParent>
                        <MobileHRStyle.RoomsHeaderText>Available Rooms</MobileHRStyle.RoomsHeaderText>
                        <MobileHRStyle.RoomsSubHeaderText>Price Including Tax</MobileHRStyle.RoomsSubHeaderText>
                    </MobileHRStyle.RoomsHeaderParent>
                    { getHotelRooms() }
                    { getRoomCount() > 0 && 
                        createPortal(<MobileHourlyRoomCart />,document.body)
                    }
                </>
                :
                null
            }
            <DialogBox buttonText = "Ok" dialogContent = "Please select room." open = { openDialog } buttonHandler = { closeDialog }/>
        </MobileHRStyle.HotelRoomParent>
    )
}

export default MobileHourlyRoomCategoryInfo;