import styled from "styled-components";
import Box from '@mui/material/Box';
import { styled as styledMUI, alpha } from '@mui/system';
import { Button, Typography } from "@mui/material";
import { CalendarOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import { TimePicker } from 'antd';

const { RangePicker } = DatePicker; 


  const blue = {
    100: '#DAECFF',
    200: '#99CCF3',
    400: '#3399FF',
    300: '#66B2FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };

  /*
  export const StyledSlider = styledMUI(SliderUnstyled)(
    ({ theme }) => `
    color: ${theme.palette.mode === 'light' ? grey[500] : grey[300]};
    height: 6px;
    width: 85%;
    margin:0 5%;
    padding: 16px 0;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
  
    &:hover {
      opacity: 1;
    }
  
    &.${sliderUnstyledClasses.disabled} { 
      pointer-events: none;
      cursor: default;
      color: ${theme.palette.mode === 'light' ? grey[300] : grey[600]};
      opacity: 0.5;
    }
  
    & .${sliderUnstyledClasses.rail} {
      display: block;
      position: absolute;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background-color: currentColor;
      opacity: 0.4;
    }
  
    & .${sliderUnstyledClasses.track} {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 2px;
      background-color: currentColor;
    }
  
    & .${sliderUnstyledClasses.thumb} {
      position: absolute;
      width: 16px;
      height: 16px;
      margin-left: -6px;
      margin-top: -6px;
      box-sizing: border-box;
      border-radius: 50%;
      outline: 0;
      border: 3px solid currentColor;
      background-color: #fff;
  
      :hover,
      &.${sliderUnstyledClasses.focusVisible} {
        box-shadow: 0 0 0 0.25rem ${alpha(
          theme.palette.mode === 'light' ? blue[400] : blue[300],
          0.15,
        )};
      }
  
      &.${sliderUnstyledClasses.active} {
        box-shadow: 0 0 0 0.25rem ${alpha(
          theme.palette.mode === 'light' ? blue[200] : blue[300],
          0.3,
        )};
      }
    }
  `,
  );
*/

export const RoomSelectionParent = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    & .rs-picker-toggle-value {
        color:#191A1C;
    }
    position:relative;
    width:100%;
    margin: 0 1rem;
    border-radius: 10px;
    box-shadow: 1px 3px 8px 0px rgba(50, 50, 71, 0.17);
    @media(max-width:576px) {
      width: 100vw;
      margin: 0 1rem;
      border-radius: 10px;
      box-shadow: 1px 3px 8px 0px rgba(50, 50, 71, 0.17);
    }
    &. ant-picker-separator {
      color:white;
    }
}`

export const RoomSelectionRow = styled(Box)`
&& {
  
    display:flex;
    flex-direction:column;
    gap:0rem;
    @media(max-width:576px) {
      flex-direction:column;
    }
}`

export const DateRangePicker = styled(RangePicker)`
&& {
    z-index:2;
    width:100%;
    padding:0 11px;
    height:100%;
    font-family:Red Hat Display;
}`

export const StartDatePicker = styled(DatePicker)`
&& {
    z-index:2;
    width:100%;
    padding:0 11px;
    height:100%;
    font-family:Red Hat Display;
}`

export const Separator = styled(Box)`
&& {
    background: #A6BCDA;
    width:2px;
}`

export const StartTimePicker = styled(TimePicker)`
&& {
    z-index:2;
    width:100%;
    padding:0 11px;
    height:100%;
    font-family:Red Hat Display;
}`

export const TimeParent = styled(Box)`
&& {
  display:flex;
  height:50px;
  width:100%;
  position:relative;
  background-color: white;
  border: 0.5px solid var(--light-blue, #A6BCDA);
  border-radius: 10px 10px 0px 0px;
  background: #FFF;
  padding:8px 0;  
  & .ant-picker-range-separator {
      height:100%;
      width:1px;
      border-right:1px solid #A6BCDA;
      padding:0;
  };
  & .ant-picker-range-separator span{
      display:none
  }
  & .ant-picker-active-bar {
      display:none;
  };
  & .ant-picker-input input {
      text-align:center;
  };
  & .ant-picker-suffix {
      display:none;
  };
  & .ant-picker-clear {
      display:none;
  };
  & .ant-picker-header-super-prev-btn {
      display:none !important;
  };
  & .ant-picker-input:nth-child(3) input  {
      text-align:center;
      padding-left:1rem;
  };
  @media(max-width:576px) {
      width:auto;
      height: 50px;
  };
  & .ant-picker-dropdown {
    position: absolute;
    bottom: 0;
    left: 60%;
    transform: translateX(-50%);
    max-width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
  }
}`

export const DateParent = styled(Box)`
&& {
  height:50px;
  width:100%;
  position:relative;
  background-color: white;
  border: 0.5px solid var(--light-blue, #A6BCDA);
  border-radius: 10px 10px 0px 0px;
  background: #FFF;
  padding:8px 0;  
  & .ant-picker-range-separator {
      height:100%;
      width:1px;
      border-right:1px solid #A6BCDA;
      padding:0;
  };
  & .ant-picker-range-separator span{
      display:none
  }
  & .ant-picker-active-bar {
      display:none;
  };
  & .ant-picker-input input {
      text-align:center;
  };
  & .ant-picker-suffix {
      display:none;
  };
  & .ant-picker-clear {
      display:none;
  };
  & .ant-picker-header-super-prev-btn {
      display:none !important;
  };
  & .ant-picker-input:nth-child(3) input  {
      text-align:center;
      padding-left:1rem;
  };
  @media(max-width:576px) {
      width:auto;
      height: 50px;
  }
}`

export const DateIconsWrapper = styled(Box)`
&& {
    position:absolute;
    width:100%;
    height:100%;
    top:0px;
    display:flex;
    align-items:center;
    z-index:1;
}`

export const StartDateIcon = styled(CalendarOutlined)`
&& {
    color:#141414;
    position: absolute;
    left: 5%;
}`

export const TimeIcon = styled(Box)`
&& {
    background:url(styles/assets/clock.svg);
    position: absolute;
    left: 55%;
    height:15px;
    width:15px;
    background-size:100%;
}`

export const EndDateIcon = styled(CalendarOutlined)`
&& {
    color:#141414;
    position: absolute;
    left: 55%;
}`

export const RoomSelectionRowAbs = styled(Box)`
&& {
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    display:flex;
    flex-direction:row;
    position:fixed;
    bottom:0px;
    padding-top:10px;
    width:600px;
    z-index:2;
    @media(max-width:576px) {
        width:100%;
        left:unset;
        transform:unset;
    };
    & > div {
        width:100%;
    }
}`


export const CriteriaSelectionBox = styled(Box)`
&& {
    height:50px;
    background-color:white;
    display:flex;
    padding:0 1rem;
    justify-content:flex-start;
    align-items:center;
    color:#141414;
    font-size:1rem;
    font-family: 'Red Hat Display';
    cursor:pointer;
    position:relative;
    background-image:url(styles/assets/human.svg);
    background-repeat: no-repeat;
    background-position: 1rem 50%;
    width: 100%;
    padding-left:3rem;
    border: 0.5px solid #A6BCDA;
    @media (max-width: 576px) {
        width: 100%;
        padding-left:3rem;
        border: 0.5px solid #A6BCDA;
        border-top:0px;
        border-bottom:0px;
    };
    & span {
        padding:0 8px;
        font-weight:700;
    };
    & span:first-child {
      padding-right:8px;
      padding-left:0px;
      font-weight:700;
  }
    
}`

export const CheckAvail = styled(Button)`
&& {
    height:50px;
    padding:1rem;
    font-size:0.8rem;
    font-family: 'Red Hat Display';
    color:white;
    background: linear-gradient(180deg, #F31771 0%, #D0105F 100%);
    box-shadow: 1px 3px 8px rgba(50, 50, 71, 0.17);
    border-radius: 0px 0px 10px 10px;
    text-transform:capitalize;
    font-size:1rem;
    @media(max-width:576px) {
      width:auto;
      margin-left:0;
    };
    &:hover {
      background: linear-gradient(180deg, #F31771 0%, #D0105F 100%);
    }
}`

/* Start Room Selection Dropdown */
export const Dropdown = styled(Box)`
&& {
    background-color:white;
    padding:0.5rem 1rem 1rem 1rem;
    border-radius:15px 15px 0 0;
    width: 100%;
    left:20rem;
    box-shadow:0px -6px 4px 0px rgba(149,149,149,0.17);
    @media(max-width:576px) {
        z-index:2;
        left:0px;
        width:100%;
        top:5px;
        border-radius:15px 15px 0 0;
    }
}`

export const RoomMaxLabel = styled(Typography)`
&& {
    color:#141414;
    font-size:1rem;
    letter-spacing:1px;
    font-weight:700;
}`

export const DropdownImageParent = styled(Box)`
&& {
    width:100%;
    display:flex;
    justify-content:center;
    height: 15px;
    align-items: flex-start;
}`

export const DropdownImage = styled(Box)`
&& {
    height: 4px;
    background-color: #000;
    opacity: 0.4;
    width: 36px;
    border-radius: 4px;
}`

export const LabelWrapper = styled(Box)`
&& {

}`

export const RSDBigLabel = styled(Typography)`
&& {
    font-weight:500;
    color:#141414;
    letter-spacing:1px;
    font-size:0.9rem;
}`

export const RSDSmallLabel = styled(Typography)`
&& {
    color:#141414;
    font-size:0.75rem;
    letter-spacing:1px;
    font-weight:400;
    opacity:0.8;
}`

export const RoomBox = styled(Box)`
&& {
    margin-top:0.5rem;
    position:relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}`

export const SelectBox = styled(Box)`
&& {
    display:flex;
    justify-content:center;
    padding-top:1rem;
}`

export const DoneBtn = styled(Button)`
&& {
    padding:0.2rem 2rem;
    border-radius:5px;
    background: linear-gradient(180deg, #F31771 0%, #D0105F 100%);
    box-shadow: 1px 3px 8px rgba(50, 50, 71, 0.17);
    color: white;
    font-family: Red Hat Display;
    text-transform: capitalize;
}`

export const SelectedValue = styled(Typography)`
&& {
    position: absolute;
    right: 0px;
    top: 7px;
    font-weight:700;
}`

/* End Room Selection Dropdown*/

/* Strat Hotel Selection */
export const HotelSelectionParent = styled(Box)`
&& {
    height:50px;
    border-radius: 10px 10px 0px 0px;
    border: 0.5px solid #A6BCDA;
    background: #FFF;
    padding:8px 0;
    display:flex;
    display:none;
}`

export const HotelLocation = styled(Box)`
&& {
    height: 100%;
    width: 50%;
    border-right: 1px solid #A6BCDA;
    background-image:url(styles/assets/gps.svg);
    background-position:1rem 50%;
    background-repeat:no-repeat;
    color: #141414;
    font-family: Red Hat Display;
    font-size: 1rem;
    padding-left: 3rem;
    display: flex;
    align-items: center;
}`

export const HotelName = styled(Box)`
&& {
    height: 100%;
    width: 50%;
    color: #141414;
    font-family: Red Hat Display;
    font-size: 0.85rem;
    color: #141414;
    font-family: Red Hat Display;
    font-size: 1rem;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    background-image:url(styles/assets/hotelImg.svg);
    background-position:1rem 50%;
    background-repeat:no-repeat;
}`
/* End Hotel Selection */

