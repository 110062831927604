import { TextField, Typography, Button } from '@mui/material';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

export const AddGuestParent = styled(Box)`
&& {
    border-radius: 10px;
    background-color: white;
    width: 40vw;
    display: flex;
    margin: 0 auto;
    max-height: 40vw;
    flex-direction:column;
    position:relative;
    font-family:'Red Hat Display';
}`

export const AddGuestHeader = styled(Box)`
&& {
    background: #F0F6FF;
    border-radius: 10px 10px 0px 0px;
    color:#052C65;
    font-size:1.25rem;
    padding:1rem;
}`

export const AddGuestBody = styled(Box)`
&& {
    display:flex;
    flex-direction:column
}`

export const AddGuestFooter = styled(Box)`
&& {
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:#F1F6FB;
    padding:1.5rem;
}`

export const AGRow = styled(Box)`
&& {
    display:flex;
}`

export const AGColumn = styled(Box)`
&& {
    width:50%;
    padding-left:1rem;
}`

export const AGLabel = styled(Typography)`
&& {
    font-size:0.8rem;
    color:#354665;
    font-weight:500;
    margin:1rem 0;
}`

export const AGInput = styled(TextField)`
&& {

}`

export const AGAddBtn = styled(Button)`
&& {
    width: fit-content;
    margin: 2rem 1rem;
    padding-left:2rem;
    padding-right:2rem;
    background-color:#BAE7FF;
    border-radius:35px;
    color:#002766;
    font-size:1rem;
    font-weight:500;
    text-transform:unset;
    font-family:'Red Hat Display';
}`

export const AddedGuestList = styled(Box)`
&& {

}`

export const AGDone = styled(Button)`
&& {
    font-weight: 700;
    padding-left: 2rem;
    padding-right: 2rem;
    font-family:'Red Hat Display';
    background: linear-gradient(180deg,#F31771 0%,#D0105F 100%);
    border-radius: 5px;
}`

export const CloseModal = styled(CloseIcon)`
&& {
    position:absolute;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor:pointer;
}`

export const AddedGuestRow = styled(Box)`
&& {
    display: flex;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
}`

export const AddedGuestName = styled(Box)`
&& {
    font-size:1rem;
    color;#2C2C2E;
    font-weight:400;  
    width:40%;
}`

export const GuestEditIcon = styled(EditIcon)`
&& {
    color:#263F97;
}`