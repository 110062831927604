import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
var numeral = require('numeral');

//Material UI and styeld Imports
import * as MCStyle from '../../../../styles/BookingMobile/mobileCartStyle';

//Internal Imports
import { addRoomsToCartMobile } from '../../../../slices/bookingDetails';
import dayjs from "dayjs";


const MobileHoulryRoomCart = () => {

    const history = useHistory();

    const dispatch = useDispatch();

    const cart = useSelector(state => state.bookingDetails.cart);

    const dateRange = useSelector(state => state.bookingDetails.selectionCriteria.dateRange);

    const getTotalPrice = () => {
        let TotalRooms = 0;
        let TotalAmount = 0;
        let totalAdults = 0;
        
        let cards = [];
        for(var o in cart) {
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                TotalRooms += roomsOcc.length;
                if(room == 'single')
                    totalAdults += (roomsOcc.length);
                else if(room == 'double') 
                    totalAdults += (roomsOcc.length * 2);
                else if(room == 'triple')
                    totalAdults += (roomsOcc.length * 3);
                for(let i = 0; i < roomsOcc.length; i ++) {
                    TotalAmount += parseInt(roomsOcc[i].price);
                }
            }
        }
        let roomText = 'room';
        //let noOfNights = (new Date(dateRange[1])).getDate() - (new Date(dateRange[0])).getDate();
        let noOfNights = (dayjs((new Date(dateRange[1])))).diff(dayjs((new Date(dateRange[0]))),'day');
        TotalAmount *= noOfNights;
        if(TotalRooms.length > 1)
            roomText = 'rooms';
        return `${numeral(TotalAmount).format('0,0')} for ${TotalRooms} ${roomText}`;
    }

    const monthNames = ["Jan", "Feb", "March", "Apr", "May", "June",
        "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];

    const getDuration = () => {
        //let noOfNights = (new Date(dateRange[1])).getDate() - (new Date(dateRange[0])).getDate();
        let noOfNights = (dayjs((new Date(dateRange[1])))).diff(dayjs((new Date(dateRange[0]))),'day');
        let nightsText = 'night ';
        let getStartDayMonth = monthNames[new Date(dateRange[0]).getMonth()];
        let getEndDayMonth = monthNames[new Date(dateRange[1]).getMonth()];
        if(noOfNights > 1)
            nightsText = 'nights ';
        return `${noOfNights} ${nightsText}(${(new Date(dateRange[0])).getDate()} ${getStartDayMonth} - ${(new Date(dateRange[1])).getDate()} ${getEndDayMonth})`;
    }

    const createMobileCartInfo = () => {
        let cartObj = {};
        selectedRooms.map((room) => {
            let roomCount = room.noOfRooms;
            if(!cartObj[room.name])
                cartObj[room.name] = {};
            for(let i = 0; i < roomCount; i++) {
                if(!cartObj[room.name][room.selectedOcc])
                    cartObj[room.name][room.selectedOcc] = [];  
                cartObj[room.name][room.selectedOcc].push({
                    id:uuidv4(),
                    price:room.roomPrice,
                    roomTypeId:room.roomTypeId,
                    roomTypeCategoryID:room.roomTypeCategoryID,
                    mealInfo:room.mealInfo
                });
            }
        });
        return cartObj;
    }

    const startBooking = () => {
        //let cartInfo = createMobileCartInfo();
        //dispatch(addRoomsToCartMobile(cartInfo));
        history.push('/checkout');
    }

    return (
        <MCStyle.CartParent>
            <MCStyle.CartDetails>
                <MCStyle.PriceDetails>
                    <span>&#x20B9;</span>
                    { getTotalPrice() }
                </MCStyle.PriceDetails>
                <MCStyle.Duration>
                    { getDuration() }
                </MCStyle.Duration>
            </MCStyle.CartDetails>
            <MCStyle.BookNow onClick = { startBooking } variant = 'contained'>
                Proceed to Book
            </MCStyle.BookNow>
        </MCStyle.CartParent>
    )
}

export default MobileHoulryRoomCart;