import React from "react";
import { useSelector, useDispatch } from "react-redux";

//Internal Imports
import { setCriteriaValue } from '../../../slices/bookingDetails';
import * as Constants from '../../../config/constants';
import SelectionCounter from '../../../utils/SelectionCounter';

//Style Imports
import Box from '@mui/material/Box';
import * as RSStyle from '../../../styles/Booking/roomSelectionStyle';

const RoomSelectionDropDown = (props) => {

    const dispatch = useDispatch();

    const selectedValue = useSelector((state) => state.bookingDetails.selectionCriteria.roomInfo);

    const sliderChangeHandler = (value,name) => {
        let currentValue = {
            [name] : value
        }
        if(name == 'Adults') {
            if(selectedValue.Rooms < Math.ceil((value + selectedValue.Children)/Constants.AllowedAdults))
                currentValue.Rooms = Math.ceil((value + selectedValue.Children)/Constants.AllowedAdults);
            if(selectedValue.Rooms > (value + selectedValue.Children))
                currentValue.Rooms = (value + selectedValue.Children);
        }
        if(name == 'Children') {
            if(selectedValue.Rooms < Math.ceil((value + selectedValue.Adults)/Constants.AllowedAdults))
                currentValue.Rooms = Math.ceil((value + selectedValue.Adults)/Constants.AllowedAdults);
            if(selectedValue.Rooms > (value + selectedValue.Adults))
                currentValue.Rooms = (value + selectedValue.Adults);
        }
        dispatch(setCriteriaValue({
            ...selectedValue,
            ...currentValue
        }))
    }
    
   
    return (
        <RSStyle.Dropdown>
            <RSStyle.DropdownImageParent>
                <RSStyle.DropdownImage></RSStyle.DropdownImage>
            </RSStyle.DropdownImageParent>
            <RSStyle.RoomMaxLabel>
                Select rooms and guests
            </RSStyle.RoomMaxLabel>
            <RSStyle.RoomBox>
                <RSStyle.RSDBigLabel>
                    Rooms
                </RSStyle.RSDBigLabel>
                {/* <RSStyle.RSDSmallLabel>
                    (Max 8 total Guests/room)
                </RSStyle.RSDSmallLabel> */}
                {/* <SliderComponent value = { selectedValue.Rooms } onChange={sliderChangeHandler} name = "Rooms"></SliderComponent>
                <RSStyle.SelectedValue>{ selectedValue.Rooms }</RSStyle.SelectedValue> */}
                <SelectionCounter value = { selectedValue.Rooms } onCountChange={sliderChangeHandler} name = "Rooms"/>
            </RSStyle.RoomBox>
            <RSStyle.RoomBox>
                <RSStyle.LabelWrapper>
                    <RSStyle.RSDBigLabel>
                        Adults
                    </RSStyle.RSDBigLabel>
                    <RSStyle.RSDSmallLabel>
                        (Above 15 Years)
                    </RSStyle.RSDSmallLabel>
                </RSStyle.LabelWrapper>
                {/* <SliderComponent value = { selectedValue.Adults } onChange={ sliderChangeHandler } name = "Adults"></SliderComponent>
                <RSStyle.SelectedValue>{ selectedValue.Adults }</RSStyle.SelectedValue> */}
                <SelectionCounter value = { selectedValue.Adults } onCountChange={ sliderChangeHandler } name = "Adults"/>
            </RSStyle.RoomBox>
            <RSStyle.RoomBox>
                 <RSStyle.LabelWrapper>
                    <RSStyle.RSDBigLabel>
                        Children
                    </RSStyle.RSDBigLabel>
                    <RSStyle.RSDSmallLabel>
                        (5 - 15 Years)
                    </RSStyle.RSDSmallLabel>
                </RSStyle.LabelWrapper>
                {/* <SliderComponent value = { selectedValue.Children } onChange={ sliderChangeHandler } name = "Children"></SliderComponent>
                <RSStyle.SelectedValue>{ selectedValue.Children }</RSStyle.SelectedValue> */}
                <SelectionCounter value = { selectedValue.Children } onCountChange={sliderChangeHandler} name = "Children"/>
            </RSStyle.RoomBox>
            <RSStyle.RoomBox>
                 <RSStyle.LabelWrapper>
                    <RSStyle.RSDBigLabel>
                        Infant
                    </RSStyle.RSDBigLabel>
                    <RSStyle.RSDSmallLabel>
                        (Upto 5 Years)
                    </RSStyle.RSDSmallLabel>
                </RSStyle.LabelWrapper>
                <SelectionCounter value = { selectedValue.Infant } onCountChange={sliderChangeHandler} name = "Infant"/>
            </RSStyle.RoomBox>
            <RSStyle.SelectBox>
                <RSStyle.DoneBtn onClick={ props.closeDropdown }>
                    Done
                </RSStyle.DoneBtn>
            </RSStyle.SelectBox>
        </RSStyle.Dropdown>
    )
}

export default RoomSelectionDropDown;

