import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
var numeral = require('numeral');

//Material UI and styled Imports
import * as MobileCheckoutStyle from '../../../styles/BookingMobile/mobileCheckoutStyle';

//Internal Imports
import { NINE_HOURS, OVERNIGHT_STAY, SIX_HOURS, THREE_HOURS } from '../../../config/constants';
import { HOURLY_STAY } from '../../../config/constants';

const MobileRoomCards = () => {

    const cartMobile = useSelector((state) => state.bookingDetails.cart);

    const STAY_TYPE = useSelector(state => state.bookingDetails.STAY_TYPE);

    const dateRange  = useSelector(state => state.bookingDetails.selectionCriteria.dateRange);

    const getOccImage = (occType) => {
        if(occType == 'single')
            return <MobileCheckoutStyle.SingleOccImg></MobileCheckoutStyle.SingleOccImg>
        else if(occType == 'double')
            return <MobileCheckoutStyle.DoubleOccImg></MobileCheckoutStyle.DoubleOccImg>
        else 
            return <MobileCheckoutStyle.TripleOccImg></MobileCheckoutStyle.TripleOccImg>
    }

    const getSelectedMealsInfo = (room) => {
        let mealStr = ' | '
        let hasMeal = false;
        if(room.mealInfo){
            let keys = Object.keys(room.mealInfo).length;
            //if(room.mealInfo['breakfast']) {
            if(room.mealInfo['breakfast'] != null && room.mealInfo['breakfast'] != undefined) {
                hasMeal = true;
                if(keys == 4)
                    mealStr += 'Breakfast' + ' , ';
                else 
                    mealStr += 'Breakfast' + ' & ';
            }
            if(room.mealInfo['lunch']) {
                hasMeal = true;
                mealStr += 'Lunch' + ' & ';
            }
            if(room.mealInfo['dinner']) {
                hasMeal = true;
                mealStr += 'Dinner' + ' , ';
            }

        }
        if(hasMeal)
            return mealStr.substring(0,mealStr.length - 2);
        else 
            return ''
    }

    const getOccText = (occType,roomInfo) => {
        if(occType == 'single')
            return <MobileCheckoutStyle.RoomOccText>{ `1 Adult ${getSelectedMealsInfo(roomInfo)}` }</MobileCheckoutStyle.RoomOccText>
        else if(occType == 'double')
            return <MobileCheckoutStyle.RoomOccText>{ `2 Adults ${getSelectedMealsInfo(roomInfo)}` }</MobileCheckoutStyle.RoomOccText>
        else 
            return <MobileCheckoutStyle.RoomOccText>{ `3 Adults ${getSelectedMealsInfo(roomInfo)}` }</MobileCheckoutStyle.RoomOccText>
    }

    const getNoOfDays = () => {
        let noOfDays = (dayjs((new Date(dateRange[1])))).diff(dayjs((new Date(dateRange[0]))),'day');
        if(noOfDays > 1) 
            return `${noOfDays} nights`;
        return `${noOfDays} night`;
    }

    const getSlotType = () => {
        let roomKey = Object.keys(cartMobile)[0];
        let occKey = Object.keys(cartMobile[roomKey])[0];
        let slotType = cartMobile[roomKey][occKey][0]['HOURLY_SLOT'];
        if(slotType == THREE_HOURS)
            return 'upto 3 Hours';
        else if(slotType == SIX_HOURS)
            return 'upto 6 Hours';
        else if(slotType == NINE_HOURS)
            return 'upto 9 Hours';
    }

    const getRoomPrice = (room) => {
        if(STAY_TYPE == HOURLY_STAY)
            return ` for ${getSlotType()}`;
        else    
            return ` for ${getNoOfDays()}`;
    }

    const generateMobileCard = () => {
        let roomCards = [];
        for(var o in cartMobile) {
            let roomName = o;
            let rooms = cartMobile[o];
            for(let roomOcc in rooms) {
                let roomOccArr = rooms[roomOcc];
                roomOccArr.map(room => {
                    let TotalAmount = parseInt(room.price);
                    if(room.mealInfo) {
                        if(room.mealInfo['Meal']) {
                            TotalAmount += parseInt(room.mealInfo['Meal']);
                        }
                        else {
                            for(var mealType in room.mealInfo) {
                                TotalAmount += parseInt(room.mealInfo[mealType]);
                            }
                        }
                    }
                    roomCards.push(
                        <MobileCheckoutStyle.RoomCartParent>
                            <MobileCheckoutStyle.RoomCardHeader>
                                { roomName }
                            </MobileCheckoutStyle.RoomCardHeader>
                            <MobileCheckoutStyle.RoomCardBody>
                                { getOccImage(roomOcc) }
                                { getOccText(roomOcc,room) }
                            </MobileCheckoutStyle.RoomCardBody>
                            <MobileCheckoutStyle.RoomCardFooter>
                                <span>&#x20B9;</span>
                                {/* { <span>{room.price}</span> } */}
                                { <span> { numeral(TotalAmount * ((dayjs((new Date(dateRange[1])))).diff(dayjs((new Date(dateRange[0]))),'day'))).format('0,0')}</span>}
                                { getRoomPrice(room) }
                            </MobileCheckoutStyle.RoomCardFooter>
                        </MobileCheckoutStyle.RoomCartParent>
                    )
                })
            }
        }
        return roomCards;
    }
    
    return(
        <MobileCheckoutStyle.MobileRoomCardParent>
            <MobileCheckoutStyle.RoomCardlabel>
                Selected Rooms
            </MobileCheckoutStyle.RoomCardlabel>
            { generateMobileCard() }
        </MobileCheckoutStyle.MobileRoomCardParent>
    )
}

export default MobileRoomCards;