import styled from "styled-components";
import { Box, Button, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const MCContainer = styled(Box)`
&& {
    background-color: white;
    padding: 1rem 1rem;
    margin: 3px 0 0 0;
    padding-bottom: 1rem;
}`

export const MCHeader = styled(Box)`
&& {
    font-size: 1rem;
    font-weight: 700;
}`

export const MCText = styled(Typography)` && {
    font-size:0.85rem;
    
}`

export const MCButton = styled(Button)`
&& {
    width:90%;
    margin:1rem 5%;
    text-transform:capitalize;
    border-color:#838383;
    color:#838383;
    &:hover {
        border-color:#838383;
        color:#838383;
        background-color:white;
    }
}`

/* Start Pop up style */
export const MCPopUpContainer = styled(Box)`
&& {
    background-color:white;
    left: 50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
    width:500px;
    position:fixed;
    display:flex;
    box-shadow:2px -1px 2px 2px rgba(149,149,149,0.17),
    -2px 2px 2px 2px rgba(149,149,149,0.17);
    flex-direction:column;
    padding:1rem 2rem 2rem 2rem;
    gap:1rem;
    border-radius:4px;
    z-index:2;
    @media (max-width: 576px) { 
        width: 85%;
    }
}`

export const PopupHeader = styled(Typography)`
&& {

}`

export const  MobileNo = styled(TextField)`
&& {

}`

export const  BookingID = styled(TextField)`
&& {
    
}`

export const VerifyBtn = styled(Button)`
&& {
    text-transform:capitalize;
    width:5rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    height:2.5rem;
    text-transform:capitalize;
    color:white;
    border-radius: 5px;
    width:12rem;
    background: var(--brand-color, linear-gradient(180deg, #F31771 0%, #D0105F 100%));
    /* Drop Shadow */
    box-shadow: 1px 3px 8px 0px rgba(50, 50, 71, 0.17); 
}`

export const Cancellation = styled(Button)`
&& {
    text-transform:capitalize;
    width:5rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    height:2.5rem;
    text-transform:capitalize;
    color:white;
    border-radius: 5px;
    width:12rem;
    background: var(--brand-color, linear-gradient(180deg, #F31771 0%, #D0105F 100%));
    margin-bottom:1rem;
    box-shadow: 1px 3px 8px 0px rgba(50, 50, 71, 0.17); 
}`

export const CrossIcon = styled(CloseIcon)`
&& {
    position:absolute;
    right:2rem;
    top:1rem;
    cursor:pointer;
}`

export const MobleCancelDetailsParent = styled(Box)`
&& {
    width:600px;
    display:block;
    background-color:rgb(227, 229, 232);
    flex-grow:1;
    margin:0 auto;
    position:relative;
    height:100vh;
    display:flex;
    flex-direction:column;
    @media (max-width: 576px) {
        display:block;
        width:100vw;
        margin:unset;
    }
}`

export const DummyParentImg = styled(Box)`
&& {
    background-color:white;
}`

export const CancellationParent = styled(Box)`
&& {
    background-color:white;
    margin-top:2px;
    padding-top:1rem;
}`

export const CacncelImg = styled(Box)`
&& {
    height: 32px;
    background-image: url(styles/assets/confirm.svg);
    width: 32px;
    margin-bottom: 1rem;
}
`

export const ConfHeader = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    text-align: center;
    color: white;
    background: #ad1a1a;
    height:160px;
    display:flex;
    padding:1rem 0;
    align-items: center;
    flex-direction:column
}`
/* End Pop up styled */