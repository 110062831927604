import React from 'react';

//Mateial UI and style imports
import * as BCStyle from '../styles/utils/dialogBoxStyle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const DialogBox = (props) => {
    return (
        <Dialog fullWidth sx = {{ padding:'2rem', fontFamily:'Red Hat Display'}} open={props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {/* <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
            </DialogTitle> */}
            <DialogContent>
                <DialogContentText sx = {{fontFamily:'Red Hat Display', color:'black'}} id="alert-dialog-description">
                    { props.dialogContent }
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent:'flex-end'}}>
                <BCStyle.ComfirmButton onClick={ props.buttonHandler } autoFocus>
                    {props.buttonText}
                </BCStyle.ComfirmButton>
            </DialogActions>
        </Dialog>
    )
}

export default DialogBox;