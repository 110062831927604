import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

//Internal Imports
import BookingPageHeader from '../../BookinPageHeader/BookingPageHeader';
import MobileLPImages from './MobileLPImages';
import MobileLPInfo from './MobleLPInfo';
import BookingLPRules from '../LandingPage/BookingLPRules';
import RoomSelection from '../../RoomSelection/RoomSelection';
import MapContainer from './MapContainer';
import { RANDOMKEY } from '../../../../config/constants';
import ReviewsContainer from './ReviewsContainer';
import RoomDetails from '../../RoomDetails';
import MobileStaySelector from './MobileStaySelector';
import { setOverNightStay } from '../../../../slices/bookingDetails';
import MobileFacilitesPage from './MobileFacilitiesPage';
import MobileFooter from './MobileFooter';
import MobileCancelPage from '../../MobileCancel';

//Style Imports
import * as BookingLPStyle from '../../../../styles/Booking/bookingLPStyle';
import * as BookingHIStyle from '../../../../styles/Booking/bookingLPStyle';


const MobileLandingPage = (props) => {

    const hotelInfo = useSelector((state) => {
        return state.bookingDetails.hotelDetails;
    });

    const dispatch = useDispatch()

    const STAY_TYPE = useSelector(state => state.bookingDetails.STAY_TYPE);

    const roomDetails = useSelector(state => state.bookingDetails.hotelCategory);

    const [mapLocation,setMapLocation] = useState({});

    const secondComponentRef = useRef();

    const hotelId = useSelector(state => state.bookingDetails.hotelId);

    const geocodeAddress = () => {
        if(hotelInfo.address) {
            const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                `${hotelInfo.address} ${hotelInfo.city} ${hotelInfo.state}`
            )}&key=${RANDOMKEY}`;

            axios.get(geocodeUrl)
            .then((res) => {
                let locObj = {
                    lat:res.data.results[0].geometry.location.lat,
                    lng:res.data.results[0].geometry.location.lng
                }
                setMapLocation((prevObj) => ({
                    ...prevObj,
                    ...locObj
            }));
            })
            .catch(error => {
                console.error('Error geocoding address:', error);
            });
        }
    };

    const hasHourlyStay = () => {
        let room = roomDetails[0];
        if(room && room.attributes?.hourlyStayObj?.hasHourlyStay)
            return true;
        return false;
    }

    useEffect(() => {
        geocodeAddress();
    },[hotelInfo]);

    const scrollToSecondComponent = () => {
        secondComponentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        // // secondComponentRef.current.scrollBy(0, -100);

        // const secondComponentElement = secondComponentRef.current;

        // if (secondComponentElement) {
        //     const offset = 100; // Set your desired offset here
        //     const topPos = secondComponentElement.getBoundingClientRect().top + window.scrollY;
            
        //     const scroll = (targetPosition) => {
        //     const currentPosition = window.scrollY;
        //     const distance = targetPosition - currentPosition;
        //     const step = distance / 20; // Adjust the number of steps as needed for desired smoothness
            
        //     const animateScroll = () => {
        //         if (Math.abs(distance) > Math.abs(step)) {
        //         window.scrollBy(0, step);
        //         requestAnimationFrame(animateScroll);
        //         } else {
        //         window.scrollTo(0, targetPosition);
        //         }
        //     };
            
        //     animateScroll();
        //     };

        //     scroll(topPos - offset);
        // }
      };
    
    return (
        <BookingLPStyle.MobileLandingPageContainer>
            <BookingPageHeader name = {hotelInfo.name} phone = { hotelInfo.phone }/>
            
            
            { hotelInfo.attributes && <MobileLPImages /> }
            { hotelInfo.attributes && <MobileFacilitesPage /> }
            { hotelInfo.attributes && <MobileLPInfo />}
            { ( hotelInfo.attributes && Object.keys(mapLocation).length > 0 ) && 
                <>
                    <BookingHIStyle.MapContainerParent>
                        <MapContainer lat={mapLocation.lat} lng={mapLocation.lng}/>
                    </BookingHIStyle.MapContainerParent>
                    <ReviewsContainer hotelId = { hotelId } address = { `${hotelInfo.name} ${hotelInfo.address} ${hotelInfo.city} ${hotelInfo.state}` }/>
                </>
            }
            {   hasHourlyStay() &&
                <MobileStaySelector stayType = { STAY_TYPE } stayHandler = { (flag) => { dispatch(setOverNightStay(flag)) } }/>
            }
            { hotelInfo.attributes && 
                <BookingHIStyle.RoomSelectionParent>
                    <RoomSelection scrollToSecondComponent = { scrollToSecondComponent }/>
                </BookingHIStyle.RoomSelectionParent>
            }
            { hotelInfo.attributes && <RoomDetails ref = { secondComponentRef }/>}
            { hotelInfo.attributes && <BookingLPRules stayType = { STAY_TYPE }/>}
            { hotelInfo.attributes && <MobileCancelPage />}
            <MobileFooter />
        </BookingLPStyle.MobileLandingPageContainer>
    ) 
}

export default MobileLandingPage;