import styled from "styled-components";
import Box from '@mui/material/Box';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Typography } from "@mui/material";

export const BCContainer = styled(Box)`
&& {

}`

export const CircleOutline = styled(CheckCircleOutlineOutlinedIcon)`
&& {
    margin: 0 auto 0.5rem auto;
    color: #237804;
    font-size: 2rem;
}`

export const CloseIcon = styled(CloseOutlinedIcon)`
&& {
    cursor:pointer;
    margin:0.5rem 0;
}`

export const BookingConfHeader = styled(Typography)`
&& {
    color:#237804;
    font-weight:600;
    font-size:1.5rem;
    letter-spacing:0.01rem;
    font-family: 'Red Hat Display';
    text-align:center;
}`

export const BookingGuestDetails = styled(Typography)`
&& {
    color:#002766;
    font-weight:500;
    font-size:1.12rem;
    letter-spacing:0.01rem;
    font-family: 'Red Hat Display';
    text-align:center;
    margin-top:0.5rem;
}`

export const Stay = styled(Box)`
&& {
    display:flex;
    margin-top:1rem;
}`

export const CheckInOUTDate = styled(Typography)`
&& {
    color:#002766
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    padding-right:1rem;
}`

export const CheckInOUTDateLabel = styled(Typography)`
&& {
    color:#002766;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
}`
