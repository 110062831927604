import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

export const CounterParent = styled(Box)`
&& {
    height:35px;
    width:80px;
    background: #F5F9FF;
    border: 0.5px solid rgba(20, 20, 20, 0.3);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    & span {
        font-size:1rem;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}`

export const  CounterButton = styled(Box)`
&& {
    height:100%;
    width:35px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    font-size:1.5rem;
}`