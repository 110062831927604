import React from 'react';
import { useSelector } from 'react-redux';

//Style and Material UI Imports.
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//Internal Imports.
import MobileLandingPage from './LandingPageMobile/MobileLandingPage';
import WhatsApp from '../Chat/whatsApp';

const LandingPageDetails = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('xs'));

    const cart = useSelector((state) => state.bookingDetails.cart);

    const hasRooms = () => {
        if(Object.keys(cart).length > 0) {
            let roomCount = 0;
            for(var o in cart) {
                let roomType = cart[o];
                for(var room in roomType) {
                    let roomsOcc = roomType[room];
                    roomCount += roomsOcc.length;
                }
            }
            return roomCount;
        }
        return 0;
    }

    return (
        <>
            <MobileLandingPage />
            { hasRooms() == 0 &&
                <WhatsApp />
            }
            {/* <BookingLandingPage /> */}
        </>
    )
}

export default LandingPageDetails;