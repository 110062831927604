import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { createPortal } from "react-dom";
var numeral = require('numeral');

//Style and Material UI Imports
import * as MobileHRStyle from '../../../../styles/BookingMobile/mobileHRStyle';
// var Carousel = require('react-responsive-carousel').Carousel;
import Carousel from 'react-material-ui-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


//InternalImports
import { ROOMONLY, RoomsDropDownRange } from "../../../../config/constants";
import CounterComponent from '../../../../utils/CounterComponent';
import { addRoomsToCart } from "../../../../slices/bookingDetails";
import { 
    updateMealsInRoom,
    removeMealTypeInRoom
 } from '../../../../slices/bookingDetails';
import MobileRoomCart from "./MobileRoomCart";
import DialogBox from "../../../../utils/DialogBox";
import { MEAL_BREAKFAST, MEAL_LUNCH, MEAL_DINNER } from "../../../../config/constants";

const MobileRoomCategoryInfo = () => {

    const cart = useSelector(state => {
        return state.bookingDetails.cart;
    })

    const [noOfRooms, setNoofRooms] = useState({});

    const[openDialog, setOpenDialog] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const hotelRoomInfo = useSelector(state => {
        return {
            hotelRooms:state.bookingDetails.hotelRooms,
            hotelCategory:state.bookingDetails.hotelCategory,
            hotelRoomImages:state.bookingDetails.hotelRoomImages,
            hotelRoomPricesByCategory: state.bookingDetails.hotelRoomPricesByCategory,
            mealDetails: state.bookingDetails.mealDetails
        }
    })

    //rooms added to cart 
    const selectedRooms = useSelector(state => state.bookingDetails.selectedRooms);

    const getDefaultState = () => {
        let roomInfo = {};
        hotelRoomInfo.hotelRooms.map(room => {
            let roomCat = hotelRoomInfo.hotelCategory.find(cat => cat.hotel_room_type_id == room.hotel_room_type_id);
            roomInfo[room.name] = {
                roomTypeId:room.hotel_room_type_id,
                roomTypeCategoryID:room.hotel_room_type_category_id
            };
        })
        return roomInfo;
    }

    //Code for mealType occupancy in roomSelectionInfo state
    const setMealInfo = (roomInfo,mealType,roomTypeName,occupancy, mealTypeID) => {
        let multiplier = 1;
        if(occupancy == 'double')
            multiplier = 2;
        if(occupancy == 'triple')
            multiplier = 3;
        if(roomInfo.mealInfo.hasOwnProperty(mealType)) {
            let mealInfo = { ...roomInfo.mealInfo };
            delete mealInfo[mealType];
            delete mealInfo['Meal'];
            if(mealType == 'Meal')
                mealInfo = {};
            dispatch(removeMealTypeInRoom({
                name:roomTypeName,
                occupancy,
                uuid:roomInfo.id,
                mealInfo
            }));    
        }
        else {
            let mealInfo = { ...roomInfo.mealInfo };
            mealInfo[mealType] = multiplier * (hotelRoomInfo.mealDetails.find(meal => meal.meal_type == mealTypeID).price);
            if(mealInfo['breakfast'] && mealInfo['lunch'] && mealInfo['dinner']) {
                mealInfo['Meal'] += (multiplier *  hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_BREAKFAST).price) 
                mealInfo['Meal'] += (multiplier * hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_LUNCH).price); 
                mealInfo['Meal'] += (multiplier * hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_DINNER).price);
            }
            if(mealType == 'Meal') {
                mealInfo['Meal'] += (multiplier *  hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_BREAKFAST).price) 
                mealInfo['Meal'] += (multiplier * hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_LUNCH).price); 
                mealInfo['Meal'] += (multiplier * hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_DINNER).price);
                mealInfo['breakfast'] = multiplier *  hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_BREAKFAST).price;
                mealInfo['lunch'] = multiplier *  hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_LUNCH).price;
                mealInfo['dinner'] =  multiplier *  hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_DINNER).price;
            }
            dispatch(updateMealsInRoom({
                name:roomTypeName,
                occupancy,
                uuid:roomInfo.id,
                mealInfo
            }))
        }
    }

    const getHotelOptionsSelected = (hotelRoom,suffix) => {
        let selectedValue = noOfRooms[hotelRoom.name.replaceAll(' ','') + suffix] || 0;
        return selectedValue;
    }

    //Code for Selecting Occupancy
    const getOccupancyLabel = (hotelRoom) => {
        let hotelRoomId  = hotelRoom.hotel_room_type_id;
        let name = hotelRoom.name;
        let hotelRoomCat = hotelRoomInfo.hotelRoomPricesByCategory.find(cat => cat.hotel_room_type_id == hotelRoomId); // && cat.hotel_room_type_category_code == ROOMONLY);
        return (
            <MobileHRStyle.OccupancyBody>
                <MobileHRStyle.OccupancyImgParent>
                    <MobileHRStyle.OccupancyParent>
                        <MobileHRStyle.OccupancyLabelParent>
                            <MobileHRStyle.SingleOccupancyImg />
                            <MobileHRStyle.OccupancyLabelHeader>Single Occupancy</MobileHRStyle.OccupancyLabelHeader>
                        </MobileHRStyle.OccupancyLabelParent>
                        <MobileHRStyle.OccupancyPrice>
                            <span>&#x20B9;</span>
                            <p>
                                { numeral(hotelRoomCat.single_price).format('0,0') }
                            </p>
                            <span>/night</span>
                        </MobileHRStyle.OccupancyPrice>
                    </MobileHRStyle.OccupancyParent>
                    <MobileHRStyle.CounterParent>
                        <CounterComponent 
                            name = { name} 
                            count = { getHotelOptionsSelected(hotelRoom,'single') } 
                            maxCount = { RoomsDropDownRange }
                            onCountChange = {(count,name) => {roomNoCounterChangeHandler(count,hotelRoom,'single')}}
                        />
                    </MobileHRStyle.CounterParent>
                </MobileHRStyle.OccupancyImgParent>
                <MobileHRStyle.OccupancyImgParent>
                    <MobileHRStyle.OccupancyParent>
                        <MobileHRStyle.OccupancyLabelParent>
                            <MobileHRStyle.DoubleOccupancyImg />
                            <MobileHRStyle.OccupancyLabelHeader>Double Occupancy</MobileHRStyle.OccupancyLabelHeader>
                        </MobileHRStyle.OccupancyLabelParent>
                        <MobileHRStyle.OccupancyPrice>
                            <span>&#x20B9;</span>
                            <p>
                                { numeral(hotelRoomCat.double_price).format('0,0') }
                            </p>
                            <span>/night</span>
                        </MobileHRStyle.OccupancyPrice>
                    </MobileHRStyle.OccupancyParent>
                    <MobileHRStyle.CounterParent>
                        <CounterComponent 
                            name = { name} 
                            count = { getHotelOptionsSelected(hotelRoom,'double') } 
                            maxCount = { RoomsDropDownRange }
                            onCountChange = {(count,name) => {roomNoCounterChangeHandler(count,hotelRoom,'double')}}
                        />
                    </MobileHRStyle.CounterParent>
                </MobileHRStyle.OccupancyImgParent>
                <MobileHRStyle.OccupancyImgParent >
                    <MobileHRStyle.OccupancyParent>
                        <MobileHRStyle.OccupancyLabelParent>
                            <MobileHRStyle.TripleOccupancyImg />
                            <MobileHRStyle.OccupancyLabelHeader>Triple Occupancy</MobileHRStyle.OccupancyLabelHeader>
                        </MobileHRStyle.OccupancyLabelParent>
                        <MobileHRStyle.OccupancyPrice>
                            <span>&#x20B9;</span>
                            <p>
                                { numeral(hotelRoomCat.triple_price).format('0,0') }
                            </p>
                            <span>/night</span>
                        </MobileHRStyle.OccupancyPrice>
                    </MobileHRStyle.OccupancyParent>
                    <MobileHRStyle.CounterParent>
                        <CounterComponent 
                            name = { hotelRoom.name} 
                            count = { getHotelOptionsSelected(hotelRoom,'triple') } 
                            maxCount = { RoomsDropDownRange }
                            onCountChange = {(count,name) => {roomNoCounterChangeHandler(count,hotelRoom,'triple')}}
                        />
                    </MobileHRStyle.CounterParent>
                </MobileHRStyle.OccupancyImgParent>
            </MobileHRStyle.OccupancyBody>
        )
    }

    //Code for Meal Selection
    const getMealSelectionHeader = (counter,occupancy) => {
        let occupancelabel = '';
        if(occupancy == 'single')
            occupancelabel = 'Single Occupancy';
        else if(occupancy == 'double')
            occupancelabel = 'Double Occupancy';
        else if(occupancy == 'triple')
            occupancelabel = 'Triple Occupancy';
        return `Select Meal for Room ${counter} - ${occupancelabel}`;
    }

    const getBreakFastMealHTML = (price) => {
        if(price > 0) {
            return (
                <>
                    (
                        <span>&#x20B9;</span>
                        <p>
                            { numeral(price).format('0,0.00') }
                        </p>
                        <span>/person/night</span>
                    )
                </>
            )
        }
        else {
            return (
                <>
                    (Complimentary)
                </>
            )
        }
    }


    const getMealSelections = (hotelRoom) => {
        if (hotelRoomInfo.mealDetails.length == 0)
            return;

        let hotelRoomId = hotelRoom.hotel_room_type_id;
        let name = hotelRoom.name;
        let hotelRoomCat = hotelRoomInfo.hotelRoomPricesByCategory.find(cat => cat.hotel_room_type_id == hotelRoomId); // && cat.hotel_room_type_category_code == ROOMONLY);
        let mealCollection = [];
        let counter = 0;

        let breakfastInfo = hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_BREAKFAST);
        let lunchInfo = hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_LUNCH);
        let dinnerInfo = hotelRoomInfo.mealDetails.find(meal => meal.meal_type == MEAL_DINNER);
        
        if(cart[hotelRoom.name]) {
            for(var o in cart[hotelRoom.name]) {
                let allRooms = cart[hotelRoom.name][o];
                allRooms.map((room) => {
                    let occupancyType = o;
                    let roomInfo = room;
                    counter++;
                    mealCollection.push(
                        <MobileHRStyle.MealSelectionBody>
                            <MobileHRStyle.MealSelectionHeader> { getMealSelectionHeader(counter,o) }</MobileHRStyle.MealSelectionHeader>
                            {   breakfastInfo ?  
                                (<MobileHRStyle.MealSelectionParent onClick = {() => setMealInfo(roomInfo,'breakfast',hotelRoom.name, occupancyType, MEAL_BREAKFAST)}>
                                    <MobileHRStyle.BreakfastImage />
                                    <MobileHRStyle.MealLabel>Breakfast</MobileHRStyle.MealLabel>
                                    <MobileHRStyle.MealPrice>
                                        { getBreakFastMealHTML(breakfastInfo.price) }
                                    </MobileHRStyle.MealPrice>
                                    { (isMealSelected(room,'breakfast') || breakfastInfo.is_free) ? 
                                        <MobileHRStyle.SelectedMealCheckBox></MobileHRStyle.SelectedMealCheckBox> 
                                        : <MobileHRStyle.MealCheckBox></MobileHRStyle.MealCheckBox> 
                                    }
                                </MobileHRStyle.MealSelectionParent>) : null
                            }
                            {   lunchInfo ? (
                                <MobileHRStyle.MealSelectionParent onClick = {() => setMealInfo(roomInfo,'lunch',hotelRoom.name, occupancyType, MEAL_LUNCH)}>
                                    <MobileHRStyle.LunchImage />
                                    <MobileHRStyle.MealLabel>Lunch</MobileHRStyle.MealLabel>
                                    <MobileHRStyle.MealPrice>
                                        (
                                        <span>&#x20B9;</span>
                                        <p>
                                            { numeral(lunchInfo.price).format('0,0.00') }
                                        </p>
                                        <span>/person/night</span>
                                        )
                                    </MobileHRStyle.MealPrice>
                                    { isMealSelected(room,'lunch') ? 
                                        <MobileHRStyle.SelectedMealCheckBox></MobileHRStyle.SelectedMealCheckBox> 
                                        : <MobileHRStyle.MealCheckBox></MobileHRStyle.MealCheckBox> 
                                    }
                                </MobileHRStyle.MealSelectionParent>) : null
                            }
                            {   dinnerInfo ?
                                (<MobileHRStyle.MealSelectionParent onClick = {() => setMealInfo(roomInfo,'dinner',hotelRoom.name, occupancyType, MEAL_DINNER)}>
                                    <MobileHRStyle.DinnerImage />
                                    <MobileHRStyle.MealLabel>Dinner</MobileHRStyle.MealLabel>
                                    <MobileHRStyle.MealPrice>
                                        (
                                        <span>&#x20B9;</span>
                                        <p>
                                            { numeral(dinnerInfo.price).format('0,0.00') }
                                        </p>
                                        <span>/person/night</span>
                                        )
                                    </MobileHRStyle.MealPrice>
                                    { isMealSelected(room,'dinner') ? 
                                        <MobileHRStyle.SelectedMealCheckBox></MobileHRStyle.SelectedMealCheckBox> 
                                        : <MobileHRStyle.MealCheckBox></MobileHRStyle.MealCheckBox> 
                                    }
                                </MobileHRStyle.MealSelectionParent>) : null
                            }
                        </MobileHRStyle.MealSelectionBody>
                    );
                });
            }
        }
        return mealCollection;
    }

    //Code for generating Hotel Room Type and Images
    const getRoomTypeImage = (hotelRoomId) => {
        let images = hotelRoomInfo.hotelRoomImages.filter(img => img.hotel_room_type_id == hotelRoomId);
        if(images?.length > 0) {
            let imagesColl = images.map(img => {
                return (
                    <MobileHRStyle.RoomTypeImage src = { img.photo }  alt = "This is an image for Room Type">
                    </MobileHRStyle.RoomTypeImage>
                );
            })
            return imagesColl;
        } 
    }

    const getRoomType = (hotelRoom) => {
        return (
            <>
                <MobileHRStyle.RoomTypeImageBox>
                    {/* <Carousel showArrows={true}> */}
                    <Carousel autoPlay = { false } indicators = { false } navButtonsAlwaysVisible = { true }>
                        { getRoomTypeImage(hotelRoom.hotel_room_type_id) }
                    </Carousel>
                </MobileHRStyle.RoomTypeImageBox>
                <MobileHRStyle.RoomTypeName>
                    { hotelRoom.name }
                </MobileHRStyle.RoomTypeName>
            </>
        )
    }

    //Check if meal is selecteed
    const isMealSelected = (room,mealType) => {
        if(room['mealInfo']) {
            if(room['mealInfo'][mealType] > 0) 
                return true;
        }
        return false;
    }

    const getRoomCount = () => {
        let roomCount = 0;
        for(var roomName in cart) {
            for(var occ in cart[roomName]) {
                roomCount += cart[roomName][occ].length;
            }
        }
        return roomCount;
    }

    const getRoomPriceBasedOnOcc = (occupancy,hotelRoomInfo) => {
        if(occupancy == 'single')
            return hotelRoomInfo.single_price;
        else if(occupancy == 'double')
            return hotelRoomInfo.double_price;
        else if(occupancy == 'triple')
            return hotelRoomInfo.triple_price;
    }

    const createRoomCardsTobeAdded = (noOfRooms,hotelRoom,suffix) => {
        let hotelObj;
        if(cart[hotelRoom.name])
            hotelObj = {...cart[hotelRoom.name]};
        else {
            hotelObj = {};
        }
        let roomCards = [];

        let hotelCat = hotelRoomInfo.hotelRoomPricesByCategory.find(cat => cat.hotel_room_type_id == hotelRoom.hotel_room_type_id); // && cat.hotel_room_type_category_code == 'ROOM_ONLY');
        for(let i = 0; i < noOfRooms; i ++) {
            let mealInfo = {};
            let breakFastInfo = hotelRoomInfo.mealDetails.find(breakfast => breakfast.meal_type == MEAL_BREAKFAST);
            if(breakFastInfo?.is_free)
                mealInfo['breakfast'] = 0;
            roomCards.push({
                id:uuidv4(),
                price:getRoomPriceBasedOnOcc(suffix,hotelCat),
                roomTypeId:hotelRoom.hotel_room_type_id,
                roomTypeCategoryID:hotelCat.hotel_room_type_category_id,
                mealInfo:mealInfo
            });
        }
        hotelObj[suffix] = roomCards;
        return hotelObj;
    }

    const roomNoCounterChangeHandler = (count,hotelRoom,suffix) => {
        let propName = hotelRoom.name.replaceAll(' ','') + suffix;
        let roomCards = createRoomCardsTobeAdded(count,hotelRoom,suffix);
        dispatch(addRoomsToCart({
            roomType:hotelRoom.name,
            roomCards
        }));
        setNoofRooms((prevObj) => ({
            ...prevObj,
            [propName]:count
        }));
    }

    const showMealsBox = (name) => {
        let showMeals = false;
        if(cart[name]) {
            for(var o in cart[name]) {
                if(cart[name][o].length > 0)
                    showMeals = true;
            }
        }
        return showMeals;
    }

    const getHotelRooms = () => {
        let rooms = hotelRoomInfo.hotelRooms.map(hotelRoom => {
            return (
                <MobileHRStyle.HotelRoom key = { hotelRoom.name }>
                    <MobileHRStyle.RoomType>
                        { getRoomType(hotelRoom) }
                    </MobileHRStyle.RoomType>
                    <MobileHRStyle.RoomOccupancy>
                        { getOccupancyLabel(hotelRoom) }
                    </MobileHRStyle.RoomOccupancy>
                    { showMealsBox(hotelRoom.name) &&
                        <MobileHRStyle.MealSelection>
                            { getMealSelections(hotelRoom) }
                        </MobileHRStyle.MealSelection>
                    }
                </MobileHRStyle.HotelRoom>
            )
        });
        console.log(rooms);
        return rooms;
    }

    const showPadding = () => {
        if(selectedRooms.length > 0) 
            return { showPadding : true }
    }

    const closeDialog = () => {
        setOpenDialog(false);
    }

    return (
        <MobileHRStyle.HotelRoomParent { ...showPadding() }>
            <MobileHRStyle.RoomsHeaderParent>
                <MobileHRStyle.RoomsHeaderText>Available Rooms</MobileHRStyle.RoomsHeaderText>
                <MobileHRStyle.RoomsSubHeaderText>Price Including Tax</MobileHRStyle.RoomsSubHeaderText>
            </MobileHRStyle.RoomsHeaderParent>
            { getHotelRooms() }
            { getRoomCount() > 0 && 
                createPortal(<MobileRoomCart />,document.body)
            }
            <DialogBox buttonText = "Ok" dialogContent = "Please select room." open = { openDialog } buttonHandler = { closeDialog }/>
        </MobileHRStyle.HotelRoomParent>
    )
}

export default MobileRoomCategoryInfo;