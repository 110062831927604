import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const CartParent = styled(Box)`
&& {
    background: #FFFFFF;
    height:5rem;
    border-top: 1px solid #4D4C4C;
    box-shadow: 0px -5px 8px rgba(63, 61, 61, 0.17);
    border-radius: 15px 15px 0px 0px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    bottom:0;
    width:600px;
    left:50%;
    transform: translateX(-50%);
    @media (max-width: 576px) {
        width: 100%;
        left:unset;
        transform:unset;
    }
    z-index:2;
}`

export const CartDetails = styled(Box)`
&& {

}`

export const PriceDetails = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #091222;
}`

export const Duration = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    text-decoration-line: underline;
    color: #091222;
}`

export const BookNow = styled(Button)`
&& {
    background: linear-gradient(180deg, #F31771 0%, #D0105F 100%);
    border-radius: 5px;
    height:2.5rem;
    padding:0 2rem; 
    text-transform:capitalize;
}`
