import styled from "styled-components";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { Typography } from "@mui/material";

export const ReviewsContainerBox = styled(Box)`
&& {
   background-color:white;
   padding:1rem 1rem 2rem 1rem;
}`

export const RatingsBox = styled(Box)`
&& {
    border-radius: 8px;
    border: 0.5px solid var(--light-blue, #A6BCDA);
    background: #FFF;
    display: flex;
    width: 100%;
    padding: 0.5rem 1rem;
    align-items:center;
}`

export const Ratings = styled(Rating)`
&& {
    font-size:1rem;
}`

export const RatingImage = styled(Box)`
&& {
    background-image: url(styles/assets/grating.svg);
    background-repeat: no-repeat;
    height: 60px;
    width: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-position: 50% 60%;
    background-size: 70%;
    border-radius:5px;
    border:1px solid lightgray;
    position:relative;
    &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 14px 0 1px 14px;
        border-color: transparent #FFFFFF;
        display: block;
        width: 0;
        z-index: 1;
        right: -12px;
        top: 24px;
    };
    &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 15px 0 1px 15px;
        border-color: transparent lightgray;
        display: block;
        width: 0;
        z-index: 0;
        right: -15px;
        top: 24px;
    };
}`

export const RatingText = styled(Box)`
&& {
    color: #000;
    font-family: Red Hat Display;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}`

export const RatingTotal = styled(Typography)`
&& {
    color: #000;
    font-family: Red Hat Display;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
}`

export const RatingParent = styled(Box)`&& {
    display:flex;
    flex-direction:column;
    margin-left:1rem;
}`

export const RatingHeader = styled(Typography)`
&& {

}`

export const RagingContainer = styled(Box)`
&& {
    display:flex;
}`

export const RatingCompContainer = styled(Box)`
&& {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}`