import React from "react";
import { useSelector, useDispatch } from "react-redux";

//Material UI and style Imports
import * as MobileCheckoutStyle from '../../../styles/BookingMobile/mobileCheckoutStyle';

//Internal Imports
import { setGuestDetails } from '../../../slices/bookingDetails';

const MobileGuestDetails = (props) => {

    const dispatch = useDispatch();

    const  guestDetails = useSelector(state => {    
        return [...state.bookingDetails.selectionCriteria.guestDetails]
    });

    const addPrimaryGuest = (event) => {
        let primaryGuest = {};
        let guestFound = false;
        let allGuest = [...guestDetails];
        if(guestDetails.find(guest => guest.Id == 1) != null) {
            guestFound = true;
            primaryGuest = {...guestDetails.find(guest => guest.Id == 1)};
        }
        else {
            primaryGuest["Id"] = 1;
        }
        primaryGuest[event.target.name] = event.target.value;
        if(!guestFound) 
            allGuest.push(primaryGuest);
        else {
            allGuest = [...allGuest.filter(guest => guest.Id != 1),primaryGuest];
        }
        dispatch(setGuestDetails(allGuest));
    }

    const getGuestDetail = (name) => {
        let primaryGuest = null
        if(guestDetails.find(guest => guest.Id == 1) != null)
            primaryGuest = guestDetails.find(guest => guest.Id == 1);
        return (primaryGuest) ? primaryGuest[name] : '';
    }

    const checkIfError = (name) => {
        if(props.errObj[name]) {
            return {
                error:true
            }
        }
    }

    const getErrorMessage = (name) => {
        return props.errObj[name];
    }

    return (
        <MobileCheckoutStyle.GuestBookingParent>
            <MobileCheckoutStyle.GuestLabel>I am booking for</MobileCheckoutStyle.GuestLabel>
            <MobileCheckoutStyle.GDRow>
                <MobileCheckoutStyle.GDColumn>
                    <MobileCheckoutStyle.GDColumnLabel> { 'Full Name' } {<span>*</span> } </MobileCheckoutStyle.GDColumnLabel>
                    <MobileCheckoutStyle.GDColumnInput autoComplete = "off" { ...checkIfError('fullname') } helperText = {getErrorMessage('fullname')} required = "true" onBlur = { addPrimaryGuest } name = 'fullname' size="small" placeholder="Please enter full name"> { getGuestDetail('fullname') } </MobileCheckoutStyle.GDColumnInput>
                </MobileCheckoutStyle.GDColumn>
                <MobileCheckoutStyle.GDColumn>
                    <MobileCheckoutStyle.GDColumnLabel> Contact No. {<span>*</span> }</MobileCheckoutStyle.GDColumnLabel>
                    <MobileCheckoutStyle.GDColumnInput autoComplete = "off" { ...checkIfError('mobile') } helperText = {getErrorMessage('mobile')} onBlur = { addPrimaryGuest } name = 'mobile' size="small" placeholder="Please enter mobile number"> { getGuestDetail('mobile') } </MobileCheckoutStyle.GDColumnInput>
                </MobileCheckoutStyle.GDColumn>
                <MobileCheckoutStyle.GDColumn>
                    <MobileCheckoutStyle.GDColumnLabel> Email {<span>*</span> }</MobileCheckoutStyle.GDColumnLabel>
                    <MobileCheckoutStyle.GDColumnInput autoComplete = "off" { ...checkIfError('email') } helperText = {getErrorMessage('email')} onBlur = { addPrimaryGuest } name = 'email' size="small" placeholder="Please enter email address"> { getGuestDetail('email') } </MobileCheckoutStyle.GDColumnInput>
                </MobileCheckoutStyle.GDColumn>
            </MobileCheckoutStyle.GDRow>
        </MobileCheckoutStyle.GuestBookingParent>
    )
}

export default MobileGuestDetails;