import axios from "axios";

const API = axios.create({
  baseURL:'/api/restful'
});

export const SNSAPI = axios.create({
  baseURL:'api/notification'
})

export const GOOGLEAPI = axios.create({
  baseURL:'api/googleAPI'
})
export default API;
