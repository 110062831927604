//No of Adults allowed per Room
export const AllowedAdults = 3;

//Desgination Codes
export const OWNER = 'OWNER';
export const MANAGER = 'MANAGER';
export const OTHER = 'OTHER';

//Room Categories
export const ROOMONLY = 'ROOM_ONLY';

export const RoomsDropDownRange = 5;

export const MAXROOMPERBOOKING = 10;

//Validation Regex
export const MOBILE_REGEX = /^[6-9]\d{9}$/;
export const NAME_REGEX = /^(?![ .])[\w\s]{3,}(?:\s\.)?$/;

export const RANDOMKEY = 'AIzaSyBUnxNu8EZwMrE1HnO5stIreZ80Ya-3oZg';

//Slot and Stay Type
export const OVERNIGHT_STAY = 'OVERNIGHT_STAY';
export const HOURLY_STAY = 'HOURLY_STAY';
export const THREE_HOURS = 'THREE_HOURS';
export const SIX_HOURS = 'SIX_HOURS';
export const NINE_HOURS = 'NINE_HOURS';


export const MEAL_BREAKFAST = 1;
export const MEAL_LUNCH = 2;
export const MEAL_DINNER = 3;
