import React, { useState } from "react";
import OutsideClickHandler from 'react-outside-click-handler';

//Internal Imports 
import MobileCancelPopUp from "./MobileCanclePopUp";

//styled imports 
import * as MCStyle from '../../../styles/BookingMobile/mobileCancelStyle';
import { Portal } from "@mui/material";

const MobileCancelPage = () => {

    const [showDropdown, setShowDropdown] = useState(false);

    const cancelBooking = () => {
        setShowDropdown(!showDropdown);
    }

    const closeDropdown = () => {
        setShowDropdown(false);
    }


    return (
        <MCStyle.MCContainer>
            <MCStyle.MCHeader>
                Cancel Booking
            </MCStyle.MCHeader>
            <MCStyle.MCText>
                Have an existing booking you want to cancel?
            </MCStyle.MCText>
            <MCStyle.MCButton variant = "outlined" size = "small" onClick = { cancelBooking }>
                Cancel Booking
            </MCStyle.MCButton>
            { showDropdown && 
                <Portal>
                    <OutsideClickHandler onOutsideClick={ closeDropdown }>
                        <MobileCancelPopUp closePopUP = { closeDropdown }/>
                    </OutsideClickHandler>
                </Portal>
            }
        </MCStyle.MCContainer>
    )
}

export default MobileCancelPage;