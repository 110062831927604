import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import dayjs from "dayjs";

//Material UI and styled Imports
import * as MobileCheckoutStyle from '../../../styles/BookingMobile/mobileCheckoutStyle';
import * as BookingHIStyle from '../../../styles/Booking/bookingLPStyle';
import * as MobileHIStyle from '../../../styles/Booking/mobileLPStyle';

import { NINE_HOURS, OVERNIGHT_STAY, SIX_HOURS, THREE_HOURS } from '../../../config/constants';


const MobileCheckoutDuration = ({showAddress}) => {

    const dateRange  = useSelector(state => state.bookingDetails.selectionCriteria.dateRange);

    const hotelImages = useSelector(state => state.bookingDetails.hotelPhotos);

    const STAY_TYPE = useSelector(state => state.bookingDetails.STAY_TYPE);

    const cart = useSelector(state => state.bookingDetails.cart);

    const selectionCriteria = useSelector(state => state.bookingDetails.selectionCriteria);

    //const contactInfo = {contact_value:234234234324}//
    const contactInfo = useSelector(state => state.bookingDetails.contactDetails?.find(contact => contact.contact_type == 'Phone'));

    const hotelInfo = useSelector((state) => {
        return state.bookingDetails.hotelDetails;
    });

    const hotelDetails = useSelector(state => state.bookingDetails.hotelDetails || { attributes : {'check-in' : '', 'check-out':''}});

    const getCheckinTime = () => {
        let currDate = new Date();
        const amPm = currDate.getHours() >= 12 ? 'PM' : 'AM';
        let hoursMin = currDate.getHours() + ':' + currDate.getMinutes() + ' ' + amPm;
        if(selectionCriteria.hourlySelectionInfo.checkInTime)
            return (selectionCriteria.hourlySelectionInfo.checkInTime).toUpperCase();
        return hoursMin;
    }

    const getDateForTime = () => {
        const [time, amPm] = selectionCriteria.hourlySelectionInfo.checkInTime.split(' ');
        const [hours, minutes] = time.split(':');

        // Convert hours and minutes to integers
        const parsedHours = parseInt(hours, 10);
        const parsedMinutes = parseInt(minutes, 10);

        // Create a new Date object for the current date
        const currentDate = new Date(selectionCriteria.hourlySelectionInfo.startDate);

        // Set the time components on the Date object
        currentDate.setHours(parsedHours);
        currentDate.setMinutes(parsedMinutes);

        // Adjust for AM/PM if necessary
        if (amPm === 'PM' && parsedHours < 12) {
            currentDate.setHours(parsedHours + 12);
        } else if (amPm === 'AM' && parsedHours === 12) {
            currentDate.setHours(0);
        }
        return currentDate;
    }

    const getPaddedMinutes = (minutes) => {
        return minutes < 10 ? `0${minutes}` : `${minutes}`;
    }

    const getCheckoutTime = () => {
        let roomKey = Object.keys(cart)[0];
        let occKey = Object.keys(cart[roomKey])[0];
        let slotType = cart[roomKey][occKey][0]['HOURLY_SLOT'];
        let stayHours = 0;
        if(slotType == THREE_HOURS)
            stayHours = 3;
        else if(slotType == SIX_HOURS)
            stayHours = 6;
        else if(slotType == NINE_HOURS)
            stayHours = 9;

        let currDate = new Date();
        if(selectionCriteria.hourlySelectionInfo.checkInTime) {
            currDate = getDateForTime();
        }
        const amPm = (currDate.getHours() + stayHours ) >= 12 ? 'PM' : 'AM';
        let hoursMin = (currDate.getHours() + stayHours) + ':' + getPaddedMinutes(currDate.getMinutes()) + ' ' + amPm;
        return hoursMin;
    }

    const getCheckinDate = () => {
        return formatDate(dateRange[0], 'dd MMM yy');
    }

    const getCheckoutDate = () => {
        return formatDate(dateRange[1], 'dd MMM yy');
    }

    const getNoOfNight = () => {
        let noOfDays = (dayjs((new Date(dateRange[1])))).diff(dayjs((new Date(dateRange[0]))),'day');
        if(noOfDays > 1) 
            return `${noOfDays} nights`
        return `${noOfDays} night`
    }

    const getSlotType = () => {
        let roomKey = Object.keys(cart)[0];
        let occKey = Object.keys(cart[roomKey])[0];
        let slotType = cart[roomKey][occKey][0]['HOURLY_SLOT'];
        if(slotType == THREE_HOURS)
            return 'Upto 3 Hours';
        else if(slotType == SIX_HOURS)
            return 'Upto 6 Hours';
        else if(slotType == NINE_HOURS)
            return 'Upto 9 Hours';
    }

    const formatDate = (date,formatString) => {
        console.log(format(new Date(date), formatString));
        return format(new Date(date), formatString);
    }

    const formatDateAddHours = (date,formatString) => {
        let roomKey = Object.keys(cart)[0];
        let occKey = Object.keys(cart[roomKey])[0];
        let slotType = cart[roomKey][occKey][0]['HOURLY_SLOT'];
        if(slotType == THREE_HOURS)
            return 'Upto 3 Hours';
        else if(slotType == SIX_HOURS)
            return 'Upto 6 Hours';
        else if(slotType == NINE_HOURS)
            return 'Upto 9 Hours';
        console.log(format(new Date(date), formatString));
        return format(new Date(date), formatString);
    }

    const getImg = () => {
        return (hotelImages.length > 0) ? hotelImages[0].photo : '';
    }

    const getHotelAddressText = () => {
        if(Object.keys(hotelInfo).length > 0) {
            if(hotelInfo.address.trim().endsWith(','))
                return `${hotelInfo.address} ${hotelInfo.city}, ${hotelInfo.state} - ${hotelInfo.pincode}`
            else
                return `${hotelInfo.address}, ${hotelInfo.city}, ${hotelInfo.state} - ${hotelInfo.pincode}`
        }
    }

    const getCheckInText = () => {
        if(STAY_TYPE == OVERNIGHT_STAY) {
            return (
                <MobileCheckoutStyle.CheckinTime>
                    Check-in : 
                    <span>2 PM</span>
                </MobileCheckoutStyle.CheckinTime>
            )
        }
        else {
            return (
                <MobileCheckoutStyle.CheckinTime>
                    Check-in :
                    <span>{ getCheckinTime() }</span>
                </MobileCheckoutStyle.CheckinTime>
            )
        }
    }

    const getCheckOutText = () => {
        if(STAY_TYPE == OVERNIGHT_STAY) {
            return (
                <MobileCheckoutStyle.CheckinTime>
                    Check-out : 
                    <span>11 AM</span>
                </MobileCheckoutStyle.CheckinTime>
            )
        }
        else {
            return (
                <MobileCheckoutStyle.CheckinTime>
                    Check-out :
                    <span>{ getCheckoutTime() }</span>
                </MobileCheckoutStyle.CheckinTime>
            )
        }
    }

    const getCheckOutDetails = () => {
        if(STAY_TYPE == OVERNIGHT_STAY) {
            return (
                <MobileCheckoutStyle.CheckoutInfoParent>
                    <MobileCheckoutStyle.CheckInfInfo>
                        { getCheckInText() }
                        <MobileCheckoutStyle.CheckinDate> { getCheckinDate() }</MobileCheckoutStyle.CheckinDate>
                        <MobileCheckoutStyle.CheckinDay> { formatDate(dateRange[0], 'EEEE') } </MobileCheckoutStyle.CheckinDay>
                    </MobileCheckoutStyle.CheckInfInfo>
                    <MobileCheckoutStyle.DurationInfo>
                        { getNoOfNight() }
                    </MobileCheckoutStyle.DurationInfo>
                    <MobileCheckoutStyle.CheckOutInfo>
                        { getCheckOutText() }
                        <MobileCheckoutStyle.CheckinDate> { getCheckoutDate() } </MobileCheckoutStyle.CheckinDate>
                        <MobileCheckoutStyle.CheckinDay> { formatDate(dateRange[1], 'EEEE') } </MobileCheckoutStyle.CheckinDay>
                    </MobileCheckoutStyle.CheckOutInfo>
                </MobileCheckoutStyle.CheckoutInfoParent>
            )
        }
        else {
            return (
                <MobileCheckoutStyle.CheckoutInfoParent>
                    <MobileCheckoutStyle.CheckInfInfo>
                        { getCheckInText() }
                        <MobileCheckoutStyle.CheckinDate> { getCheckinDate() }</MobileCheckoutStyle.CheckinDate>
                        <MobileCheckoutStyle.CheckinDay> { formatDate(dateRange[0], 'EEEE') } </MobileCheckoutStyle.CheckinDay>
                    </MobileCheckoutStyle.CheckInfInfo>
                    <MobileCheckoutStyle.DurationInfo>
                        { getSlotType() }
                    </MobileCheckoutStyle.DurationInfo>
                    <MobileCheckoutStyle.CheckOutInfo>
                        { getCheckOutText() }
                        <MobileCheckoutStyle.CheckinDate> { getCheckinDate() } </MobileCheckoutStyle.CheckinDate>
                        <MobileCheckoutStyle.CheckinDay> { formatDate(dateRange[0], 'EEEE') } </MobileCheckoutStyle.CheckinDay>
                    </MobileCheckoutStyle.CheckOutInfo>
                </MobileCheckoutStyle.CheckoutInfoParent>
            )
        }
    }

    return (
        <>
            { showAddress &&
                <MobileCheckoutStyle.DummyParentImg>
                    <MobileCheckoutStyle.AddressImgParent>
                        <MobileCheckoutStyle.AddressParent>
                            <BookingHIStyle.Address>
                                <MobileHIStyle.HotelName>
                                    { hotelInfo.name }
                                </MobileHIStyle.HotelName>
                                <MobileHIStyle.HotelAddressBox>
                                    <MobileHIStyle.HotelAddressImg></MobileHIStyle.HotelAddressImg>
                                    <BookingHIStyle.HotelAdress>
                                        { getHotelAddressText() }
                                    </BookingHIStyle.HotelAdress>
                                </MobileHIStyle.HotelAddressBox>
                                <MobileHIStyle.HotelAddressBox>
                                    <MobileHIStyle.HotelNumberImg></MobileHIStyle.HotelNumberImg>
                                    <BookingHIStyle.HotelNumber onClick = {() => window.open(`tel:+91${contactInfo?.contact_value}`,'_blank')}>
                                        { contactInfo?.contact_value }
                                    </BookingHIStyle.HotelNumber>
                                </MobileHIStyle.HotelAddressBox>
                            </BookingHIStyle.Address>
                        </MobileCheckoutStyle.AddressParent>
                        <MobileCheckoutStyle.ConfirmHotelImg src = { getImg() }></MobileCheckoutStyle.ConfirmHotelImg>
                    </MobileCheckoutStyle.AddressImgParent>
                </MobileCheckoutStyle.DummyParentImg>
            }
            { getCheckOutDetails() }
        </>
    )
}

export default MobileCheckoutDuration;