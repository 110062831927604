import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//Internal Imports
import MobileHourlyRoomCategoryInfo from './MobileHourlyRoomCategoryInfo';
import RoomSelection from '../../RoomSelection/RoomSelection';
import BookingPageHeader from '../../BookinPageHeader/BookingPageHeader';
import { setHotelRoomDetails } from '../../../../slices/bookingDetails';
import API from '../../../../api';

//Style and MUI Imports
import * as HotelRoomStyle from '../../../../styles/Booking/hotelRoomStyle';
import * as MobileHRStyle from '../../../../styles/BookingMobile/mobileHRStyle';

const HourlyRoomCategoryMobileLandingPage = () => {

    const dispatch = useDispatch();

    const hotelId = useSelector(state => state.bookingDetails.hotelId);

    const hotelInfo = useSelector((state) => {
        return state.bookingDetails.hotelDetails;
    })

    const hotelImages = useSelector(state => {
        return state.bookingDetails.hotelPhotos;
    });

    const getMainImage = () => {
        return hotelImages.find(img => img.photo_type == "MAIN").photo;
    }

    useEffect(() => {
        let promiseArray = [];
        promiseArray.push(API.get(`/getHotelRoomsByHotelId?hotel_id=${hotelId}`));
        promiseArray.push(API.get(`/getHotelRoomCategoriesByHotelId?hotel_id=${hotelId}`));
        promiseArray.push(API.get(`/getHotelRoomPhotosUsingHotelId?hotel_id=${hotelId}`));
        Promise.all(promiseArray).then((res) => {
            let hotelRooms = res[0].data.data;
            let hotelCategory = res[1].data.data;
            let hotelRoomImages = res[2].data.data;
            dispatch(setHotelRoomDetails({
                hotelRooms,
                hotelCategory,
                hotelRoomImages
            }));
        });
        return () => {
            
        }
    },[])

    return(
        <MobileHRStyle.MobileRoomParent>
            {/* <BookingPageHeader name = { hotelInfo.name } phone = { hotelInfo.phone }/>
            <BookingHIStyle.RoomSelectionParent>
                <RoomSelection />
            </BookingHIStyle.RoomSelectionParent> */}
            <HotelRoomStyle.RoomCategoryParent >
                <MobileHourlyRoomCategoryInfo />
            </HotelRoomStyle.RoomCategoryParent>
        </MobileHRStyle.MobileRoomParent>
    )
}

export default HourlyRoomCategoryMobileLandingPage;