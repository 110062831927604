import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as yup from 'yup'
import dayjs from 'dayjs';
var numeral = require('numeral');

//Material and styled Imorts
import * as MobileCheckoutStyle from '../../../styles/BookingMobile/mobileCheckoutStyle';
import { Box } from '@mui/material';

//Internal Imports
import MobileCheckoutDuration from './MobileCheckoutDuration';
import MobileRoomCards from './MobileRoomCards';
import MobileGuestDetails from './MobileGuestDetails';
import { MOBILE_REGEX, NAME_REGEX } from '../../../config/constants';
import API from '../../../api';
import MobileBookingConfirmation from './MobileBookingConfirmation';
import { SNSAPI } from '../../../api';

const MobileCheckout = () => {

    const history = useHistory();

    //Error object for Notification
    const [errorObj, setErrorObj] = useState({
        open:false,
        message:'',
        severity:'info'
    });


    let hotelId = 1;
    try {
        hotelId = useSelector(state => state.bookingDetails.hotelId);
    }
    catch(ex) {

    }

    const [guestDetailsErr, setGuestDetailsErr] = useState({});

    const  guestDetails = useSelector(state => {    
        return [...state.bookingDetails.selectionCriteria.guestDetails]
    });

    //State for Booking Confirmation PopUp
    const [openDialog, setOpenDialog] = useState(false);

    const yupObject = yup.object({
        name:yup.string().required().matches(NAME_REGEX,'Please enter a valid name'),
        mobile_number:yup.string().required().matches(MOBILE_REGEX,'Please enter a valid mobile number.'),
        email_id:yup.string().required().email(),
    })

    const hotelImages = useSelector(state => {
        return state.bookingDetails.hotelPhotos;
    });

    const hotelDetails = useSelector(state => state.bookingDetails.hotelDetails);

    const contactDetails = useSelector(state => state.bookingDetails.contactDetails);

    const hotelEmployees = useSelector(state => state.bookingDetails.hotelEmployees);

    const selectionCriteria = useSelector(state => state.bookingDetails.selectionCriteria);

    const cart = useSelector(state => state.bookingDetails.cart);

    const getImageUrl = () => {
        if(hotelImages.length > 0)
            return hotelImages.find(img => img.photo_type == "MAIN").photo;
    }

    const getContactNumber = () => {
        let contactNo = contactDetails?.find(contact => contact.contact_type == 'Phone');
        if(contactNo)
            return contactNo.contact_value;
        return ''
    }

    //Get the total Amount to be Paid for the booking.
    const getTotalAmountTobePaid = () => {
        let totalAmount = 0;
        for(var o in cart) {
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                roomsOcc.map(room => {
                    totalAmount += parseInt(room.price);
                    for(var mealType in room.mealInfo) {
                        totalAmount += parseInt(room.mealInfo[mealType]);
                    }
                })
            }
        }
        let noOfNights = (dayjs((new Date(selectionCriteria.dateRange[1])))).diff(dayjs((new Date(selectionCriteria.dateRange[0]))),'day');
        //return totalAmount;
        return numeral(totalAmount * noOfNights).format('0,0');
    }

    //Get User Room Bookings
    const getUserRoomBookings = (bookingId) => {
        let userRoomBookings = [];
        for(var o in cart) {
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                roomsOcc.map(room => {
                    userRoomBookings.push({
                        user_booking_id:bookingId,
                        hotel_room_type_id:room.roomTypeId,
                        hotel_room_type_category_id:1,//selectionCriteria.roomTypeInfo.roomCategory.hotel_room_type_category_id,
                        hotel_room_id:null,
                        check_in_date:dayjs(selectionCriteria.dateRange[0],'yyyy-MM-dd').toISOString(),
                        check_out_date:dayjs(selectionCriteria.dateRange[1],'yyyy-MM-dd').toISOString(),
                        amount_per_night:0
                    });
                })
            }
        }
        return userRoomBookings;
    }

    //Validate the Mandatory Info before Reservation
    const validateInfo = () => {
        if(selectionCriteria.guestDetails.length == 0) {
            let obj = {
                fullname:'Please enter a valid name',
                mobile:'Please enter a valid mobile number',
                email:'Please enter a valid email id'
            }
            setGuestDetailsErr((prevObj) => ({
                ...obj
            }))
            return false;
        }
        let objectToValidate = {
            name:selectionCriteria.guestDetails[0].fullname,
            email_id:selectionCriteria.guestDetails[0].email,
            mobile_number:selectionCriteria.guestDetails[0].mobile
        }
        yupObject.validate(objectToValidate,{ abortEarly:false}).then((res) => {
            makeReservation();
        }).catch(err => {
            var a = 1;
            let hasError = false;
            const errors = err.inner.reduce((acc, error) => {
                return {
                    ...acc,
                    [error.path]: true,
                }
                }, {}
            );
            let obj = {};
            if(errors['name']) {
                hasError = true;
                obj['fullname'] = 'Please enter a valid name.';
            }
            if(errors['mobile_number']) {
                hasError = true;
                obj['mobile'] = 'Please enter a valid mobile number.';
            }
            if(errors['email_id']) {
                hasError = true;
                obj['email'] = 'Please enter a valid Email ID.';
            }
            setGuestDetailsErr((prevObj) => ({
                ...obj
            }));
        })
    }

    const validateandMakeReservation = () => {
        validateInfo();
    }

    const generateRandomVarcharNumber = () =>  {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const numericCharset = '0123456789';
    
        let varcharPart = '';
        let numericPart = '';
    
        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            varcharPart += charset[randomIndex];
        }
        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * numericCharset.length);
            numericPart += numericCharset[randomIndex];
        }
        const result = varcharPart + numericPart;
        return result;
    }

    //Make Reservation button Handler.
    const makeReservation = async (event) => {
        try {
            let totalAmount = getTotalAmountTobePaid();
            let userId = -1;
            //Check IF Primary Guest Exists in Database.
            let userInfo = await API.get(`/getUser?mobile_number=${selectionCriteria.guestDetails[0].mobile}`);

            //Craete a user for Primary Guest If does not exist in Data base.
            if(userInfo.data.count == 0) {
                userInfo = await API.post('/createUser',{
                    name:selectionCriteria.guestDetails[0].fullname,
                    email_id:selectionCriteria.guestDetails[0].email,
                    mobile_number:selectionCriteria.guestDetails[0].mobile,
                    image_location:'',
                    attributes:'{}'
                });
                userId = userInfo.data.data.insertId;
            }
            else 
                userId = userInfo.data.data[0].user_id;

            let generateBookingId = generateRandomVarcharNumber();

            //Create Reservation Info.
            let reservation = {
                user_id:userId,
                hotel_id:hotelId,
                // check_in_date:dayjs(selectionCriteria.dateRange[0],'yyyy-MM-dd').toISOString(),
                // check_out_date:dayjs(selectionCriteria.dateRange[1],'yyyy-MM-dd').toISOString(),
                check_in_date:dayjs(selectionCriteria.dateRange[0]).format('YYYY-MM-DD'),
                check_out_date:dayjs(selectionCriteria.dateRange[1]).format('YYYY-MM-DD'),
                total_amount:totalAmount.replace(',',''),
                coupon_code:'',
                coupon_type:'',
                coupon_value:0,
                total_amount_after_discounts:totalAmount.replace(',',''),
                paying_now:0,
                paying_at_hotel:totalAmount.replace(',',''),
                status:'PENDING',
                booking_constraints:'{}',
                generated_booking_id:generateBookingId
            };
            
            const bookingConfirmation = await API.post('/makeReservation',reservation);
            let bookingId = bookingConfirmation.data.data.insertId;

            //Add all the guests to the current booking.
            const guestDetails = selectionCriteria.guestDetails.map((guest) => {
                return {
                    user_booking_id:bookingId,
                    guest_name:guest.fullname,
                    guest_email_id:guest.email,
                    guest_mobile_number:guest.mobile,
                    guest_gst_number:''
                }
            }); 

            const guestsAdded = await API.post('/addGuestsToReservation',guestDetails);
            const addRoomToBooking = await API.post('/addRoomsToReservation', getUserRoomBookings(bookingId))
            //setOpenDialog(true);
            sendMail({
                amount:totalAmount,
                bookingId,
                guestName:guestDetails[0].guest_name,
                mobile:guestDetails[0].guest_mobile_number,
                email:guestDetails[0].guest_email_id,
                generateBookingId
            })
            history.push(`/confirmed/${generateBookingId}`);
            //history.push(`/confirmed`);
        }
        catch(ex) {
            setErrorObj((prevObj) => ({
                ...errorObj,
                open:true,
                message:'Something went wrong.Please try again after some time.',
                severity:'error'
            }));
        }
    }

    const backClickHandler = (event) => {
        history.push('/');
    }

    const getGuestCount = () => {
        let text = '';
        if(selectionCriteria.roomInfo.Adults > 1)
            text += (selectionCriteria.roomInfo.Adults + ' Adults,')
        else if(selectionCriteria.roomInfo.Adults == 1)
            text += (selectionCriteria.roomInfo.Adults + ' Adult,')
        if(selectionCriteria.roomInfo.Children > 1)
            text += (selectionCriteria.roomInfo.Children + ' Children,')
        else if (selectionCriteria.roomInfo.Children == 1)
            text += (selectionCriteria.roomInfo.Children + ' Child,')
        if(selectionCriteria.roomInfo.Infant > 1)
            text += (selectionCriteria.roomInfo.Infant + ' Infants')
        else if (selectionCriteria.roomInfo.Infant == 1)   
            text += (selectionCriteria.roomInfo.Infant + ' Infant')
        return text.substring(0,text.length - 1);
    }

    const getRoomBookingInfo = () => {
        let roomCatText = ''
        let currentRoomCount = 0;
        let roomCount = 0;
        for(var o in cart) {
            currentRoomCount = 0;
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                roomCount += roomsOcc.length;
                currentRoomCount += roomsOcc.length;
            }
            roomCatText += `${currentRoomCount} ${o} ,`;
        }
        return roomCatText.substring(0,roomCatText.length - 1);
    }

    const isMealSelected = () => {
        let hasMeal = false
        for(var o in cart) {
            let roomType = cart[o];
            for(var room in roomType) {
                if(room.mealInfo)
                    hasMeal = true;
            }
        }
        if(hasMeal)
            return 'Meal Selected';
        else
            return 'Not Selected';
    }

    const getItemDesc = (room) => {
        let desc = '';
        if(room == 'single')
            desc = 'Room price for 1 Night X 1 Guest'
        else if (room == 'double')
            desc = 'Room price for 1 Night X 2 Guests'
        else if (room == 'triple')
            desc = 'Room price for 1 Night X 3 Guests'
        return desc
    }

    const getRoomDesc = () => {
        let items = [];
        for(var o in cart) {
            let roomType = cart[o];
            for(var room in roomType) {
                let itemObj = {};
                itemObj.roomName = o;
                itemObj.desc = getItemDesc(room);
                itemObj.price = roomType[room][0].price;
                items.push(itemObj);
            }
        }
        return items;
    }

    const sendMail = async(params) => {

        let bookingInfo = getRoomBookingInfo()
        let roomItems = getRoomDesc();
        let mailParams = {
            subject:`New reservation received | Guest Name: ${params.guestName} | Booking ID: ${params.generateBookingId}`,
            heading:`Date: ${dayjs(selectionCriteria.dateRange[0]).format('MMM DD')} - ${dayjs(selectionCriteria.dateRange[1]).format('MMM DD')}, ${(new Date()).getFullYear()} | Booking ID: ${params.generateBookingId} | Room Count: ${bookingInfo[0]} | Pay at Hotel`,
            guestName:params.guestName,
            checkIn:`${dayjs(selectionCriteria.dateRange[0]).format('MMM DD YYYY')}`,
            checkInTime:`From 2:00 PM`,
            checkOut:`${dayjs(selectionCriteria.dateRange[1]).format('MMM DD YYYY')}`,
            checkOutTime:`To 11:00 AM`,
            noOfNights: (dayjs((new Date(selectionCriteria.dateRange[1])))).diff(dayjs((new Date(selectionCriteria.dateRange[0]))),'day'),
            bookingId:params.generateBookingId.toUpperCase(),
            hotelName:hotelDetails.name,
            hotelAddress:hotelDetails.address,
            hotelContactNo:contactDetails.find(ct => ct.contact_type == 'Phone')?.contact_value,
            occupancy:bookingInfo[1],
            roomCat:bookingInfo[2],
            amount:params.amount,
            plan:'',
            payReceived:'0',
            mobileNumber:params.mobile,
            email:params.email,
            guestEmail:params.email,
            cancellationDate:(dayjs(selectionCriteria.dateRange[0]).subtract(1,'day').format('DD MMM') + ' 11:00 AM'),
            advancePaid:0,
            balanceAmount:  params.amount - 0,
            guestCount:getGuestCount(),
            noOfRooms:getRoomBookingInfo(),
            mealSelected:isMealSelected(),
            items:roomItems
        }

        let hotelEmailTo = '';
        hotelEmployees.map(emp => {
            hotelEmailTo += (emp.email_id + ',');
        })
        hotelEmailTo = hotelEmailTo.substring(0,hotelEmailTo.length - 1);

        let startDate = dayjs(selectionCriteria.dateRange[0]).format('DD MMM YY');
        let userMailParams = { ...mailParams}
        userMailParams.subject = `Booking Voucher | ${hotelDetails.name}, ${hotelDetails.city} | ${dayjs(selectionCriteria.dateRange[0]).format('MMM DD YYYY')} - ${dayjs(selectionCriteria.dateRange[1]).format('MMM DD YYYY')}`;
        userMailParams.heading = `Date: ${dayjs(selectionCriteria.dateRange[0]).format('MMM DD')} - ${dayjs(selectionCriteria.dateRange[1]).format('MMM DD')}, ${(new Date()).getFullYear()} | Booking ID: ${params.generateBookingId} | Amount: INR ${params.amount}`;
        userMailParams.greeting = `${params.guestName}, Thank you for booking with us!`
        mailParams.email = hotelEmailTo;

        const respMail = await SNSAPI.post('/sendNotification',{
            notificationType:'EMAIL',
            mailParams,
            templateType:'HOTELTEMPLATE',
            userMailParams:userMailParams,
            userMailTemplateType:'USERTEMPLATE'
        });

        // const response = await SNSAPI.post('/sendNotification',{
        //     notificationType:'EMAIL',
        //     userMailParams:userMailParams,
        //     userMailTemplateType:'USERTEMPLATE'
        // });
    }

    const getHotelAddressText = () => {
        if(hotelDetails.address?.trim().endsWith(','))
            return `${hotelDetails.address} ${hotelDetails.city}, ${hotelDetails.state} - ${hotelDetails.pincode}`
        else
            return `${hotelDetails.address}, ${hotelDetails.city}, ${hotelDetails.state} - ${hotelDetails.pincode}`
    }

    useEffect(() => {
        if(Object.keys(cart).length == 0) {
            history.push('/');
        }
        return () => {
            
        }
    },[])

    return (
        <MobileCheckoutStyle.CheckoutParent>
            <MobileCheckoutStyle.CheckoutImage src = { getImageUrl() }></MobileCheckoutStyle.CheckoutImage>
            <MobileCheckoutStyle.BackiconParent>
                <MobileCheckoutStyle.BackIcon onClick={ backClickHandler }></MobileCheckoutStyle.BackIcon>
            </MobileCheckoutStyle.BackiconParent>
            <MobileCheckoutStyle.HotelInfoParent>
                <MobileCheckoutStyle.HotelInfo>
                    <MobileCheckoutStyle.HotelName>{ hotelDetails.name } </MobileCheckoutStyle.HotelName>
                    <MobileCheckoutStyle.HotelLocation>
                        <MobileCheckoutStyle.HotelLocationIcon></MobileCheckoutStyle.HotelLocationIcon>
                        <MobileCheckoutStyle.HotelLocationLabel> { getHotelAddressText() }</MobileCheckoutStyle.HotelLocationLabel>
                    </MobileCheckoutStyle.HotelLocation>
                    <MobileCheckoutStyle.HotelNumber>
                        <MobileCheckoutStyle.HotelNumberIcon></MobileCheckoutStyle.HotelNumberIcon>
                        <MobileCheckoutStyle.HotelNumberLabel onClick = {() => window.open(`tel:+91${getContactNumber()}`,'_blank')}> { getContactNumber() } </MobileCheckoutStyle.HotelNumberLabel>
                    </MobileCheckoutStyle.HotelNumber>
                </MobileCheckoutStyle.HotelInfo>
            </MobileCheckoutStyle.HotelInfoParent>
            <MobileCheckoutStyle.CheckoutDurationParent>
                <MobileCheckoutDuration showAddress={false}/>
            </MobileCheckoutStyle.CheckoutDurationParent>
            {/* <Box sx = {{ height:'3px', background:'#A6BCDA',margin:'0 1rem' }}></Box> */}
            <MobileRoomCards />
            <MobileGuestDetails errObj = { guestDetailsErr }/>
            <MobileCheckoutStyle.ReservationParent>
                {/* <MobileCheckoutStyle.MakeReservationPayLater variant = 'outlined' onClick = { validateandMakeReservation }>
                    Pay at Hotel
                </MobileCheckoutStyle.MakeReservationPayLater> */}
                <MobileCheckoutStyle.MakeReservation variant = 'contained' onClick = { validateandMakeReservation }>
                    Pay at Hotel
                </MobileCheckoutStyle.MakeReservation>
            </MobileCheckoutStyle.ReservationParent>
            { openDialog && <MobileBookingConfirmation totalAmount= { getTotalAmountTobePaid() } /> }
        </MobileCheckoutStyle.CheckoutParent>
    )
}

export default MobileCheckout;