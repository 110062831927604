import { configureStore, combineReducers } from "@reduxjs/toolkit";

import bookingDetails from "./bookingDetails";

const reducer = combineReducers({
    bookingDetails
});

export default configureStore({
    reducer
});