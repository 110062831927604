import styled from "styled-components";
import Box from '@mui/material/Box';
import { Typography, Button } from "@mui/material";

export const RoomsHeaderParent = styled(Box)`
&& {
    background: white;
    padding: 1rem 1rem 0 1rem; 

}`

export const RoomsHeaderText = styled(Typography)`
&& {
    color: #141414;
    font-family: Red Hat Display;
    font-size: 1.12rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}`

export const RoomsSubHeaderText = styled(Typography)`
&& {
    color: #141414;
    font-family: Red Hat Display;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding:0.5rem 0;
}`

export const MobileRoomParent = styled(Box)`
&& {
    display:block;
    @media (max-width: 576px) {
        display:block;
    }
}`

export const HotelRoomParent = styled(Box)`
&& {
    color:var(--fontColor);
    width:100%;
    box-sizing: border-box;
    padding-bottom:${props => (props.showPadding ? '80px' : 0)};
    margin-top:3px;
    background:white;
}`

export const HotelRoom = styled(Box)`
&& {
    display:flex;
    background-color:white;
    margin-bottom:0.5rem;
    flex-direction:column;
    border-radius: 4px;
    border: 0.5px solid #A6BCDA;
    margin:1rem 1rem 1rem 1rem;
    background: #FFF;
    &:last-child {
        margin-top: 0px !important;
    }
}`

export const RoomType = styled(Box)`
&& {
    width:100%;
    padding:1rem;
}`

    // height:12rem;
export const RoomTypeImageBox = styled(Box)`
&& {
    height:300px;
    width:100%;
    border-radius:0.75rem;
    & .MuiButtonBase-root {
        background-color:white;
    };
    & .MuiSvgIcon-root {
        color:black;
    };
    @media(max-width:576px) {
        height: 230px;
    };
}`

    // height: 12rem;
export const RoomTypeImage = styled.img`
&& {
    width: 100%;
    height:300px;
    object-fit: cover;
    border-radius: 4px;
    @media(max-width:576px) {
        height: 230px;
    };
}`

export const RoomTypeName = styled(Box)`
&& {
    margin-top: 1rem;
    text-transform: capitalize;
    font-size: 1rem;
    color: #141414;
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 700;
}`

export const OccupancyLabelHeader = styled(Typography)  `
&& {
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    font-size: 1rem;
    color: var(--fontColor);
    font-weight: 700;
}`

export const OccupancyImgParent = styled(Box)`
&& {
    width: 100%;
    display: flex;
    background: #FFFFFF;
    justify-content: space-between;
    padding: 0.5rem 0;
    align-items:center;
    height:75px;
}`

export const OccupancyParent = styled(Box)`
&& {
    width:60%;
}`

export const CounterParent = styled(Box)`
&& {
    width:40%;
    display:flex;
    justify-content:flex-end;
}`

export const OccupancyBody = styled(Box)`
&& {
    display:flex;
    justify-content:space-between;
    flex-direction:column;
}`

export const OccupancyLabelParent = styled(Box)`
&& {
    display:flex;

}`

export const SingleOccupancyImg = styled(Box)`
&& {
    background-image:url(styles/assets/single.svg);
    background-repeat:no-repeat;
    width: 35px;
    height:25px;
    background-position-x: 0;
    background-position-y: 50%;
    background-size: 40%;
}`

export const OccupancyPrice = styled(Box)`
&& {
    display:flex;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 16px;
    color: #141414;
    & p {
        font-size:0.85rem;
        font-weight:700;
    }
}`

export const DoubleOccupancyImg = styled(Box)`
&& {
    background-image:url(styles/assets/double.svg);
    background-repeat:no-repeat;
    width: 35px;
    height:25px;
    background-position-x: 0;
    background-position-y: 50%;
    background-size: 55%;
}`

export const TripleOccupancyImg = styled(Box)`
&& {
    background-image:url(styles/assets/triple.svg);
    background-repeat:no-repeat;
    width: 35px;
    height:25px;
    background-position-x: 0;
    background-position-y: 50%;
    background-size: 55%;
}`


// border-bottom:1px solid #d4d1d1;
export const RoomOccupancy = styled(Box)`
&& {
    margin:0 1rem 1rem 1rem;
    padding-top:0px;
    display:flex;
    flex-direction:column;
}`

export const MealSelection = styled(Box)`
&& {
    margin:0 1rem;

    display:flex;
    flex-direction:column;
    gap:1rem;
}`

export const MealSelectionHeader = styled(Typography) `
&& {
   color: var(--black-main, #141414);
    font-family: Red Hat Display;
    font-size: 0.9rem;
    font-weight: 700;
}`

export const MealSelectionBody = styled(Box)`
&& {
    display:flex;
    justify-content:space-between;
    flex-direction: column;
    gap: 1rem;
    padding:1rem 0;
    border-top:1px solid #d4d1d1;
}`

export const MealSelectionParent = styled(Box)`
&& {
    width: 100%;
    display: flex;
    background: #FFFFFF;
    align-items:center;
    height: 65px;
    position:relative;
    height: 45px;
    border-radius: 8px;
    border: 0.5px solid var(--light-blue, #A6BCDA);
    padding:0 0.75rem;
    background: #FFF;
    gap:5px;
    cursor:pointer;
}`

export const BreakfastImage = styled(Box)`
&& {
    background-image:url(styles/assets/lunch.svg);
    background-repeat:no-repeat;
    width: 25px;
    height:25px;
    background-position-x: center;
}`

export const LunchImage = styled(Box)`
&& {
    background-image:url(styles/assets/lunch.svg);
    background-repeat:no-repeat;
    width: 25px;
    height:25px;
    background-position-x: center;
}`

export const DinnerImage = styled(Box)`
&& {
    background-image:url(styles/assets/lunch.svg);
    background-repeat:no-repeat;
    width: 25px;
    height:25px;
    background-position-x: center;
}`

export const MealLabel = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 16px;
    color: #141414;
}`

export const MealPrice = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 16px;  
    color: #141414;
    font-size: 0.9rem;
    font-weight: 600;
    display:flex;
}`

export const SelectedMealCheckBox = styled(Box)`
&& {
    position:absolute;
    background-image:url(styles/assets/checked.svg);
    background-position:center;
    background-repeat:no-repeat;
    right: 0px;
    height: 45px;
    width: 45px;
}`

export const MealCheckBox = styled(Box)`
&& {
    position:absolute;
    background-image:url(styles/assets/unchecked.svg);
    background-position:center;
    background-repeat:no-repeat;
    right: 0px;
    height: 45px;
    width: 45px;
}`

export const RoomSelection = styled(Box)`
&& {
    padding:1rem;
    display:flex;
    justify-content:space-between;
    align-items:center;
}`

export const RoomSelectionLable = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 0.85rem;
    line-height: 19px;
    color: #141414;
}`

export const CartButtons = styled(Box)`
&& {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
}`

export const AddtoCart = styled(Button)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 18px;
    color: #141414;
    background: #FFFFFF;
    border: 0.5px solid #141414;
    border-radius: 5px;
    height: 40px;
    padding: 0 2rem;
    text-transform:capitalize;
}`

export const BookNow = styled(Button)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 18px;
    color: #FFFFFF;
    height: 40px;
    padding: 0 2rem;
    background: linear-gradient(180deg, #F31771 0%, #D0105F 100%);
    border-radius: 5px;
    text-transform:capitalize;
}`

/* Hourly Stay Css start */
export const HourlyStaySlot = styled(Box)`
&& {
    cursor:pointer;
    display: flex;
    width: 110px;
    height: 38px;
    font-weight:400;
    font-size:0.75rem
    padding: 11px 8px 11px 10px;
    justify-content: center;
    align-items: center;
    font-style:'Red Hat Display';
    border-radius: 4px;
    border: 0.5px solid var(--black-main, #141414);
    flex-shrink: 0;
    background-color:${props => {
        if(props.selected) {
            return '#141414';
        }
        else
            return 'white';
    }};
    color:${props => {
        if(props.selected) {
            return 'white';
        }
        else
            return '#141414';
    }};
}`

export const SlotParent = styled(Box)`
&& {
    display:flex;
    gap:1rem;
    padding-top:1rem;
}`
/* Hourly stay css end */