import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//Internal Imports
import AddGuest from "./AddGuest";
import { setGuestDetails } from '../../../slices/bookingDetails';

//Style Imports
import * as CheckOutStyle from '../../../styles/Booking/checkoutStyle';

const CheckoutGuestDetail = () => {

    const dispatch = useDispatch()

    const [addGuest, setAddGuest] = useState(false);

    const  guestDetails = useSelector(state => {    
        return [...state.bookingDetails.selectionCriteria.guestDetails]
    });

    const showAddGuest = () => {
        setAddGuest(true);
    }

    const addPrimaryGuest = (event) => {
        let primaryGuest = {};
        let guestFound = false;
        let allGuest = [...guestDetails];
        if(guestDetails.find(guest => guest.Id == 1) != null) {
            guestFound = true;
            primaryGuest = {...guestDetails.find(guest => guest.Id == 1)};
        }
        else {
            primaryGuest["Id"] = 1;
        }
        primaryGuest[event.target.name] = event.target.value;
        if(!guestFound) 
            allGuest.push(primaryGuest);
        else {
            allGuest = [...allGuest.filter(guest => guest.Id != 1),primaryGuest];
        }
        dispatch(setGuestDetails(allGuest));
    }

    const getGuestDetail = (name) => {
        let primaryGuest = null
        if(guestDetails.find(guest => guest.Id == 1) != null)
            primaryGuest = guestDetails.find(guest => guest.Id == 1);
        return (primaryGuest) ? primaryGuest[name] : '';
    }

    return (
        <CheckOutStyle.GuestDetailParent>
            <CheckOutStyle.HotelInfoHeader>
                Guest Details
            </CheckOutStyle.HotelInfoHeader>
            <CheckOutStyle.HotelInfoBody>
                <CheckOutStyle.GDRow>
                    <CheckOutStyle.GDColumn>
                        <CheckOutStyle.GDColumnLabel> Guest Full Name </CheckOutStyle.GDColumnLabel>
                        <CheckOutStyle.GDColumnInput required = "true" onBlur = { addPrimaryGuest } name = 'fullname' size="small" placeholder="Please enter full name"> { getGuestDetail('fullname') } </CheckOutStyle.GDColumnInput>
                    </CheckOutStyle.GDColumn>
                    <CheckOutStyle.GDColumn>
                        <CheckOutStyle.GDColumnLabel> Email Address </CheckOutStyle.GDColumnLabel>
                        <CheckOutStyle.GDColumnInput onBlur = { addPrimaryGuest } name = 'email' size="small" placeholder="Please enter email address"> { getGuestDetail('email') } </CheckOutStyle.GDColumnInput>
                    </CheckOutStyle.GDColumn>
                    <CheckOutStyle.GDColumn>
                        <CheckOutStyle.GDColumnLabel> Mobile Number </CheckOutStyle.GDColumnLabel>
                        <CheckOutStyle.GDColumnInput onBlur = { addPrimaryGuest } name = 'mobile' size="small" placeholder="Enter Mobile Number"> { getGuestDetail('mobile') } </CheckOutStyle.GDColumnInput>
                    </CheckOutStyle.GDColumn>
                </CheckOutStyle.GDRow>
                <CheckOutStyle.GSTRow>
                    <CheckOutStyle.GSTCheckBox></CheckOutStyle.GSTCheckBox>
                    <CheckOutStyle.GSTLabel>Enter GST Details (optional)</CheckOutStyle.GSTLabel>
                </CheckOutStyle.GSTRow>
                {/* <CheckOutStyle.NewGuestBtn variant = "text" onClick={ showAddGuest }>
                    + Add Guest
                </CheckOutStyle.NewGuestBtn> */}
            </CheckOutStyle.HotelInfoBody>
            { <AddGuest guestDetails = { guestDetails } addGuest = { addGuest } handleClose = { () => {setAddGuest(false)} }/> }
        </CheckOutStyle.GuestDetailParent>
    )
}

export default CheckoutGuestDetail;