import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
var numeral = require('numeral');
import { useHistory, useParams } from "react-router-dom";
import dayjs from 'dayjs';

//Material UI and Style Imports
import * as MobileCheckoutStyle from '../../../styles/BookingMobile/mobileCheckoutStyle';
import MobileCheckoutDuration from './MobileCheckoutDuration';

//Internal imports
import { clearCart } from '../../../slices/bookingDetails';
import MobileFooter from '../LandingPageDetails/LandingPageMobile/MobileFooter';

const MobileBookingConfirmation = ({  }) => {

    const { bookingId } = useParams();

    const dispatch = useDispatch();

    const history = useHistory();

    const selectionCriteria = useSelector(state => state.bookingDetails.selectionCriteria);

    const cart = useSelector(state => state.bookingDetails.cart);

    const getRGText = () => {
        let text = '';
        let roomCount = 0;
        let guestCount = 0;
        for(var o in cart) {
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                roomCount += roomsOcc.length;
                if(room == 'single')
                    guestCount += (roomsOcc.length);
                else if(room == 'double')
                    guestCount += (roomsOcc.length * 2);
                else if(room == 'triple')
                    guestCount += (roomsOcc.length * 3);
            }
        }

        // if(selectionCriteria.roomInfo.Rooms  > 1)
        //     text += (selectionCriteria.roomInfo.Rooms + ' Rooms, ')
        // else 
        //     text += (selectionCriteria.roomInfo.Rooms + ' Room, ')
        //let guestCount = selectionCriteria.roomInfo.Adults + selectionCriteria.roomInfo.Children + selectionCriteria.roomInfo.Infant;
        if(roomCount > 1)
            text += (roomCount + ' Rooms, ')
        else    
            text += (roomCount + ' Room, ')
        if(guestCount > 1)
            text += (guestCount + ' Guests')
        else    
            text += (guestCount + ' Guest')
        return text;
    }

    const getTotalAmountTobePaid = () => {
        let totalAmount = 0;
        for(var o in cart) {
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                roomsOcc.map(room => {
                    totalAmount += parseInt(room.price);
                    for(var mealType in room.mealInfo) {
                        if(mealType != 'Meal')
                            totalAmount += parseInt(room.mealInfo[mealType]);
                    }
                })
            }
        }
        let noOfNights = (dayjs((new Date(selectionCriteria.dateRange[1])))).diff(dayjs((new Date(selectionCriteria.dateRange[0]))),'day');
        return numeral(totalAmount * noOfNights).format('0,0');
    }

    useEffect(() => {

        if(Object.keys(cart).length == 0) {
            history.push('/');
        }

        document.body.classList.add('no-overscroll-x');

        const handleGesture = (event) => {
            if (event.deltaX > 0) {
              event.preventDefault();
            }
        };
        window.addEventListener('wheel', handleGesture, { passive: false });
        return () => {
          window.removeEventListener('wheel', handleGesture);
          document.body.classList.remove('no-overscroll-x');
        };
      }, []);

    return(
        <MobileCheckoutStyle.MobileConfirmationParent>
            <MobileCheckoutStyle.ConfHeader>
                <MobileCheckoutStyle.CongHeaderChild>
                    <MobileCheckoutStyle.ConfirmImg></MobileCheckoutStyle.ConfirmImg>
                    <p>
                        Congratulations<br/>
                        Your booking is confirmed.<br/>
                        Confirmation will be sent to your email.
                    </p>
                </MobileCheckoutStyle.CongHeaderChild>
            </MobileCheckoutStyle.ConfHeader>
            <MobileCheckoutDuration showAddress={true}/>
            <MobileCheckoutStyle.RoomsGuestParent>
                <MobileCheckoutStyle.RGHeader>Booking ID</MobileCheckoutStyle.RGHeader>
                <MobileCheckoutStyle.BIDText>{ bookingId }</MobileCheckoutStyle.BIDText>
            </MobileCheckoutStyle.RoomsGuestParent>
            <MobileCheckoutStyle.RoomsGuestParent>
                <MobileCheckoutStyle.RGHeader>Rooms & Guests</MobileCheckoutStyle.RGHeader>
                <MobileCheckoutStyle.RGText> { getRGText() }</MobileCheckoutStyle.RGText>
            </MobileCheckoutStyle.RoomsGuestParent>
            <MobileCheckoutStyle.PaymentInfoParent>
                <MobileCheckoutStyle.PaymentLabel>
                    Payment Details
                </MobileCheckoutStyle.PaymentLabel>
                <MobileCheckoutStyle.PaymentInfo>
                    <MobileCheckoutStyle.PriceMode>
                        Total Booking Amount
                    </MobileCheckoutStyle.PriceMode>
                    <MobileCheckoutStyle.Price>
                        <span>&#x20B9;</span>
                        { getTotalAmountTobePaid() }
                    </MobileCheckoutStyle.Price>
                </MobileCheckoutStyle.PaymentInfo>
                <MobileCheckoutStyle.PaymentInfo>
                    <MobileCheckoutStyle.PriceMode>
                        Amount Paid
                    </MobileCheckoutStyle.PriceMode>
                    <MobileCheckoutStyle.Price>
                        <span>&#x20B9;</span>
                        { 0 }
                    </MobileCheckoutStyle.Price>
                </MobileCheckoutStyle.PaymentInfo>
                <MobileCheckoutStyle.PaymentInfo>
                    <MobileCheckoutStyle.PriceMode>
                        Pay at hotel
                    </MobileCheckoutStyle.PriceMode>
                    <MobileCheckoutStyle.Price>
                        <span>&#x20B9;</span>
                        { getTotalAmountTobePaid() }
                    </MobileCheckoutStyle.Price>
                </MobileCheckoutStyle.PaymentInfo>
            </MobileCheckoutStyle.PaymentInfoParent>
            <MobileFooter/>
        </MobileCheckoutStyle.MobileConfirmationParent>
    )
}

export default MobileBookingConfirmation;