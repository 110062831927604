import React, { useState } from "react";
import { useSelector } from "react-redux";
import { differenceInCalendarDays,format } from 'date-fns'; 
import dayjs from 'dayjs';

import { useHistory } from "react-router-dom";
var numeral = require('numeral');

//Internal Imports
import * as PBStyle from '../../../styles/Booking/checkoutStyle';
import API from '../../../api';
import { SNSAPI } from "../../../api";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import BookingConfirmation from './BookingConfirmation';


const Alert = React.forwardRef<HTMLDivElement>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const PriceBreakout = () => {

    const history = useHistory();

    //Error object for Notification
    const [errorObj, setErrorObj] = useState({
        open:false,
        message:'',
        severity:'info'
    })

    //State for Booking Confirmation PopUp
    const [openDialog, setOpenDialog] = useState(false);

    //Dialog Close Handler 
    const handleClose = () => {
        setOpenDialog(false);
        history.push('/booking');
    }

    //State for Coupon Code
    const [couponCode, setCouponCode] = useState('');

    //Get Selection Criteria and hotelRoomTypeInfo from Redux
    const hotelRoomType = useSelector((state) => {
        return state.bookingDetails.selectionCriteria.roomTypeInfo;
    })

    const selectionCriteria = useSelector(state => state.bookingDetails.selectionCriteria);

    //Rooms added to cart
    const cart = useSelector(state => state.bookingDetails.cart);

    //Hotel Details 
    const hotelDetails = useSelector(state => state.bookingDetails.hotelDetails);
    const contactDetails = useSelector(state => state.bookingDetails.contactDetails);



    //Calculate Total Price for Booking
    const getTotalPrice = () => {
        let totalPrice = hotelRoomType.roomCategory.attributes.price + hotelRoomType.roomCategory.attributes.tax
        return `${hotelRoomType.roomCategory.attributes.currency_symbol} ${totalPrice}`
    }

    const pluralizeWord = (val,word) => {
        return val > 1 ? (`${val} ${word} s`) : (`${val} ${word}`);
    }

    //Get Total Number of Rooms booked
    const getRoomCount = () => {
        let TotalRooms = 0;
        for(var o in cart) {
            let totalAdults = 0;
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                TotalRooms += roomsOcc.length;
            }
        }
        return TotalRooms;
    }

    //Generate label text for Base Price
    const getBasePriceLabel = () => {
        const distance = differenceInCalendarDays(new Date(selectionCriteria.dateRange[1]),new Date(selectionCriteria.dateRange[0]));
        return pluralizeWord(getRoomCount(), 'Room') + " x " + pluralizeWord(distance,'Night');
        //return `${selectionCriteria.roomInfo.Rooms} Rooms X ${distance} Nights`;
    }

    //Validate the Coupon Code on Apply
    const validateCoupon = () => {
        API.post('/validateCoupon', {
            coupon_code:couponCode
        }).then((resp) => {
            if(resp.data.count == 0) {
                setErrorObj((prevObj) => ({
                    ...errorObj,
                    open:true,
                    message:'Coupon code is not Valid',
                    severity:'info'
                }));
            }
        }).catch((err) => {
            setErrorObj((prevObj) => ({
                ...errorObj,
                open:true,
                message:'Something went wrong.Please try again after some time.',
                severity:'error'
            }));
        });
    }

    //Validate the Mandatory Info before Reservation
    const validateInfo = () => {
        if(selectionCriteria.guestDetails.length == 0) {
            setErrorObj((prevObj) => ({
                ...errorObj,
                open:true,
                message:'Please enter guest details',
                severity:'info'
            }));
            return false;
        }
        return true;
    }

    //Get the total Amount to be Paid for the booking.
    const getTotalAmountTobePaid = () => {
        let totalAmount = 0;
        for(var o in cart) {
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                roomsOcc.map(room => {
                    totalAmount += parseInt(room.price);
                    for(var mealType in room.mealInfo) {
                        totalAmount += parseInt(room.mealInfo[mealType]);
                    }
                })
            }
        }
        return numeral(totalAmount).format('0,0');
    }

    //Get User Room Bookings
    const getUserRoomBookings = (bookingId) => {
        let userRoomBookings = [];
        for(var o in cart) {
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                roomsOcc.map(room => {
                    userRoomBookings.push({
                        user_booking_id:bookingId,
                        hotel_room_type_id:room.roomTypeId,
                        hotel_room_type_category_id:1,//selectionCriteria.roomTypeInfo.roomCategory.hotel_room_type_category_id,
                        hotel_room_id:null,
                        // check_in_date:format(selectionCriteria.dateRange[0],'yyyy-MM-dd'),
                        // check_out_date:format(selectionCriteria.dateRange[1],'yyyy-MM-dd'),
                        check_in_date:dayjs(selectionCriteria.dateRange[0],'yyyy-MM-dd').toISOString(),
                        check_out_date:dayjs(selectionCriteria.dateRange[1],'yyyy-MM-dd').toISOString(),
                        amount_per_night:0
                    });
                })
            }
        }
        return userRoomBookings;
    }

    const getBookingRoomInfo = () => {
        let roomCount = 0;
        let currentRoomCount = 0;
        let occupancyMap = {
            'single':0,
            'double':0,
            'triple':0
        };

        let roomCatText = ''

        for(var o in cart) {
            currentRoomCount = 0;
            let roomType = cart[o];
            for(var room in roomType) {
                let roomsOcc = roomType[room];
                roomCount += roomsOcc.length;
                currentRoomCount += roomsOcc.length;
                occupancyMap[room] = occupancyMap[room] + roomsOcc.length;
            }
            roomCatText += `${currentRoomCount} ${o} ,`;
        }

        let occupancyText = '';
        if(occupancyMap['single'])
            occupancyText += occupancyMap['single'] + ' Single,'
        if(occupancyMap['double'])
            occupancyText += occupancyMap['double'] + ' Double,'
        if(occupancyMap['triple'])
            occupancyText += occupancyMap['triple'] + ' Triple,'

        return [
            roomCount,
            occupancyText.substring(0,occupancyText.length - 1),
            roomCatText.substring(0,roomCatText.length - 1)
        ]
    }

    const sendMail = async(params) => {

        let bookingInfo = getBookingRoomInfo()
        let mailParams = {
            subject:`Booking Voucher | ${hotelDetails.name}, ${hotelDetails.city} | ${dayjs(selectionCriteria.dateRange[0]).format('MMM DD YYYY')} - ${dayjs(selectionCriteria.dateRange[1]).format('MMM DD YYYY')}`,
            heading:`Date: ${dayjs(selectionCriteria.dateRange[0]).format('MMM DD')} - ${dayjs(selectionCriteria.dateRange[1]).format('MMM DD')}, ${(new Date()).getFullYear()} | Booking ID: ${params.bookingId} | Room Count: ${bookingInfo[0]} | Pay at Hotel`,
            guestName:params.guestName,
            checkIn:`${dayjs(selectionCriteria.dateRange[0]).format('MMM DD YYYY')}`,
            checkInTime:`From 12:00 PM`,
            checkOut:`${dayjs(selectionCriteria.dateRange[1]).format('MMM DD YYYY')}`,
            checkInTime:`From 11:00 AM`,
            bookingId:params.bookingId,
            hotelName:hotelDetails.name,
            hotelAddress:hotelDetails.address,
            hotelContactNo:contactDetails.find(ct => ct.contact_type == 'Phone').contact_value,
            occupancy:bookingInfo[1],
            roomCat:bookingInfo[2],
            amount:params.amount,
            plan:'',
            payReceived:'0',
            mobileNumber:params.mobile,
            email:params.email,
            cancellationDate:dayjs(selectionCriteria.dateRange[0]).subtract(1,'day').format('DD MMM h:mm A'),
            advancePaid:0,
            balanceAmount:amount - 0
        }

        const respMail = await SNSAPI.post('/sendNotification',{
            notificationType:'EMAIL',
            mailParams,
            templateType:'HOTELTEMPLATE'
        });

        let startDate = dayjs(selectionCriteria.dateRange[0]).format('DD MMM YY');
        mailParams.subject = `New reservation received | Guest Name: ${params.guestName} | Booking ID: ${params.bookingId}`;
        mailParams.heading = `Date: ${dayjs(selectionCriteria.dateRange[0]).format('MMM DD')} - ${dayjs(selectionCriteria.dateRange[1]).format('MMM DD')}, ${(new Date()).getFullYear()} | Booking ID: ${params.bookingId} | Amount: INR ${params.amount}`;
        mailParams.greeting = `${params.guestName}, Thank you for booking with us!`

        const response = await SNSAPI.post('/sendNotification',{
            notificationType:'EMAIL',
            mailParams,
            templateType:'USERTEMPLATE'
        });
    }

    //Make Reservation button Handler.
    const makeReservation = async (event) => {
        try {
            if(validateInfo()) {
                let totalAmount = getTotalAmountTobePaid();
                let userId = -1;
                //Check IF Primary Guest Exists in Database.
                let userInfo = await API.get(`/getUser?mobile_number=${selectionCriteria.guestDetails[0].mobile}`);

                //Craete a user for Primary Guest If does not exist in Data base.
                if(userInfo.data.count == 0) {
                    userInfo = await API.post('/createUser',{
                        name:selectionCriteria.guestDetails[0].fullname,
                        email_id:selectionCriteria.guestDetails[0].email,
                        mobile_number:selectionCriteria.guestDetails[0].mobile,
                        image_location:'',
                        attributes:'{}'
                    });
                    userId = userInfo.data.data.insertId;
                }
                else 
                    userId = userInfo.data.data[0].user_id;

                //Create Reservation Info.
                console.log(dayjs(selectionCriteria.dateRange[0],'yyyy-MM-dd').toISOString());
                let amount = parseInt()
                //console.log(format(selectionCriteria.dateRange[0],'yyyy-MM-dd'));
                let reservation = {
                    user_id:userId,
                    hotel_id:1,
                    check_in_date:dayjs(selectionCriteria.dateRange[0],'yyyy-MM-dd').toISOString(),
                    check_out_date:dayjs(selectionCriteria.dateRange[1],'yyyy-MM-dd').toISOString(),
                    total_amount:totalAmount,
                    coupon_code:'',
                    coupon_type:'',
                    coupon_value:0,
                    total_amount_after_discounts:totalAmount,
                    paying_now:0,
                    paying_at_hotel:totalAmount,
                    status:'PENDING',
                    booking_constraints:'{}'
                };
                
                const bookingConfirmation = await API.post('/makeReservation',reservation);
                let bookingId = bookingConfirmation.data.data.insertId;

                //Add all the guests to the current booking.
                const guestDetails = selectionCriteria.guestDetails.map((guest) => {
                    return {
                        user_booking_id:bookingId,
                        guest_name:guest.fullname,
                        guest_email_id:guest.email,
                        guest_mobile_number:guest.mobile,
                        guest_gst_number:''
                    }
                }); 

                const guestsAdded = await API.post('/addGuestsToReservation',guestDetails);

                //Add the Room Information to the current booking.
                const addRoomToBooking = await API.post('/addRoomsToReservation', getUserRoomBookings(bookingId));

                //Send Email
                sendMail({
                    amount:totalAmount,
                    bookingId,
                    guestName:guestDetails[0].guest_name,
                    mobile:guestDetails[0].guest_mobile_number,
                    email:guestDetails[0].guest_email_id,
                })

                setOpenDialog(true);
            }
        }
        catch(ex) {
            setErrorObj((prevObj) => ({
                ...errorObj,
                open:true,
                message:'Something went wrong.Please try again after some time.',
                severity:'error'
            }));
        }
    }

    return (
        <>
            <PBStyle.PBParent>
                <PBStyle.PBHeader>
                    Price Break-Up
                </PBStyle.PBHeader>
                <PBStyle.PBPrice>
                    <PBStyle.PBPriceLabelParent>
                        <PBStyle.PBPriceBoldLabel> {getBasePriceLabel()} </PBStyle.PBPriceBoldLabel>
                        <PBStyle.PBTaxLabel>Base Price</PBStyle.PBTaxLabel>
                    </PBStyle.PBPriceLabelParent>
                    <PBStyle.PBTaxAmount>
                        <span>&#x20B9;</span>
                        { getTotalAmountTobePaid() }
                        {/* { `${hotelRoomType.roomCategory.attributes.currency_symbol} ${hotelRoomType.roomCategory.attributes.price}`} */}
                    </PBStyle.PBTaxAmount>
                </PBStyle.PBPrice>
                {/* <PBStyle.PBTax>
                    <PBStyle.PBTaxLabel>Tax and Service Fees</PBStyle.PBTaxLabel>
                    <PBStyle.PBTaxAmount>{ `${hotelRoomType.roomCategory.attributes.currency_symbol} ${hotelRoomType.roomCategory.attributes.tax}`}</PBStyle.PBTaxAmount>
                </PBStyle.PBTax> */}
                <PBStyle.PBAmount>
                    <PBStyle.PBAmountLabel>Total Amount to be Paid</PBStyle.PBAmountLabel>
                    <PBStyle.PBAmountValue>
                        <span>&#x20B9;</span>
                        { getTotalAmountTobePaid() }
                    </PBStyle.PBAmountValue>
                    {/* <PBStyle.PBAmountValue>{ getTotalPrice() }</PBStyle.PBAmountValue> */}
                </PBStyle.PBAmount>
            </PBStyle.PBParent>
            <PBStyle.CouponParent>
                <PBStyle.CouponHeader>
                    Apply Coupon
                </PBStyle.CouponHeader>
                <PBStyle.CouponLabel>
                    Enter your coupon code
                </PBStyle.CouponLabel>
                <PBStyle.CouponInputParent>
                    <PBStyle.CouponInput size='small' placeholder="Enter coupon code" onBlur={ (event) => setCouponCode(event.target.value) }>
                    </PBStyle.CouponInput>
                    <PBStyle.ApplyCoupon onClick = { validateCoupon }>Apply</PBStyle.ApplyCoupon>
                </PBStyle.CouponInputParent>
            </PBStyle.CouponParent>
            <PBStyle.PaymentParent>
                <PBStyle.PaymentHeader>
                    Payment Options
                </PBStyle.PaymentHeader>
                <PBStyle.PaymentBody>
                    <PBStyle.PaymentRow>
                        Pay at Hotel
                    </PBStyle.PaymentRow>
                </PBStyle.PaymentBody>
            </PBStyle.PaymentParent>
            <PBStyle.MakeReservationParent>
                <PBStyle.MakeReservation variant="contained" onClick = { makeReservation }>
                    Make Reservation
                </PBStyle.MakeReservation>
            </PBStyle.MakeReservationParent>
            <BookingConfirmation open = {openDialog} handleClose = { handleClose }/>
            <Snackbar open={errorObj.open} autoHideDuration={3000} severity = { errorObj.severity } message = {errorObj.message}>
            </Snackbar>
        </>
    )
}

export default PriceBreakout;