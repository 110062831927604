import React from 'react';
import { useSelector } from 'react-redux';

//Material UI and Style Imports.
import RoomCategoryLandingPage from './RoomCategory/RoomCategoryLandingPage';
import RoomCategoryMobileLandingPage from './RoomCategoryMobile/RoomCategoryMobileLandingPage';
import HourlyRoomCategoryMobileLandingPage from './RoomCategoryHourlyMobile/HourlyRoomCategoryMobileLandingPage';
import { OVERNIGHT_STAY } from '../../../config/constants';


const RoomDetailsParent = React.forwardRef((props,ref) => {

    const STAY_TYPE = useSelector(state => state.bookingDetails.STAY_TYPE);

    return (
        <div ref = { ref }>
            { STAY_TYPE == OVERNIGHT_STAY ? <RoomCategoryMobileLandingPage ref = { ref }/> : <HourlyRoomCategoryMobileLandingPage ref = { ref }/>}
        </div>
    )
});

export default RoomDetailsParent;