import styled from "styled-components";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import AutoComplete from '@mui/material/Autocomplete'

export const DesktopRoomParent = styled(Box)`
&& {
    display:block;
    @media (max-width: 576px) {
        display:none;
    }
}`



export const HotelRoomParent = styled(Box)`
&& {
    margin:2rem 0rem 3rem 2rem;
    background-color:#EFF3FA;
    border-radius: 10px 0px 0px 0px;
    color:var(--fontColor);
    box-sizing: border-box;
    border-bottom:1px solid #CFE2FF;    
    width:65%;
}`

export const RoomTypeHeader = styled(Box)`
&& {
    height:52px;
    display:flex;
    align-items:center;
    background: #091222;
    border-radius: 10px 0px 0px 0px;
    color:white;
    position:sticky;
    top:0px;
}`

export const RoomTypeNameHeader = styled(Box)`
&& {
    width:40%;
    padding-left:1rem;
    height:52px;
    display:flex;
    align-items:center;
}`

export const RoomOptionsHeader = styled(Box)`
&& {
    width:10%;
    height:52px;
    padding-left:1rem;
    display:flex;
    align-items:center;
}`

export const RoomPriceHeader = styled(Box)`
&& {
    width:20%;
    height:52px;
    padding-left:1rem;
    display:flex;
    align-items:center;
    justify-content:center;
}`

export const RoomCountHeader = styled(Box)`
&& {
    width:30%;
    height:52px;
    padding-left:1rem;
    display:flex;
    align-items:center;
}`

export const HotelRoom = styled(Box)`
&& {
    display:flex;
    background-color:white;
    border-left:1px solid #CFE2FF;
    margin-bottom:0.5rem;
    &:last-child {
        margin-top: 0px !important;
    }
}`

export const RoomType = styled(Box)`
&& {
    width:40%;
    padding:1rem;
}`

export const RoomOccupancy = styled(Box)`
&& {
    width:10%;
    padding:1rem 0.5rem;
    border-left:1px solid #EFF3FA;
}`

export const RoomOptions = styled(Box)`
&& {
    width:20%;
    border-left:1px solid #EFF3FA;
    padding:1rem;
    justify-content:center;
    display: flex;
    flex-direction: column;
    align-items: center;
}`

export const RoomPrices = styled(Box)`
&& {
    width:30%;
    border-left:1px solid #EFF3FA;
    padding:1rem;
}`

export const RoomTypeImage = styled.img`
&& {
    width: 100%;
    height: 12rem;
    object-fit: cover;
    border-radius: 0.75rem;
}`

export const RoomTypeName = styled(Box)`
&& {
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    font-size: 1.2rem;
    color: var(--fontColor);
    font-weight: 700;
}`

export const RoomOption = styled(Box)`
&& {
    height:7rem;
    border-bottom:1px solid #CFE2FF;
    padding:1rem 0 0 1rem;
    font-size:1.1rem;
    color:var(--fontColor);
    &:last-child {
        border-bottom: 0px !important;
    }
}`

export const RoomPrice = styled(Box)`
&& {
    height: 33%;
    color: #052C65;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    &:last-child {
        border-bottom: 0px !important;
    }
}`


export const PriceDetails = styled(Box)`
&& {
    display:flex;
    flex-direction:row;
    align-items:center;
    font-family: 'Red Hat Display';
}`

export const PDHeader = styled(Typography)`
&& {
    font-size:0.8rem;
    font-weight:400;
}`

export const Price = styled(Typography)`
&& {
    font-size:1rem;
    font-weight:500;
    font-family: 'Red Hat Display';
}`

export const Taxes = styled(Typography)`
&& {
    font-size: 0.8rem;
    font-weight: 400;
    padding-left: 0.4rem;
    font-family: 'Red Hat Display';
}`

export const ReserveRoom = styled(Box)`
&& {
    display:flex;
    height:40px;
    align-self:center;
}`

export const ReserveNow = styled(Box)`
&& {
    background-color:#0A58CA;
    border-radius:5px;
    color:white;
    font-size:0.8rem;
    font-weight:700;
    display:flex;
    align-items:center;
    padding:0 1rem;
    cursor:pointer;
}`

export const RoomCatMainImage = styled(Box)`
&& {
    background-color:#091222;
    width:100%;
    height:16rem;
    display:flex;
    justify-content: center;
    align-items: center;
    @media(max-width:576px) {
        height:200px;
    }
}`

export const RoomCategoryParent =  styled(Box)`
&& {
    display:flex;
}`

export const OccupancyImgParent = styled(Box)`
&& {
    height:33%;
    display: flex;
    align-items: center;
}`

export const SingleOccupancyImg = styled(Box)`
&& {
    background-image:url(styles/assets/single.svg);
    background-repeat:no-repeat;
    height: 25px;
    width: 100%;
}`

export const DoubleOccupancyImg = styled(Box)`
&& {
    background-image:url(styles/assets/double.svg);
    background-repeat:no-repeat;
    height: 25px;
    width: 100%;
}`

export const TripleOccupancyImg = styled(Box)`
&& {
    background-image:url(styles/assets/triple.svg);
    background-repeat:no-repeat;
    height: 25px;
    width: 100%;
}`

export const RoomCountSelect = styled(AutoComplete)`
&& {
    width:4rem;
}`