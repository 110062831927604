import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

export const CounterParent = styled(Box)`
&& {
    height:35px;
    width:85px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    border-radius: 4px;
    border: 0.5px solid rgba(20, 20, 20, 0.30);
    background: #FFF;
    & span {
        font-size:0.85rem;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}`

export const  CounterButton = styled(Box)`
&& {
    color: #717171;
    height:100%;
    width:28px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    font-size:1.5rem;
    & svg {
        font-size:1rem;
    }
}`