import styled from "styled-components";
import Box from '@mui/material/Box';
import { Typography,Button,TextField } from "@mui/material";
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';

export const CheckoutParent = styled(Box)`
&& {
    width:600px;
    display:block;
    background-color:white;
    flex-grow:1;
    margin:0 auto;
    position:relative;
    @media (max-width: 576px) {
        display:block;
        width:100vw;
        margin:unset;
    }
}`

export const CheckoutImage = styled.img`
&& {
    height:180px;
    width:600px;
    @media (max-width: 576px) {
        display:block;
        width:100vw;
    }
}`

export const HotelInfo = styled(Box)`
&& {
    padding: 1rem 1rem 0.5rem 1rem;
    background-color:white;
}`

export const HotelInfoParent = styled(Box)`
&& {
    background-color:#ECECEC;
    padding-bottom:0.25rem;
}`

export const CheckoutDurationParent = styled(Box)`
&& {
    background-color:#ECECEC;
    padding-bottom:0.25rem;
}`

export const HotelName = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 21px;
    color: #141414;
}`

export const HotelLocation = styled(Box)`
&& {
    display:flex;
}`

export const HotelLocationIcon = styled(Box)`
&& {
    background-image: url(styles/assets/location.svg);
    background-repeat: no-repeat;
    background-position-x: 0%;
    background-position-y: 50%;
    min-width: 30px;
    height:30px;
}`

export const HotelLocationLabel = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 19px;
    color: #141414;
    display: flex;
    align-items: center;
}`

export const HotelNumber = styled(Box)`
&& {
    display:flex;
}`

export const HotelNumberIcon = styled(Box)`
&& {
    background-image:url(styles/assets/phone.svg);
    background-repeat: no-repeat;
    background-position-x: 0%;
    background-position-y: 50%;
    width: 30px;
    height:30px;
}`

export const HotelNumberLabel = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 19px;
    color: #141414;
    display: flex;
    align-items: center;
}`

export const BackIcon = styled(ArrowBackIosSharpIcon)`
&& {
    font-size:1.25rem;
}`

export const BackiconParent = styled(Box)`
&& {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 12px;
    left: 12px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}`

export const CheckoutInfoParent = styled(Box)`
&& {
    margin-top:2px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items:center;
    background:white;
}`

export const DummyParentImg = styled(Box)`
&& {
    background:#008009;
}`

export const AddressImgParent = styled(Box)`
&& {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items:center;
    border-radius: 20px 20px 0px 0px;
    background: #FFF;
}`

export const AddressParent = styled(Box)`
&& {
    width:calc(100% - 90px);
}`

export const ConfirmHotelImg = styled.img`
&& {
    height:90px;
    width:90px;
}`

export const CheckOutInfo = styled(Box)`
&& {

}`

export const CheckInfInfo = styled(Box)`
&& {

}`

export const DurationInfo = styled(Box)`
&& {
    background: #F0F6FF;
    border: 0.5px dashed #4D4C4C;
    border-radius: 4px; 
    height:30px;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 16px;
    color: #141414;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}`

export const CheckinTime = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 19px;
    color: #4D4C4C;
    & span {
        font-weight:bold;
    }
}`

export const CheckinDate = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 0.85rem;
    line-height: 19px;
    color: #141414;
}`

export const CheckinDay = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 19px;
    color: #4D4C4C;
}`

export const ReservationParent = styled(Box)`
&& {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    gap:1rem;
}`

export const MakeReservation = styled(Button)`
&& {
    border-radius: 5px;
    height:2.5rem;
    text-transform:capitalize;
    color:white;
    border-radius: 5px;
    width:12rem;
    background: var(--brand-color, linear-gradient(180deg, #F31771 0%, #D0105F 100%));
    /* Drop Shadow */
    box-shadow: 1px 3px 8px 0px rgba(50, 50, 71, 0.17); 
}`

export const MakeReservationPayLater = styled(Button)`
&& {
    border: 0.5px solid #272727;
    box-shadow: 1px 3px 8px rgba(50, 50, 71, 0.17);
    border-radius: 5px;
    color: var(--black-main, #141414);
    height:2.5rem;
    text-transform:capitalize;
}`

/* Mobile Card css start */
export const MobileRoomCardParent = styled(Box)`
&& {
    padding:1rem 1rem 0rem 1rem;
}`

export const RoomCardlabel = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 21px;
    color: #141414;
}`

export const RoomCartParent = styled(Box)`
&& {
    background: #FFFFFF;
    border: 0.75px solid #E6E6E6;
    border-radius: 10px;
    margin:1rem 0;
    padding: 1rem;
}`

export const RoomCardHeader = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 600;
    font-size: 0.85rem;
    line-height: 19px;
    color: #141414;
    height: 25px;
}`

export const RoomCardBody = styled(Box)`
&& {
    display:flex;
    height: 25px;
    align-items: center;
}`

export const SingleOccImg = styled(Box)`
&& {
    background-image:url(styles/assets/single.svg);
    background-repeat:no-repeat;
    width: 20px;
    height:25px;
    background-position-x: 0;
    background-position-y: center;
}`

export const DoubleOccImg = styled(Box)`
&& {
    background-image:url(styles/assets/double.svg);
    background-repeat:no-repeat;
    width: 20px;
    height:25px;
    background-position-x: 0;
    background-position-y: center;
}`

export const TripleOccImg = styled(Box)`
&& {
    background-image:url(styles/assets/triple.svg);
    background-repeat:no-repeat;
    width: 20px;
    height:25px;
    background-position-x: 0;
    background-position-y: center;
}`

export const RoomOccText = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 19px;
    color: #141414;
    padding-left:5px;
    text-transform:capitalize;
}`

export const RoomCardFooter = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 19px;
    color: #4D4C4C;
    & span{
        font-weight: 700;
        font-size: 1rem;
        padding-right: 5px;
    };
    height: 25px;
    display: flex;
    align-items: flex-end;
}`
/* Mobile Card css end */

/* Mobile Guest css start*/

export const GuestBookingParent = styled(Box)`
&& {
    padding:0 1rem;
}`

export const GuestLabel = styled(Typography)`
&& {
    height:25px;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 21px;
    color: #141414;
    display:flex;
    align-items:flex-end;
}`
export const GDRow = styled(Box)`
&& {
    display:flex;
    box-sizing:border-box;
    flex-direction:column;
}`

export const GDColumn = styled(Box)`
&& {
    width:100%;
    box-sizing:border-box;
    padding:1rem 0 0 0;
}`

export const GDColumnLabel = styled(Typography)`
&& {
    color:#354665;
    font-size:0.85rem;
    font-weight:500;
    margin-bottom:5px;
    font-family:'Red Hat Display';
    & span {
        color:red;
    }
}`

export const GDColumnInput = styled(TextField)`
&& {
    width:95%;
}`
/* Mobile Guest css end*/

/* Confirmation Css Start */
export const MobileConfirmationParent = styled(Box)`
&& {
    width:600px;
    margin:0 auto;
    top:0px;
    z-index: 2;
    background-color: white;
    display: flex;
    flex-direction: column;
    background-color: #E3E5E8;
    overscroll-behavior-x: none;
    height:100vh;
    @media (max-width: 576px) {
        width:100vw;
    }
}`

export const ConfHeader = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    text-align: center;
    color: white;
    background: #008009;
    height:160px;
    display:flex;
    padding:1rem 0;
    align-items: center;
    flex-direction:column
}`

export const CongHeaderChild = styled(Box)`
&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}`

export const ConfirmImg = styled(Box)`
&& {
    height: 32px;
    background-image: url(styles/assets/confirm.svg);
    width: 32px;
    margin-bottom: 1rem;
}
`

export const PaymentInfoParent = styled(Box)`
&&{
    display:flex;
    margin-top:2px;
    padding:1rem;
    flex-grow:1;
    background:white;
    flex-direction:column;
}`

export const PaymentLabel = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 21px;
    color: #141414;
}`

export const PaymentInfo = styled(Box)`
&& {
    padding:1rem 0 0 0;
    display:flex;
    justify-content:space-between;
}`

export const Price = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 21px;
    color: #141414;
}`

export const PriceMode= styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 21px;
    color: #141414;
}`
/* Confirmation Css Ends */

export const RoomsGuestParent = styled(Box)`
&& {
    padding:1rem 1rem;
    margin-top:2px;
    background:white;
}`

export const RGHeader = styled(Typography)`
&& {
    color: #141414;
    font-family: Red Hat Display;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}`

export const RGText = styled(Typography)`
&& {
    color: var(--black-main, #141414);
    font-family: Red Hat Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top:0.5rem;
}`

export const BIDText = styled(Typography)`
&& {
    color: var(--black-main, #141414);
    font-family: Red Hat Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top:0.5rem;
    text-transform:uppercase;
}`