import styled from "styled-components";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";

export const LandingPageContainer = styled(Box)`
&& {
    @media(max-width:576px) {
        display:none;
    }
}`

export const MobileLandingPageContainer = styled(Box)`
&& {
    display:block;
    display: block;
    max-width: 600px;
    margin:0 auto;
    @media(max-width:576px) {
        display:block;
        width:100vw;
    }
}`

export const BookingLPContainer = styled(Box)`
&& {
    display:flex;
    width:100vw;
}`

export const BookingLPHeader = styled(Box)`
&& {
    width:100%;
    @media(max-width:576px) {
        display:block;
        width:100vw;
    };
    display:flex;
    height:70px;
    background-color:white;
    justify-content:space-between;
    align-items:center;
    box-sizing:border-box;
    font-family: 'Red Hat Display';
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 10;
}`

//background:linear-gradient(90deg, #6F24CF 0%, rgba(219, 162, 77, 0.980313) 28.65%, rgba(205, 50, 122, 0.94336) 66.15%, rgba(183, 7, 165, 0.91) 100%);
//background-position: 0% 50%;
//background-image: url(styles/assets/hoteliologo.svg);
export const BookingLPHotelName = styled(Box)`
&& {
    width:100%;
    display:flex;
    height:50px;
    align-items:center;
    font-size:2.2rem;
    font-family: 'Lily Script One';
    font-weight:400;
    background-clip: text !important;
    text-fill-color: transparent;
    padding-left:1rem;
    background-repeat: no-repeat;
    box-shadow: 1px 3px 4px 0px rgba(149, 149, 149, 0.17);
    & img {
        max-height:45px;
    }
    @media(max-width:576px) {
        font-size:1.5rem;
    }
}`

export const BookingLPHotelNumber = styled(Box)`
&& {
    display:flex;
    font-size:1.2rem;
    font-weight:400;
}`

export const RoomSelectionParent = styled(Box)`
&& {
    padding:1.2rem 0 1rem 0;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background:white;
    @media(max-width:576px) {
        width:100vw;
    }
}`



/* Booking Page Rules Css Start */
    // margin:0px 4rem;
    // padding:0 2rem 2rem 2rem;
    // padding-top:1rem;
export const BookingRules = styled(Box)`
&& {
    background-color:white;
    padding: 1rem 1rem;
    margin:3px 0 0 0;
    padding-bottom:${props => (props.hasCart ? '2rem' : '1rem')};
    @media (max-width: 576px) {
        padding: 1rem 1rem;
        margin:3px 0 0 0;
        padding-bottom:${props => 
            (props.hasCart ? '2rem' : '1rem')
        };
        opacity:1;
    }
    & ul {
        @media (max-width: 576px) {
            padding-left: 1rem;
        }
    }
}`

export const BookingRulesHeader = styled(Typography)`
&& {
    font-size:1rem;
    font-weight:700;
}`

export const BookingRulesTime = styled(Typography)`
&& {
    font-size:0.85rem;
}`

export const BookingRulesItem = styled.li`
&& {
    font-size:0.8rem;
    font-weight:400;
    padding-bottom:0.5rem;
}`

/* Booking Page Rules Css End */

/* Booking Page Hotel Info Start */
// margin: 1rem 4rem 0 1rem;
// padding: 0 2rem;
// padding-bottom:1rem;
export const BookingHIParent = styled(Box)`
&& {
    background:white;
    margin: 3px 0 0 0;
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    @media (max-width: 576px) {
        padding: 0;
        margin: 3px 0 0 0;
        padding:0 1rem;
        padding-bottom:0.5rem;
    }
}`



export const Map = styled(Box)`
&& {
    height:120px;
    width:120px;
}`

export const MapImage = styled.img`
&& {
    cursor:pointer;
}`

export const HotelName = styled(Typography)`
&& {
    font-size:1.4rem;
    font-weight:700;
    color:var(--fontColor);
}
`

export const HotelAdress = styled(Typography)`
&& {
    font-size:1rem;
    font-weight:400;
    color:var(--fontColor);
}`

export const HotelNumber = styled(Typography)`
&& {
    font-size:1rem;
    color:var(--fontColor);
    font-weight:500;
}`

export const FacilitiesParent = styled(Box)`
&& {
    background:white;
    padding: 1.5rem 1rem;
}`

export const HotelFacilities = styled(Box)`
&& {
    font-family: 'Red Hat Display';
    display: flex;
    justify-content: flex-start;
    overflow:auto;
}`

export const FacilityParent = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    padding-right:1.5rem;
    & img {
        height:40px;
        width:40px;
    }
}
`

export const ImageParent = styled(Box)`
&& {
    height: 75px;
    width: 75px;
    border: 1px solid #C2C2C2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}`

export const HotelFacility = styled(Typography)`
&& {
    font-size:0.75rem;
    color:#2C2C2E;
    white-space:nowrap;
    margin-top:1rem;
    &::marker {
        color:#67696C;
        font-size:1rem;
    }
    
}`

export const HotelDesc = styled(Box)`
&& {

}`

export const HotelDescTypo = styled(Typography) `
&& {
    font-family: 'Red Hat Display';
    font-weight: 500;
}`

export const AddressandMap = styled(Box)`
&& {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}`

export const Address = styled(Box) `
&& {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 0;
}`
/* Booking Page Hotel Info End */

/* Booking page Images Start Css */
export const MainImageBox = styled(Box)`
&& {
    @media(max-width:576px) {
        height:200px;
    }
}`

export const MainImageBoxGallery = styled(Box)`
&& {
    position:relative;
    margin:0;
}`

export const MainImage = styled.img`
&& {
    @media(max-width:576px) {
        height:200px;
        width:100%;
    }
}`

export const MainCatImage = styled.img`
&& {
    height:16rem;
    width:100%;
}
`

export const ImagesSquare = styled(Box)`
&& {
    margin:0px 4rem;
    padding: 2rem 1rem 2rem 2rem;
    @media (max-width: 576px) {
        padding: 2rem 0rem 0rem 0rem;
        margin:0px 1rem;
    }
}`

export const GridContainer = styled(Grid)`
&& {

}`

export const GridMainSquare = styled(Grid)`
&& {

}`

export const GridMainSquareExtra = styled(Grid)`
&& {
    position:relative;
}`

export const ExtraImgText = styled(Typography)`
&& {
    background: #4D4C4C;
    padding: 2px 12px;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    color: white;
    font-size: 1.25rem;
    left: 50%;
    transform: translate(-35%,-10%);
}`

export const GridGeneralContainer = styled(Grid)`
&& {
    height:100%;
    width:100%;
    @media (max-width: 576px) {
        width:105%;
    }
}`

export const SquareImage = styled.img`
&& {
    width: 104%;
    position: relative;
    left: -2%;
}`

export const SquareImageMain = styled.img`
&& {
    height:97%;
    width:100%;
}`

export const MapContainerParent = styled(Box)`
&& {
    height: 216px;
    width: 100%;
    padding: 1rem;
    padding-top:0px;
    background: white;
    position: relative;
    box-sizing:border-box;
    overflow:hidden;
    &:nth-child(1) {
        height: 100% !important;
        position: relative !important;
        &:nth-child(1) {
            width:calc(100% - 2rem) !important;
        };
    };
}`

/* Booking Page Images End css */

/* Stay selector Css Start*/
export const StaySelectorParent = styled(Box)`
&& {
    background: #FFF;
}`

export const StaySelector = styled(Box)`
&& {
    height:32px;
    border-radius: 4px;
    border: 1px solid #A6BCDA;
    background: #FFF;
    margin:0 1rem;
    display:flex;
}`

export const StaySelectionBox = styled(Box)`
&& {
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    width:50%;
    font-size:0.75rem;
    font-style:'Red Hat Display';
    background-color:${props => {
        if(props.overnight) {
            return '#141414';
        }
        else {
            return '#FFF';
        }
    }};
    border-radius:${props => {
        if(props.overnight) {
            return '4px';
        }
        else {
            return '4px';
        }
    }};
    color:${props => {
        if(props.overnight) {
            return 'white';
        }
        else {
            return '#141414';
        }
    }};
}`
/* Stay selector css end*/