import styled from "styled-components";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import { Button } from "@mui/material";

export const CheckoutParent = styled(Box)`
&& {
    display: flex;
    flex-direction: column;
    position:relative;
    width:100vw;
    @media (max-width: 576px) {
        display:none;
    }
}`

export const CheckoutHeader = styled(Typography)`
&& {
    color:var(--fontColor);
    font-weight:500;
    font-size:1.4rem;
    background-color:#f4f9ff;
    height:15rem;
    position:absolute;
    top:70px;
    padding:1rem 0 0 2rem;
    width:100%;
    font-family:'Red Hat Display';
}`

export const CheckoutLeft = styled(Box)`
&& {
    width: 70%;
    box-sizing: border-box;
    background-color: white;
    z-index: 2;
    position: relative;
    border-radius: 10px 10px 0 0;
}`

export const CheckoutRight = styled(Box)`
&& {
    width:30%;
    box-sizing:border-box;
    margin-left:1rem;
}`

export const HotelInfoParent = styled(Box)`
&& {
    background-color:white;
    border: 1px solid #CFE2FF;
    border-radius: 10px 10px 0px 0px;
    box-sizing:border-box;
    display:flex;
    flex-direction:column;
    margin-bottom:2rem;
}`

export const HotelInfoHeader = styled(Box)`
&& {
    font-size:1.1rem;
    color:var(--fontColor);
    background: #E9ECEF;
    border-radius: 10px 10px 0px 0px;
    height:60px;
    display:flex;
    align-items:center;
    padding-left:1rem;
}`

export const CheckoutBody = styled(Box)`
&& {
    margin: 4rem 2rem 2rem 2rem;
    box-sizing:border-box;
    border-radius: 10px 10px 0 0;
    display:flex;
}`


export const GuestDetailParent = styled(HotelInfoParent)`
&& {
    font-family:'Red Hat Display';
}`

/* Checkout Hotel Info Start*/
export const CheckoutHotelImg = styled.img`
&& {
    height:82px;
    width:105px;
}`

export const CheckoutImgParent = styled(Box)`
&& {
    display: flex;
    margin: 1rem;
}`

export const HotelInfoBody = styled(Box)`
&& {

}`

export const CheckoutHotelLoc = styled(Box)`
&& {
    margin:5px 1rem;
}`

export const CheckoutHotelName = styled(Typography)`
&& {
    font-size:1.3rem;
    color: var(--fontColor);
    font-weight:500;
    font-family:'Red Hat Display';
}`

export const CheckoutHotelAdd = styled(Typography)`
&& {
    font-size:1rem;
    color: var(--fontColor);
    font-weight:400;
    margin-top:0.5rem;
    font-family:'Red Hat Display';
}`

export const CheckoutRange = styled(Box)`
&& {
    height: 5.5rem;
    background-color: #F1F1F1;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    box-sizing:border-box;
    padding-bottom:1.rem;
}`

export const CheckINOUT = styled(Box)`
&& {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin-right:2rem;
}`

export const RoomGuestCount = styled(Box)`
&& {
    width:30%;
    border-left:1px solid #FFFFFF;
    font-size:1rem;
    font-weight:400;
    display:flex;
    justify-content:center;
    color:var(--fontColor);
    align-items:center
}`

export const CheckoutRoomTypeParent = styled(Box)`
&& {
    margin:0.5rem 1rem 0.5rem 1rem;
    display:flex;
}`

export const CheckoutRoomType = styled(Typography)`
&& {
    color: var(--fontColor);
    font-weight: 500;
    font-size: 1rem;
    font-family:'Red Hat Display';
}`

export const CheckoutGuestCount = styled(Typography)`
&& {
    color: var(--fontColor);
    font-weight: 400;
    font-size: 0.75rem;
    display:flex;
    align-items:center;
    margin-left:0.5rem;
    line-height:1.7rem;
    font-family:'Red Hat Display';
    text-transform:capitalize;
}`

export const NNights = styled(Box)`
&& {
    background-color: white;
    color: #003A8C;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0.4rem 1.4rem;
    text-transform: uppercase;
    border-radius: 4px;
}`

export const DashedLine = styled(Box)`
&& {
    width: 3rem;
    border: 1px dashed lightgray;
}
`

export const NNightsParent = styled(Box)`
&& {
    display: flex;
    justify-content: center;
    align-items: center;
}`

export const CheckInParent = styled(Box)`
&& {

}`



export const CheckOutParent = styled(Box)`
&& {
    
}`

export const SmallLabel = styled(Typography)`
&& {
    font-size:0.75rem;
    font-weight:400;
    color:var(--fontColor);
    opacity:0.7
    font-family:'Red Hat Display';
}`

export const BigLabel = styled(Typography)`
&& {
    font-size:1rem;
    font-weight:500;
    color:var(--fontColor);
    font-family:'Red Hat Display';
}`
/* Checkout Hotel Info End*/

/* Checkout Guest Detail Starts*/
export const GDRow = styled(Box)`
&& {
    display:flex;
    box-sizing:border-box;
}`

export const GDColumn = styled(Box)`
&& {
    width:33%;
    box-sizing:border-box;
    padding:1rem;
}`

export const GDColumnLabel = styled(Typography)`
&& {
    color:#354665;
    font-size:0.85rem;
    font-weight:500;
    margin-bottom:5px;
    font-family:'Red Hat Display';
}`

export const GDColumnInput = styled(TextField)`
&& {
    width:85%;
}`

export const GSTRow = styled(Box)`
&& {
    margin:0.5rem 1rem;
    display:flex;
    margin-left:0.5rem;
}`

export const GSTCheckBox = styled(Checkbox)`&& {
&& {

}`

export const GSTLabel = styled(Typography)`
&& {
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:0.7rem;
    color:#2C2C2E;
    font-weight:400;
    font-family:'Red Hat Display';
}`

export const NewGuestBtn = styled(Button)`
&& {
    color:#3D8BFD;
    font-size:0.8rem;
    text-transform:Capitalize;
    letter-spacing: 0.01rem;
    margin-left:1rem;
    margin-bottom:2rem;
}`
/* Checkout Guest Detail End*/


/* Price Break Up Start */
export const  PBParent = styled(Box)`
&& {
    background-color: white;
    position: relative;
    border: 1px solid #CFE2FF;
    border-radius: 5px;
    font-family:'Red Hat Display';
}`

export const  PBHeader = styled(Box)`
&& {
    border-bottom:1px solid #ABB5BE;
    font-size:1.1rem;
    color:var(--fontColor);
    letter-spacing: 0.01rem;
    font-weight:500;
    padding:1rem;
}`

export const  PBPrice = styled(Box)`
&& {
    margin:1rem 0.5rem;
    padding:0rem 0.5rem 10px 0.5rem;
    border-bottom:1px solid #ABB5BE;
    font-size:0.9rem;
    color:var(--fontColor);
    letter-spacing: 0.01rem;
    font-weight:500;
    display:flex;
    justify-content:space-between;
}`

export const  PBTax = styled(Box)`
&& {
    margin:1rem 0.5rem;
    padding:0rem 0.5rem 10px 0.5rem;
    border-bottom:1px solid #ABB5BE;
    font-size:0.9rem;
    color:var(--fontColor);
    letter-spacing: 0.01rem;
    font-weight:500;
    display:flex;
    justify-content:space-between;
}`
export const  PBAmount = styled(Box)`
&& {
    margin:1rem;
    font-size:0.9rem;
    color:var(--fontColor);
    letter-spacing: 0.01rem;
    font-weight:500;
    display:flex;
    justify-content:space-between;
    font-family:'Red Hat Display';
}`

export const PBTaxLabel = styled(Typography)`
&& {
    font-family:'Red Hat Display';
}`

export const PBPriceBoldLabel = styled(Typography)`
&& {
    font-weight:500;
    font-family:'Red Hat Display';
}`


export const PBTaxAmount = styled(Typography)`
&& {
    font-weight:500;
    font-family:'Red Hat Display';

}`

export const PBAmountLabel = styled(Typography)`
&& {
    font-weight:500;
    font-family:'Red Hat Display';
}`

export const PBPriceLabelParent = styled(Box)`
&& {

}`

export const PBAmountValue = styled(Typography)`
&& {
    color:#DC3545;
    font-size:1.1rem;
    font-weight:500;
    font-family:'Red Hat Display';
}`

export const CouponParent = styled(Box)`
&& {
    padding:1rem;
    border: 1px solid #CFE2FF;
    border-radius: 5px;
    position:relative;
    margin-top:1rem;
}`

export const CouponHeader = styled(Typography)`
&& {
    font-size:1.1rem;
    font-weight:500;
    color:var(--fontColor);
    margin-bottom:0.8rem;
    font-family:'Red Hat Display';
}`

export const CouponLabel = styled(Typography)`
&& {
    font-size:0.8rem;
    color:#354665;
    font-weight:500;
    margin-bottom:0.5rem;
    font-family:'Red Hat Display';
}` 

export const CouponInputParent = styled(Box)`
&& {
    display:flex;
}`

export const CouponInput = styled(TextField)`
&& {
    width:60%;
}`

export const ApplyCoupon = styled(Button)`
&& {
    width:40%;
    margin-left:1rem;
    background-color:#0050B3;
    font-size:0.8rem;
    color:white;
    font-weight:500;
    &:hover {
        background-color:#0050B3 !important;
    }
    background: linear-gradient(180deg, #F31771 0%, #D0105F 100%);
    border-radius: 5px;
    font-family:'Red Hat Display';
}`

export const PaymentParent = styled(Box)`
&& {
    margin-top:1rem;
    background: #FFFFFF;
    border: 1px solid #CFE2FF;
    border-radius: 5px;
}`

export const PaymentHeader = styled(Box)`
&& {
    font-size:1.1rem;
    color:var(--fontColor);
    font-weight:500;
    padding:1rem;
    border-bottom:1px solid #ABB5BE;
}`

export const PaymentBody = styled(Box)`
&& {
    
}`

export const PaymentRow = styled(Box)`
&& {
    font-size:0.8rem;
    color:var(--fontColor);
    font-weight:600;
    margin:1rem;

}`
export const MakeReservationParent = styled(Box)`
&& {
    display:flex;
    justify-content:center;
    aligh-items:center;
    margin-top:2rem;
}`

export const MakeReservation = styled(Button)`
&& {
    width:70%;
    font-weight:500;
    color:white;
    font-size:1rem;
    text-transform:capitalize;
    background: linear-gradient(180deg, #F31771 0%, #D0105F 100%);
    border-radius: 5px;
    font-family:'Red Hat Display';
}`
/* Price Break Up End */