import React from 'react';


//Style and Material UI Imports;
import * as MFStyle from '../../../../styles/BookingMobile/mobileFooter.js';

const MobileFooter = () => {
    return (
        <MFStyle.MobileFooter>
            <MFStyle.FooterText>Powered by</MFStyle.FooterText>
            <MFStyle.FooterImg ></MFStyle.FooterImg>
        </MFStyle.MobileFooter>
    )
}

export default MobileFooter;