import React from 'react';

//Material UI and Style Imports.
import Checkout from './Checkout';
import MobileCheckout from './CheckoutMobile';


const CheckoutDetails = () => {
    return (
        <>
            {/* <Checkout /> */}
            <MobileCheckout />
        </>
    )
}

export default CheckoutDetails;