import React , { useState } from 'react';
import { useSelector } from 'react-redux';

//internal imports
import RoomSelection from '../../RoomSelection/RoomSelection';
import Carousel from 'react-material-ui-carousel';
// import 'react-material-ui-carousel/lib/styles.css';

//Style Imports

import * as MobileHIStyle from '../../../../styles/Booking/mobileLPStyle';
import { Box } from '@mui/material';

const MobileLPImages = () => {

    const [showGallery,setShowGallery] = useState(false);

    const hotelImages = useSelector(state => {
        return state.bookingDetails.hotelPhotos;
    });

    const getMainImage = () => {
        return hotelImages.find(img => img.photo_type == "MAIN").photo;
    }

    const getSquareImage = () => {
        let img = hotelImages.filter(img => img.photo_type == "SQUARE");
        if(img && img.length > 0)
            return img[0].photo;
    }

    const hotelImagesWithoutLogo = hotelImages.filter(img => img.photo_type != "LOGO");

    const getGeneralImage = () => {
        let imgList = hotelImages;//.filter(img => img.photo_type == "SQUARE");
        let imgCollection = []
        imgList.map(imgcol => {
            if(imgcol.photo !== '{{photo}}' && imgcol.photo_type != "LOGO") 
                imgCollection.push(
                    <MobileHIStyle.SquareImage src = { imgcol.photo } alt = 'Hotel Room Image' />
                );
        })
        return imgCollection;
    }

    const getMoreText = () => {
        if(hotelImages.length > 5)
            return `+ ${hotelImages.length-5}`;
    }

    const showGalleryHandler = () => {
        setShowGallery(true);
    }

    return (
        <>
            {/* <BookingHIStyle.MainImageBox>
               <BookingHIStyle.MainImage src = { getMainImage() } alt = 'Main Image of the Hotel'>   
               </BookingHIStyle.MainImage>  
            </BookingHIStyle.MainImageBox> */}
            { showGallery ? 
                <MobileHIStyle.CarouselBox>
                    <Carousel autoPlay = { false } indicators = { false } navButtonsAlwaysVisible = { true }>
                        { getGeneralImage() }
                    </Carousel>
                    <MobileHIStyle.CarouselBackButton onClick = { () => { setShowGallery(false);} }></MobileHIStyle.CarouselBackButton>
                </MobileHIStyle.CarouselBox>
                :
                <MobileHIStyle.CarouselBox>
                    <MobileHIStyle.TopImageRow>
                        <MobileHIStyle.TopLeftImageCont>
                            <MobileHIStyle.TopLeftImage style={{ borderRadius:'4px 0 0 0 '}} onClick={showGalleryHandler} src = { hotelImagesWithoutLogo[0]?.photo }></MobileHIStyle.TopLeftImage>
                        </MobileHIStyle.TopLeftImageCont>
                        <MobileHIStyle.TopImage style={{ borderRadius:'0px 4px 0 0 '}} onClick={showGalleryHandler}  src = { hotelImagesWithoutLogo[1]?.photo }></MobileHIStyle.TopImage>
                    </MobileHIStyle.TopImageRow>
                    <MobileHIStyle.ImageRow>
                        <MobileHIStyle.BottomImage style={{ borderRadius:'0px 0 0 4px '}} onClick={showGalleryHandler}  src = { hotelImagesWithoutLogo[2]?.photo }></MobileHIStyle.BottomImage>
                        <MobileHIStyle.MiddleImageCont>
                            <MobileHIStyle.BottomMiddleImage onClick={showGalleryHandler}  src = { hotelImagesWithoutLogo[3]?.photo }></MobileHIStyle.BottomMiddleImage>
                        </MobileHIStyle.MiddleImageCont>
                        <MobileHIStyle.LastImgContainer onClick={showGalleryHandler} >
                            <MobileHIStyle.LastImage style = {{ borderRadius:'0px 0 4px 0 '}}  src = { hotelImagesWithoutLogo[4]?.photo }></MobileHIStyle.LastImage>
                            <MobileHIStyle.LastImgText>{ getMoreText() }</MobileHIStyle.LastImgText>
                        </MobileHIStyle.LastImgContainer>
                    </MobileHIStyle.ImageRow>
                </MobileHIStyle.CarouselBox>
            }
        </>
    )
}

export default MobileLPImages;