import React from 'react';
import { useSelector } from 'react-redux';

//Style Imports
import * as BookingLPStyle from '../../../styles/Booking/bookingLPStyle';

const BookingPageHeader = ({ name, phone}) => {

    const hotelImages = useSelector(state => state.bookingDetails.hotelPhotos);

    const getImage = () => {
        if(hotelImages != null && hotelImages.length > 0)   {
            if(hotelImages.find(img => img.photo_type == 'LOGO') != null)
                return hotelImages.find(img => img.photo_type == 'LOGO').photo;
        }
        return '';
    }

    return (
        <BookingLPStyle.BookingLPHeader>
            <BookingLPStyle.BookingLPHotelName>
                <img src = { getImage() } />
            </BookingLPStyle.BookingLPHotelName>
            {/* <BookingLPStyle.BookingLPHotelNumber>
                { phone }
            </BookingLPStyle.BookingLPHotelNumber> */}
        </BookingLPStyle.BookingLPHeader>
    )
}

export default BookingPageHeader;