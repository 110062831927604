import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Typography,Button } from '@mui/material';

export const CartParent = styled(Box)`
&& {
    margin: 2rem 2rem 3rem 0rem;
    background-color:  #EFF3FA;
    border-radius: 0px 10px 0px 0px;
    color: var(--fontColor);
    box-sizing: border-box;
    border-bottom: 1px solid #CFE2FF;
    width: 35%;
    display:flex;
    flex-direction:column;
    border-top:0px;
}`

export const CartHeader = styled(Box)`
&& {
    display: flex;
    width: 100%;
    justify-content: center;
    height:52px;
    display:flex;
    align-items:center;
    background: #091222;
    border-radius: 0px 10px 0px 0px;
    color:white;
    position:sticky;
    top:0px;
}`

export const CartBody = styled(Box)`
&& {
    padding-left:1rem;
    flex-grow:1;
    border:1px solid #CFE2FF;
}`

export const  CardParent = styled(Box)`
&& {
    background: #FFFFFF;
    border: 1px solid #CFE2FF;
    border-radius: 10px;
    color:#031633;
    font-family:'Red Hat Display';
    display:flex;
    flex-direction:column;
    width: 90%;
    padding: 1rem 1rem 0 1rem;
    margin-top:1rem;
}`

export const CardHeader = styled(Box)`
&& {
    display: flex;
    justify-content: space-between;
    align-items:center;
}`

export const CardTitleParent = styled(Box)`
&& {
    padding-left:0.25rem;
}`

export const CardTitle = styled(Box)`
&& {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 30px;
}`

export const CardIconParent = styled(Box)`
&& {
    font-style: normal;
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 21px;
    display:flex;
}`

export const CardPrice = styled(Typography)`
&& {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 15px;
    & span{
        font-size:0.75rem;
    }
}`

export const CardBody = styled(Box)`
&& {
    display:flex;
    flex-wrap:wrap;
    margin-top:0.8rem;
}`

export const MealCard = styled(Box)`
&& {
    min-width:50%;
    display:flex;
    padding-bottom:1rem;
    justify-content:space-between;
    align-items:center;
    padding-right:${props => (props.paddingRight ? '1rem' : '0')};
}`

export const MealTitleParent = styled(Box)`
&& {
    font-style: normal;
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 21px;
}`

export const MealTitle = styled(Typography)`
&& {
    font-style: normal;
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 21px;
    font-family:'Red Hat Display';
}` 

export const MealPrice = styled(Typography)`
&& {
    font-style: normal;
    font-weight: 500;
    font-size: 0.70rem;
    line-height: 21px;
    font-family:'Red Hat Display';
    & span {
        padding-right:0.5rem;
    }
}` 

export const MealButton = styled(Box)`
&& {
    font-style: normal;
    font-weight: 500;
    font-size: 0.65rem;
    line-height: 21px;
    color: #002766;
    text-transform: capitalize;
    background: #FFFFFF;
    border: 0.5px solid #CFE2FF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 1.8rem;
    cursor:pointer;
}`


export const SingleOccupancyImg = styled(Box)`
&& {
    background-image:url(styles/assets/single.svg);
    background-repeat:no-repeat;
    height: 25px;
    width: 25px;
}`

export const DoubleOccupancyImg = styled(Box)`
&& {
    background-image:url(styles/assets/double.svg);
    background-repeat:no-repeat;
    height: 25px;
    width: 48px;
}`

export const TripleOccupancyImg = styled(Box)`
&& {
    background-image:url(styles/assets/triple.svg);
    background-repeat:no-repeat;
    height: 25px;
    width:70px;
}`

export const CartFooter = styled(Box)`
&& {
    display: flex;
    justify-content: center;
    align-items:center;
    margin: 1rem 0;
    width: 90%; 
    flex-direction:column;
}`

export const NextButton = styled(Box)`
&& {
    background: linear-gradient(180deg, #F31771 0%, #D0105F 100%);
    border-radius: 5px;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.12rem;
    line-height: 18px;
    display:flex;
    justify-content:center;
    align-items:center;
    width:fit-content;
    height:36px;
    padding:0 2rem;
    color: #FFFFFF;
    cursor:pointer;
    margin-top:1rem;
}`
export const FooterTotal = styled(Box)`
&& {
    display:flex;
    justify-content:space-between;
    width:100%;
}`

export const TotalRooms = styled(Box)`
&& {

}`

export const TotalAmount = styled(Box)`
&& {
    
}`

export const TotalRoomsTitle = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.12rem;
    line-height: 24px;
    color: #031633;
}`

export const TotalRoomsCount = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 16px;
    color: #031633;
    opacity: 0.7;
}`

export const TotalAmountValue = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.12rem;
    line-height: 24px;
    color: #031633;
}`

export const TotalAmountValueTax = styled(Typography)`
&& {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 16px;
    color: #031633;
    opacity: 0.7;
}`