//External Imports 
import React, { useEffect } from 'react';
import { useDispatch , useSelector } from 'react-redux';
import axios from 'axios';

//Internal Imports
import LandingPageDetails from './LandingPageDetails';
import API from '../../api';


//Slice imports
import { 
    setBookingDetails,
    setHotelId 
} from '../../slices/bookingDetails';

const BookingDetails = (props) => {

    const dispatch = useDispatch();

    const hotelId = useSelector(state => state.bookingDetails.hotelId);

    const updateBookingInfo = (currenthotelId) => {
        let hotId = currenthotelId;
        let promiseArray = [];
        promiseArray.push(API.get(`/getHotelDetailsById?hotel_id=${currenthotelId}`));
        promiseArray.push(API.get(`/getHotelPhotosByHotelId?hotel_id=${currenthotelId}`));
        promiseArray.push(API.get(`/getHotelFacilitiesByHotelId?hotel_id=${currenthotelId}`));
        promiseArray.push(API.get(`/getHotelRulesByHotelId?hotel_id=${currenthotelId}`));
        promiseArray.push(API.get(`/getContactDetailForHotel?hotel_id=${currenthotelId}`));
        promiseArray.push(API.get(`/getAllEmployeesForHotel?hotel_id=${currenthotelId}`));

        Promise.all(promiseArray).then((res) => {
            let hotelDetails = res[0].data.data[0];
            let hotelPhotos = res[1].data.data;
            let hotelfacilities = res[2].data.data;
            let hotelRules = res[3].data.data;
            let contactDetails = res[4].data.data;
            let hotelEmployees = res[5].data.data;
            if(hotelRules.length == 0) {
                hotelRules.push({
                    hotel_rule_id: 1,
                    rule_color: "black",
                    rule_description: "Aadhar, Driving License and Govt. ID are accepted as ID proof(s)",
                    rule_image: null,
                    show_in_bold: 0
                })
                hotelRules.push({
                    hotel_rule_id: 1,
                    rule_color: "black",
                    rule_description: "PAN Card and Non-Govt IDs are not accepted as ID proof(s)",
                    rule_image: null,
                    show_in_bold: 0
                })
                hotelRules.push({
                    hotel_rule_id: 1,
                    rule_color: "black",
                    rule_description: "Local IDs are allowed",
                    rule_image: null,
                    show_in_bold: 0
                })
                hotelRules.push({
                    hotel_rule_id: 1,
                    rule_color: "black",
                    rule_description: "Pets are not allowed",
                    rule_image: null,
                    show_in_bold: 0
                })
                hotelRules.push({
                    hotel_rule_id: 1,
                    rule_color: "black",
                    rule_description: "Smoking within the premises is not allowed",
                    rule_image: null,
                    show_in_bold: 0
                })
                hotelRules.push({
                    hotel_rule_id: 1,
                    rule_color: "black",
                    rule_description: "Does not allow private parties or events",
                    rule_image: null,
                    show_in_bold: 0
                })
            }
            dispatch(setBookingDetails({
                hotelDetails,
                hotelPhotos,
                hotelfacilities,
                hotelRules,
                currentHotelId:hotId,
                contactDetails,
                hotelEmployees
            }))
        });
    }

    useEffect(() => {

        const getHotelId = async () => {
            try {
                const resp = await axios.post('/api/hotel/getHotelIdFromUrlPattern',{
                    //urlPattern:`${window.location.hostname.split('.')[0]}.app`
                    urlPattern:window.location.origin
                });
                if(resp.data.length > 0) {
                    updateBookingInfo(resp.data[0].hotel_id);
                }
            }
            catch(ex) {
                var a = 1; 
            }
        }
        if(window.location.host == 'www.app.hotelio.in') {
            window.location.replace('https://app.hotelio.in');
        }
        else {
            if(hotelId == -1)
                getHotelId();
            else 
                updateBookingInfo(hotelId)
        }
        
    },[hotelId]);

    return (
        <>
            { (hotelId > -1) && <LandingPageDetails /> }
        </>
        //<RoomCategoryLandingPage />
    )
}

export default BookingDetails;