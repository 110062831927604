import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
var numeral = require('numeral');

//Style and Material UI Imports
import * as HotelRoomStyle from '../../../../styles/Booking/hotelRoomStyle';
import { TextField } from "@mui/material";

//InternalImports
// import { setRoomTypeInfo } from "../../../slices/bookingDetails";
import { ROOMONLY, RoomsDropDownRange } from "../../../../config/constants";
import { addRoomsToCart } from "../../../../slices/bookingDetails";
import CounterComponent from "../../../../utils/CounterComponent";

const RoomCategoryInfo = () =>{

    const [noOfRooms, setNoofRooms] = useState({});

    const history = useHistory();
    const dispatch = useDispatch();
    const hotelRoomInfo = useSelector(state => {
        return {
            hotelRooms:state.bookingDetails.hotelRooms,
            hotelCategory:state.bookingDetails.hotelCategory,
            hotelRoomImages:state.bookingDetails.hotelRoomImages
        }
    })

    const cart = useSelector(state => {
        return state.bookingDetails.cart;
    })

    const getRoomInfoHeader = () => {
        return (
            <>
                <HotelRoomStyle.RoomTypeNameHeader>
                    { "Room Types" }
                </HotelRoomStyle.RoomTypeNameHeader>
                <HotelRoomStyle.RoomOptionsHeader>
                    { "Sleeps" }
                </HotelRoomStyle.RoomOptionsHeader>
                <HotelRoomStyle.RoomCountHeader>
                    { "Price" }
                </HotelRoomStyle.RoomCountHeader>
                <HotelRoomStyle.RoomPriceHeader>
                    { "Select Room" }
                </HotelRoomStyle.RoomPriceHeader>
            </>
        )
    }

    const getHotelRooms = () => {
        let rooms = hotelRoomInfo.hotelRooms.map(hotelRoom => {
            return (
                <HotelRoomStyle.HotelRoom key = { hotelRoom.name }>
                    <HotelRoomStyle.RoomType>
                        { getRoomType(hotelRoom) }
                    </HotelRoomStyle.RoomType>
                    <HotelRoomStyle.RoomOccupancy>
                        { getOccupancyLabel() }
                    </HotelRoomStyle.RoomOccupancy>
                    <HotelRoomStyle.RoomPrices>
                        { getRoomPrice(hotelRoom.hotel_room_type_id) }
                    </HotelRoomStyle.RoomPrices>
                    <HotelRoomStyle.RoomOptions>
                        { getRoomOptions(hotelRoom) }
                    </HotelRoomStyle.RoomOptions>
                </HotelRoomStyle.HotelRoom>
            )
        });
        console.log(rooms);
        return rooms;
    }

    const getRoomTypeImage = (hotelRoomId) => {
        let images = hotelRoomInfo.hotelRoomImages.find(img => img.hotel_room_type_id == hotelRoomId);
        if(images)
            return images.photo;
    }

    const getRoomType = (hotelRoom) => {
        return (
            <>
                <HotelRoomStyle.RoomTypeName>
                    { hotelRoom.name }
                </HotelRoomStyle.RoomTypeName>
                <HotelRoomStyle.RoomTypeImage src = { getRoomTypeImage(hotelRoom.hotel_room_type_id) }  alt = "This is an image for Room Type">
                </HotelRoomStyle.RoomTypeImage>
            </>
        )
    }

    const getOccupancyLabel = (hotelRoomId) => {
        return (
            <>
                <HotelRoomStyle.OccupancyImgParent>
                    <HotelRoomStyle.SingleOccupancyImg />
                </HotelRoomStyle.OccupancyImgParent>
                <HotelRoomStyle.OccupancyImgParent>
                    <HotelRoomStyle.DoubleOccupancyImg />
                </HotelRoomStyle.OccupancyImgParent>
                <HotelRoomStyle.OccupancyImgParent>
                    <HotelRoomStyle.TripleOccupancyImg />
                </HotelRoomStyle.OccupancyImgParent>
            </>
        )
    }

    const getHotelOptionsSelected = (hotelRoom,suffix) => {
        let options = [];
        let selectedOption;
        let selectedValue = noOfRooms[hotelRoom.name.replaceAll(' ','') + suffix] || 0;
        for(let i = 0; i <= RoomsDropDownRange; i ++) {
            let optObj = {
                label:i,
                code:i
            }
            if(i == selectedValue)
                selectedOption = optObj;
            options.push(optObj);
        }
        return [selectedOption,options];
    }

    const getRoomPriceBasedOnOcc = (occupancy,attributes) => {
        if(occupancy == 'single')
            return attributes.price;
        else if(occupancy == 'double')
            return attributes.doubleOccupancyPrice;
        else if(occupancy == 'triple')
            return attributes.extraBedPrice;
    }

    const createRoomCardsTobeAdded = (noOfRooms,hotelRoom,suffix) => {
        let hotelObj;
        if(cart[hotelRoom.name])
            hotelObj = {...cart[hotelRoom.name]};
        else {
            hotelObj = {};
        }
        let roomCards = [];
        let hotelCat = hotelRoomInfo.hotelCategory.find(cat => cat.hotel_room_type_id == hotelRoom.hotel_room_type_id && cat.hotel_room_type_category_code == 'ROOM_ONLY');
        for(let i = 0; i < noOfRooms; i ++) {
            roomCards.push({
                id:uuidv4(),
                price:getRoomPriceBasedOnOcc(suffix,hotelCat.attributes),
                roomTypeId:hotelRoom.hotel_room_type_id,
                roomTypeCategoryID:hotelCat.hotel_room_type_category_id,
                mealInfo:{}
            })
        }
        hotelObj[suffix] = roomCards;
        return hotelObj;
    }

    const roomNoChangeHandler = (newValue,hotelRoom,suffix) => {
        let propName = hotelRoom.name.replaceAll(' ','') + suffix;
        let roomCards = createRoomCardsTobeAdded(newValue.code,hotelRoom,suffix);
        dispatch(addRoomsToCart({
            roomType:hotelRoom.name,
            roomCards
        }));
        setNoofRooms((prevObj) => ({
            ...prevObj,
            [propName]:newValue.code
        }));
    }

    const roomNoCounterChangeHandler = (count,hotelRoom,suffix) => {
        let propName = hotelRoom.name.replaceAll(' ','') + suffix;
        let roomCards = createRoomCardsTobeAdded(count,hotelRoom,suffix);
        dispatch(addRoomsToCart({
            roomType:hotelRoom.name,
            roomCards
        }));
        setNoofRooms((prevObj) => ({
            ...prevObj,
            [propName]:count
        }));
    }

    const getRoomOptions = (hotelRoom) => {
        const [singleOccSeleted,singelOccOptions] = getHotelOptionsSelected(hotelRoom,'single');
        const [doubleOccSeleted,doubleOccOptions] = getHotelOptionsSelected(hotelRoom,'double');
        const [tripleOccSeleted,tripleOccOptions] = getHotelOptionsSelected(hotelRoom,'triple');
        return (
            <>
                <HotelRoomStyle.OccupancyImgParent>
                    {/* <HotelRoomStyle.RoomCountSelect 
                        options = { singelOccOptions } value = { singleOccSeleted } size="small"
                        InputLabelProps={{shrink: false}} placeholder="Room" disableClearable
                        onChange={ (event,newValue) => roomNoChangeHandler(newValue,hotelRoom,'single')}
                        renderInput={(params) => <TextField {...params} placeholder = "Room" />}
                    >
                    </HotelRoomStyle.RoomCountSelect> */}
                    <CounterComponent 
                        name = { hotelRoom.name} count = { singleOccSeleted.label } maxCount = { RoomsDropDownRange }
                        onCountChange = {(count,name) => {roomNoCounterChangeHandler(count,hotelRoom,'single')}}
                    />
                </HotelRoomStyle.OccupancyImgParent>
                <HotelRoomStyle.OccupancyImgParent>
                    {/* <HotelRoomStyle.RoomCountSelect
                        options = { doubleOccOptions } value = { doubleOccSeleted } size="small"
                        InputLabelProps={{shrink: false}} placeholder="Room" disableClearable
                        onChange={ (event,newValue) => roomNoChangeHandler(newValue,hotelRoom,'double')}
                        renderInput={(params) => <TextField {...params}  placeholder = "Room"/>}
                    >
                    </HotelRoomStyle.RoomCountSelect> */}
                    <CounterComponent 
                        name = { hotelRoom.name} count = { doubleOccSeleted.label } maxCount = { RoomsDropDownRange }
                        onCountChange = {(count,name) => {roomNoCounterChangeHandler(count,hotelRoom,'double')}}
                    />
                </HotelRoomStyle.OccupancyImgParent>
                <HotelRoomStyle.OccupancyImgParent>
                    {/* <HotelRoomStyle.RoomCountSelect 
                        options = { tripleOccOptions } value = { tripleOccSeleted } size="small"
                        InputLabelProps={{shrink: false}} placeholder="Room" disableClearable
                        onChange={ (event,newValue) => roomNoChangeHandler(newValue,hotelRoom,'triple')}
                        renderInput={(params) => <TextField {...params} placeholder = "Room" />}
                    >
                    </HotelRoomStyle.RoomCountSelect> */}
                    <CounterComponent 
                        name = { hotelRoom.name} count = { tripleOccSeleted.label } maxCount = { RoomsDropDownRange }
                        onCountChange = {(count,name) => {roomNoCounterChangeHandler(count,hotelRoom,'triple')}}
                    />
                </HotelRoomStyle.OccupancyImgParent>
            </>
        )
    }

    // const selectRoom = (roomCategory,hotelRoomId) => {
    //     dispatch(setRoomTypeInfo({
    //         hotelRoom:hotelRoomInfo.hotelRooms.find(room => room.hotel_room_type_id == hotelRoomId),
    //         roomCategory:roomCategory
    //     }));
    //     setTimeout(() => {
    //         history.push('/checkOut');
    //     },500);
    // }

    const getRoomPrice = (hotelRoomId) => {
        let hotelRoomCat = hotelRoomInfo.hotelCategory.find(cat => cat.hotel_room_type_id == hotelRoomId && cat.hotel_room_type_category_code == ROOMONLY);
        let prices = []
        prices.push(
            <HotelRoomStyle.RoomPrice key = { hotelRoomCat.attributes.price }>
                <HotelRoomStyle.PriceDetails>
                    <HotelRoomStyle.Price>
                        { `${hotelRoomCat.attributes.currency_symbol} ${numeral(hotelRoomCat.attributes.price).format('0,0')}`}
                    </HotelRoomStyle.Price>
                    <HotelRoomStyle.Taxes>
                        (inc. taxes)
                    </HotelRoomStyle.Taxes>
                </HotelRoomStyle.PriceDetails>
            </HotelRoomStyle.RoomPrice>
        )
        prices.push(
            <HotelRoomStyle.RoomPrice key = { hotelRoomCat.attributes.doubleOccupancyPrice }>
                <HotelRoomStyle.PriceDetails>
                    <HotelRoomStyle.Price>
                        { `${hotelRoomCat.attributes.currency_symbol} ${numeral(hotelRoomCat.attributes.doubleOccupancyPrice).format('0,0')}`}
                    </HotelRoomStyle.Price>
                    <HotelRoomStyle.Taxes>
                        (inc. taxes)
                    </HotelRoomStyle.Taxes>
                </HotelRoomStyle.PriceDetails>
            </HotelRoomStyle.RoomPrice>
        )
        prices.push(
            <HotelRoomStyle.RoomPrice key = { hotelRoomCat.attributes.extraBedPrice }>
                <HotelRoomStyle.PriceDetails>
                    <HotelRoomStyle.Price>
                        { `${hotelRoomCat.attributes.currency_symbol} ${numeral(hotelRoomCat.attributes.extraBedPrice).format('0,0')}`}
                    </HotelRoomStyle.Price>
                    <HotelRoomStyle.Taxes>
                        (inc. taxes)
                    </HotelRoomStyle.Taxes>
                </HotelRoomStyle.PriceDetails>
            </HotelRoomStyle.RoomPrice>
        )
        return prices;
    }

    return (
        <HotelRoomStyle.HotelRoomParent>
            <HotelRoomStyle.RoomTypeHeader> 
                { getRoomInfoHeader() }
            </HotelRoomStyle.RoomTypeHeader>
            { getHotelRooms() }
        </HotelRoomStyle.HotelRoomParent>
    )
}

export default RoomCategoryInfo;