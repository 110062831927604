import React, { useEffect ,useState } from 'react';

//Material UI and Styled Imports
import * as RatingStyle from '../../../../styles/BookingMobile/mapNRatingsStyle';

//Internal Imports
import { GOOGLEAPI } from '../../../../api';

const ReviewsContainer = (props) => {

    const [ratings, setRatings] = useState(0)
    const [totalRatings, setTotalRatings] = useState(0)

    const getRatings = async () => {
        const placesResp = await GOOGLEAPI.get(`/placesAPI?add=${props.address}`);
        if(props.hotelId == 100) {
            setRatings(4.3);
            setTotalRatings(1923);
        }
        else {
            setRatings(placesResp.data.result.rating);
            setTotalRatings(placesResp.data.result.user_ratings_total)
        }
    }

    useEffect(() => {
        getRatings()
        return () => {

        }
    },[])

    return (
        <RatingStyle.ReviewsContainerBox>
            <RatingStyle.RatingsBox>
                <RatingStyle.RatingImage></RatingStyle.RatingImage>
                <RatingStyle.RatingParent>
                    <RatingStyle.RatingHeader>Google Reviews</RatingStyle.RatingHeader>
                    <RatingStyle.RagingContainer>
                        <RatingStyle.RatingText>{ ratings }</RatingStyle.RatingText>
                        <RatingStyle.RatingCompContainer>
                            <RatingStyle.Ratings value = {ratings} precision= {0.1} ></RatingStyle.Ratings>
                            <RatingStyle.RatingTotal>{ `${totalRatings} reviews` }</RatingStyle.RatingTotal>
                        </RatingStyle.RatingCompContainer>
                    </RatingStyle.RagingContainer>
                </RatingStyle.RatingParent>
            </RatingStyle.RatingsBox>
        </RatingStyle.ReviewsContainerBox>
    )
}

export default ReviewsContainer;