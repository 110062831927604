//External Imports
import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { Provider } from "react-redux";

//Material UI Imports
import * as AppStyle from '../styles/appStyle.js';

//Interal Imports
import store from '../slices';
import BookingDetails from './BookingDetails';
import CheckoutDetails from './BookingDetails/CheckoutDetails';

import RoomDetails from './BookingDetails/RoomDetails';
import MobileBookingConfirmation from './BookingDetails/CheckoutMobile/MobileBookingConfirmation.js';
import MobileCancelDetails from './BookingDetails/MobileCancel/MobileCancelDetails.js';

const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <AppStyle.AppBox>
                    <Switch>
                        <Route path= '/confirmed/:bookingId' component = { MobileBookingConfirmation } />
                    </Switch>
                    <Switch>
                        <Route path= '/' exact component = { BookingDetails } />
                    </Switch>
                    <Switch>
                        <Route path = '/checkout' component = { CheckoutDetails } />
                    </Switch>
                    <Switch>
                        <Route path = '/cancel' component = { MobileCancelDetails } />
                    </Switch>
                </AppStyle.AppBox>
            </Router>
        </Provider>
    )
}

export default App;