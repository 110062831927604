import React, { useState } from "react";
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

//Internal Imports
import API from "../../../api";

//Style Imports
import * as MCStyle from '../../../styles/BookingMobile/mobileCancelStyle';
import { NAME_REGEX, MOBILE_REGEX } from "../../../config/constants";

const MobileCancelPopUp = (props) => {

    const [formState, setFormState] = useState({});

    const [errorState, setErrorState] = useState({});

    let hotel_id = useSelector(state => state.bookingDetails.hotelId);

    const history = useHistory();

    let yupObject = yup.object({
        mobile_number:yup.string().matches(MOBILE_REGEX,'Please enter a valid Phone Number.'),
        booking_id:yup.string().matches(NAME_REGEX,'Please enter a valid Phone Number.')
    });

    const validateData = () => {
        let promiseArr = [];
        let errorObj = {}
        if(formState.mobile_number == null || formState.mobile_number == '')
            errorObj.mobile_number = 'Please enter valid mobile number';
        if(formState.booking_id == null || formState.booking_id == '')
            errorObj.booking_id = 'Please enter valid booking id';

        if(Object.keys(errorObj).length > 0) {
            setErrorState((prevObj) => ({
                ...prevObj,
                ...errorObj
            }))
            return;
        }

        promiseArr.push(yupObject.validate(formState,{ abortEarly:false}));
        Promise.all(promiseArr).then((res) => {
            getBookingData();
        }).catch(err => {
            var a = 1;
            const errors = err.inner.reduce((acc, error) => {
                return {
                    ...acc,
                    [error.path]: true,
                }
            }, {});
            let obj = {};
            let hasError = false;
            if(errors['mobile_number']) {
                hasError = true;
                obj['mobile_number'] = 'Please enter a valid Mobile Number.';
            }
            if(errors['booking_id']) {
                hasError = true;
                obj['email_id'] = 'Please enter a valid Booking ID.';
            }
            if(hasError) {
                setErrorState((prevObj) => ({
                    ...prevObj,
                    ...obj  
                }));
            }
        })
    }

    const getBookingData = () => {
        API.post('/getBookingData', {
            mobile:formState.mobile_number,
            booking_id:formState.booking_id,
            hotel_id
        }).then((res) => {
            if(res.data.data.length > 0) {
                history.push({
                    pathname:'/cancel',
                    state:res.data.data[0]
                })
            }
            else {
                setErrorState((prevObj) => ({
                    ...prevObj,
                    ...{
                        mobile_number:'Please enter valid Mobile Number',
                        booking_id:'Please enter valid Booking ID'
                    }  
                }));
            }
        }).catch(err => {

        });
    }

    const onChange = (event) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [event.target.name]:event.target.value
        }))
    }

    const getErrorState = (name) => {
        if(errorState[name]) {
            return {
                error:true,
                helperText:errorState[name]
            }
        }
    }

    return (
        <MCStyle.MCPopUpContainer>
            <MCStyle.PopupHeader>
                Cancel Your Booking 
            </MCStyle.PopupHeader>
            <MCStyle.CrossIcon onClick = { props.closePopUP}></MCStyle.CrossIcon>
            <MCStyle.MobileNo size = "small" placeholder = "Enter mobile number"
            name = "mobile_number"
            {...getErrorState("mobile_number")}
            onBlur = { onChange }>     
            </MCStyle.MobileNo>
            <MCStyle.BookingID size = "small" placeholder = "Enter Booking ID"
            name = "booking_id"
            {...getErrorState("booking_id")}
            onBlur = { onChange }>
            </MCStyle.BookingID>
            <MCStyle.VerifyBtn variant = "contained" size = "small" onClick = { validateData }>
                Verify
            </MCStyle.VerifyBtn>
        </MCStyle.MCPopUpContainer>
    )
}

export default MobileCancelPopUp;