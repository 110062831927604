//External Imports
import React from 'react';
import { useSelector } from 'react-redux';

//Style and Material UI Imports
import * as BookingHIStyle from '../../../../styles/Booking/bookingLPStyle';
import * as MobileHIStyle from '../../../../styles/Booking/mobileLPStyle';

//Internal Imports

const MobileFacilitesPage = () => {

    const hotelfacilities = useSelector((state) => {
        return state.bookingDetails.hotelfacilities;
    });

    const getHotelFacilities = () => {
        let facilities = hotelfacilities.map(facility => {
            return (
                <BookingHIStyle.FacilityParent>
                    <BookingHIStyle.ImageParent>
                        <img src = {`styles/assets/${facility.facility_image_location}`} />
                    </BookingHIStyle.ImageParent>
                    <BookingHIStyle.HotelFacility key = { facility.facility_id }> { facility.facility_name } </BookingHIStyle.HotelFacility>
                </BookingHIStyle.FacilityParent>
            )
        });
        return facilities;
    }

    return (
        <BookingHIStyle.FacilitiesParent>
            <BookingHIStyle.HotelFacilities>
                { getHotelFacilities() }
            </BookingHIStyle.HotelFacilities>
        </BookingHIStyle.FacilitiesParent>
    )
}

export default MobileFacilitesPage;