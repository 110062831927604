import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';

//Material UI and styled imports
import * as CounterStyle from '../styles/utils/counterStyle';

const CounterComponent = (props) => {
  //const [count, setCount] = useState(0);
  
  const incrementCount = () => {
    if(props.count <= props.maxCount) {
        //setCount(count + 1);
        props.onCountChange(props.count + 1,props.name);
    }
  };

  const decrementCount = () => {
    if (props.count > 0) {
      //setCount(count - 1);
      props.onCountChange(props.count - 1,props.name);
    }
  };

  

  return (
    <CounterStyle.CounterParent>
      <CounterStyle.CounterButton onClick={decrementCount} variant = "text">-</CounterStyle.CounterButton>
      <span>{props.count}</span>
      <CounterStyle.CounterButton onClick={incrementCount}  variant = "text">+</CounterStyle.CounterButton>
    </CounterStyle.CounterParent>
  );
};

export default CounterComponent;