import React from 'react';

//MUI and Style Imports
import * as BookingLPStyle from '../../../../styles/Booking/bookingLPStyle';
import { HOURLY_STAY, OVERNIGHT_STAY } from '../../../../config/constants';

//Internal Imports

const MobileStaySelector = (props) => {

    const stayClickHandelr = (name) => {
        if(name != props.stayType)
            props.stayHandler(name)
    }

    const isOverNightStay = (name) => {
        if(props.stayType == OVERNIGHT_STAY)
            return true;
        return false;
    }

    const isHourly = (name) => {
        if(props.stayType == OVERNIGHT_STAY)
            return false;
        return true;
    }

    return (
        <BookingLPStyle.StaySelectorParent>
            <BookingLPStyle.StaySelector>
                <BookingLPStyle.StaySelectionBox name = {OVERNIGHT_STAY} onClick = {()  => stayClickHandelr(OVERNIGHT_STAY) } overnight = { isOverNightStay() }>Overnight Stay</BookingLPStyle.StaySelectionBox>
                <BookingLPStyle.StaySelectionBox name = {HOURLY_STAY} onClick = {()  => stayClickHandelr(HOURLY_STAY) } overnight = { isHourly() }>Hourly Stay</BookingLPStyle.StaySelectionBox>
            </BookingLPStyle.StaySelector>
        </BookingLPStyle.StaySelectorParent>
    )
}

export default MobileStaySelector;