import React from 'react';
import { useSelector } from 'react-redux';

//Material UI and Styled Imports
import * as ChatStyle from '../../../styles/Booking/chat';
import useMediaQuery from '@mui/material/useMediaQuery';

const WhatsApp = () => {
    const matches = useMediaQuery('(max-width:576px)');
    const contactDetails = useSelector(state => state.bookingDetails.contactDetails);

    let encodeContactNo = '';
    if(contactDetails)
        encodeContactNo = contactDetails.find(contact => contact.contact_type == 'Phone')?.contact_value;

    const clickHandler = () => {
        // window.open(`https://api.whatsapp.com/send?phone=${encodeURIComponent(+919971044457)}`);
        if(matches)
            window.open(`https://api.whatsapp.com/send?phone=+91${encodeContactNo}&text=Hello!`);
        else 
            window.open(`https://api.whatsapp.com/send?phone=${encodeContactNo}&text=Hello!`);
        //window.open('https://wa.me/+919599110691')
    }

    return (
        <ChatStyle.WhatsAppContainer>
            <ChatStyle.WhatsAppBox onClick = { clickHandler }>
                <ChatStyle.WhatsAPPICON></ChatStyle.WhatsAPPICON>
            </ChatStyle.WhatsAppBox>
        </ChatStyle.WhatsAppContainer>
    )
}

export default WhatsApp;