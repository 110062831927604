import styled from "styled-components";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const CarouselBox = styled(Box)`
&& {
    height:300px;
    padding: 0 1rem;
    background: white;
    position:relative;
    @media(max-width:576px) {
        height: 230px;
    };
    & .MuiButtonBase-root {
        background-color:white;
    };
    & .MuiSvgIcon-root {
        color:black;
    };
}`

export const CarouselBackButton = styled(CloseIcon)`
&& {
    z-index:2;
    right: 20px;
    top: 5px;
    position: absolute;
    color: black;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    font-size: 1.5rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 5px 0px;
}`

    // width: 102%;
    // left: -1%;
export const SquareImage = styled.img`
&& {
    height:300px;
    width:100%;

    position: relative;
    
    border-radius:4px;
    @media(max-width:576px) {
        height: 230px;
    };
}`

export const HotelName = styled(Typography)`
&& {
    font-size:1.4rem;
    font-weight:700;
    color:black;
}
`

export const HotelAddressBox = styled(Box)`
&& {
    display:flex;
}`

export const HotelAddressImg = styled(Box)`
&& {
    background-image: url(styles/assets/location.svg);
    background-repeat: no-repeat;
    height: 25px;
    min-width: 25px;
    display: flex;
    background-position: center;
}`

export const HotelNumberImg = styled(HotelAddressImg)`
&& {
    background-image: url(styles/assets/phone.svg);
}`

export const ImageRow = styled(Box)`
&& {
    height:150px;
    width:100%;
    display:flex;
    border-radius: 0 0 4px 4px;
    @media(max-width:576px) {
        height: 115px;
    };
}
`

export const TopImageRow = styled(ImageRow)`
&& {
    border-bottom:3px solid white;
    border-radius: 4px 4px 0 0;
}`

export const TopImage = styled.img`
&& {
    height:100%;
    width:50%;
}
`

export const TopLeftImage = styled.img`
&& {
    height:100%;
    width:100%;
    cursor:pointer;
}`

export const TopLeftImageCont = styled(Box)`
&& {
    height:150px;
    width:50%;
    border-right:3px solid white;
    border-bottom: 3px solid white;
    border-radius: 4px 0 0 0;
    @media(max-width:576px) {
        height: 115px;
    };
}`

export const BottomImage = styled.img`
&& {
    max-height:100%;
    width:33%;
    cursor:pointer;
}
`
export const BottomMiddleImage = styled.img`
&& {
    height:100%;
    width:100%;
    cursor:pointer;
}`

export const LastImage = styled.img`
&& {
    height:100%;
    width:100%;
    cursor:pointer;
}
`

export const LastImgContainer = styled(Box)`
&& {
    height:100%;
    width:34%;
    position:relative;
}`

export const LastImgText = styled(Typography)`
&& {
    position: absolute;
    color: white;
    top: 45%;
    left: 45%;  
}`

export const MiddleImageCont = styled(Box)`
&& {
    height:150px;
    width:33%;
    border-left:3px solid white;
    border-right:3px solid white;
    @media(max-width:576px) {
        height: 115px;
    };
}`