import React , { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { format } from 'date-fns'; 
import OutsideClickHandler from 'react-outside-click-handler';
import dayjs from 'dayjs';

//Internal Imports
import RoomSelectionDropDown from './RoomSelectionDropDown';
import { 
    setDateRange,
    setStartDate,
    setCheckinTime 
} from '../../../slices/bookingDetails';
import { setHotelRoomDetails } from '../../../slices/bookingDetails';
import API from '../../../api';

//Style Imports 
import * as RSStyle from '../../../styles/Booking/roomSelectionStyle';
import "rsuite/dist/rsuite.css";import Portal from '@mui/material/Portal'
import { OVERNIGHT_STAY } from '../../../config/constants';

const RoomSelection = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showDropdown, setShowDropdown] = useState(false);

    const [isAddressBarCollapsed, setIsAddressBarCollapsed] = useState(false);

    const selectionInfo = useSelector((state) => {
        return state.bookingDetails.selectionCriteria;
    });

    const STAY_TYPE = useSelector(state => state.bookingDetails.STAY_TYPE);

    const hotelId = useSelector(state => state.bookingDetails.hotelId);

    const hotelDetails = useSelector(state => state.bookingDetails.hotelDetails);

    // const { beforeToday } = DateRangePicker;

    const dateChangeHandler = (date,dateString) => {
        let dateRange = [];
        dateRange.push(dayjs(new Date(date[0])).format("YYYY-MM-DD"));
        dateRange.push(dayjs(date[1]).format("YYYY-MM-DD"));
        dispatch(setDateRange(dateRange));
    }

    const startDateChangeHandler = (date,dateString) => {
        let startDate;
        startDate = (dayjs(new Date(date)).format("YYYY-MM-DD"));
        dispatch(setStartDate(dateRange));
    }

    useEffect(() => {
        const handleResize = () => {
          const bodyHeight = document.body.clientHeight;
          setIsAddressBarCollapsed(!isAddressBarCollapsed);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const getRoomDetails = () => {
        let promiseArray = [];
        promiseArray.push(API.get(`/getHotelRoomsByHotelId?hotel_id=${hotelId}`));
        promiseArray.push(API.get(`/getHotelRoomCategoriesByHotelId?hotel_id=${hotelId}`));
        promiseArray.push(API.get(`/getHotelRoomPhotosUsingHotelId?hotel_id=${hotelId}`));
        Promise.all(promiseArray).then((res) => {
            let hotelRooms = res[0].data.data;
            let hotelCategory = res[1].data.data;
            let hotelRoomImages = res[2].data.data;
            props.scrollToSecondComponent();
            dispatch(setHotelRoomDetails({
                hotelRooms,
                hotelCategory,
                hotelRoomImages
            }));

            // setTimeout(() => {
            //     history.push('/roomDetails');
            // },1000);
        });
    }

    const openDropdown = () => {
        if(!showDropdown)
            setShowDropdown(true);
    }

    const closeDropdown = () => {
        setShowDropdown(false);
    }

    const pluralizeWord = (number,word) => {
        if(word == 'Room')
            return number > 1 ? (' ' + word + 's, ') :  ' ' + word + ', ';
        else
            return number > 1 ? (' ' + word + 's ') :  ' ' + word + ' ';
    }

    const pluralizeChild = (number) => {
        return number > 1 ? (' ' + 'Children' ) : ' ' + 'Child '
    }

    const getSelectionCriteriaText = () => {
         let adults = pluralizeWord(selectionInfo.roomInfo.Adults, 'Adult') ;
         let children = pluralizeChild(selectionInfo.roomInfo.Children);
         let rooms = pluralizeWord(selectionInfo.roomInfo.Rooms, 'Room');
        return (
            <>
                <span>{selectionInfo.roomInfo.Rooms}</span>
                { pluralizeWord(selectionInfo.roomInfo.Rooms, 'Room') }
                <span>{selectionInfo.roomInfo.Adults}</span>
                { pluralizeWord(selectionInfo.roomInfo.Adults, 'Adult') }
                <span>{selectionInfo.roomInfo.Children}</span>
                { pluralizeChild(selectionInfo.roomInfo.Children) }
                <span>{selectionInfo.roomInfo.Infant}</span>
                { pluralizeWord(selectionInfo.roomInfo.Infant, 'Infant') }
            </>
        )
    }

    const disabledDate = (current) => {
        const today = dayjs().startOf('day');
        const sixMonthsFromNow = dayjs().add(6, 'months').startOf('day');
        return current.isBefore(today) || current.isAfter(sixMonthsFromNow);
    };

    const changeTimeHandler = (selectedTime) => {
        let time = (dayjs(new Date(selectedTime)).format("HH:mm a"));
        dispatch(setCheckinTime(time));
    };

    const getCheckinTime = () => {
        let currDate = new Date();
        let hoursMin = ( currDate.getMinutes() > 0 ? (currDate.getHours() + 1) : currDate.getHours() ) + ':' + currDate.getMinutes() + ' A';
        if(selectionInfo.hourlySelectionInfo.checkInTime)
            return dayjs(selectionInfo.hourlySelectionInfo.checkInTime,'h:mm A');
        return dayjs(hoursMin,'h:mm A');
    }

    const getDatePicker = () => {
        if(STAY_TYPE == OVERNIGHT_STAY)
            return(
                <RSStyle.DateParent>
                    <RSStyle.DateRangePicker format = 'DD MMM YYYY' bordered = {false} size = 'large' 
                        disabledDate = { disabledDate } value = {[dayjs(selectionInfo.dateRange[0],'YYYY/MM/DD'),dayjs(selectionInfo.dateRange[1],'YYYY/MM/DD')]}
                        onChange = { dateChangeHandler }
                    >
                    </RSStyle.DateRangePicker>
                    <RSStyle.DateIconsWrapper>
                        <RSStyle.StartDateIcon></RSStyle.StartDateIcon>
                        <RSStyle.EndDateIcon></RSStyle.EndDateIcon>
                    </RSStyle.DateIconsWrapper>
                </RSStyle.DateParent>
            )
        else {
            return(
                <RSStyle.TimeParent>
                    <RSStyle.StartDatePicker format = 'DD MMM YYYY' bordered = {false} size = 'large' 
                        disabledDate = { disabledDate } value = {dayjs(selectionInfo.hourlySelectionInfo.startDate,'YYYY/MM/DD')}
                        onChange = { startDateChangeHandler }
                    >
                    </RSStyle.StartDatePicker>
                    <RSStyle.Separator></RSStyle.Separator>
                    <RSStyle.StartTimePicker  value = { getCheckinTime() } onChange = { changeTimeHandler } popupClassName = 'modify-time-pos' 
                        placement = "bottomCenter" format = 'h A' bordered = {false} size = 'large' >
                    </RSStyle.StartTimePicker>
                    <RSStyle.DateIconsWrapper>
                        <RSStyle.StartDateIcon></RSStyle.StartDateIcon>
                        <RSStyle.TimeIcon></RSStyle.TimeIcon>
                    </RSStyle.DateIconsWrapper>
                </RSStyle.TimeParent>
            )
        }
    }


    return (
        <RSStyle.RoomSelectionParent>
            <RSStyle.RoomSelectionRow>
                <RSStyle.HotelSelectionParent>
                    <RSStyle.HotelLocation>
                        { hotelDetails.city }
                    </RSStyle.HotelLocation>
                    <RSStyle.HotelName>
                        { hotelDetails.name }
                    </RSStyle.HotelName>
                </RSStyle.HotelSelectionParent>
                { getDatePicker() }
                <RSStyle.CriteriaSelectionBox onClick={ openDropdown }>
                    { getSelectionCriteriaText() }
                </RSStyle.CriteriaSelectionBox>
                <RSStyle.CheckAvail onClick = { getRoomDetails }>
                    Check Availability
                </RSStyle.CheckAvail>
            </RSStyle.RoomSelectionRow>
            { showDropdown && 
                <Portal>
                    <RSStyle.RoomSelectionRowAbs>
                        <OutsideClickHandler onOutsideClick={ closeDropdown }>
                            <RoomSelectionDropDown closeDropdown = { closeDropdown }/>
                        </OutsideClickHandler>
                    </RSStyle.RoomSelectionRowAbs>
                </Portal>
            }
        </RSStyle.RoomSelectionParent>
    )
}

export default RoomSelection;