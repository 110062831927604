import React from 'react';
import { useSelector } from 'react-redux';

//Style Imports
import * as BookingRulesStyle from '../../../../styles/Booking/bookingLPStyle';

//internal imports
import { HOURLY_STAY, OVERNIGHT_STAY } from '../../../../config/constants';

const BookingLPRules = (props) => {
    const hotelInfo = useSelector(state => {
        return state.bookingDetails.hotelDetails;
    })

    const hotelRules = useSelector(state => {
        return state.bookingDetails.hotelRules;
    })

    const cart = useSelector((state) => state.bookingDetails.cart);

    const getTiming = () => {
        //const timings = `Check-in ${hotelInfo.attributes["check-in"]} Check-out ${hotelInfo.attributes["check-out"]}`
        const timings = `Check-in 2 PM Check-out 11 AM`;
        return timings;
    }

    const getPropertyRules = () => {
        let rules = hotelRules.map(rule => {
            return (
                <BookingRulesStyle.BookingRulesItem key = {rule.hotel_rule_id}>
                    { rule.rule_description }
                </BookingRulesStyle.BookingRulesItem>
            )
        });
        return rules;
    }

    const hasCart = () => {
        if(Object.keys(cart).length > 0) {
            let roomCount = 0;
            for(var o in cart) {
                let roomType = cart[o];
                for(var room in roomType) {
                    let roomsOcc = roomType[room];
                    roomCount += roomsOcc.length;
                }
            }
            if(roomCount > 0) {
                return {
                    hasCart : true
                }
            }
        }
    }

    return (
        <BookingRulesStyle.BookingRules { ...hasCart() }>
            <BookingRulesStyle.BookingRulesHeader>
                { "Property Rules" }
            </BookingRulesStyle.BookingRulesHeader>
            { (props.stayType == OVERNIGHT_STAY) &&
                <BookingRulesStyle.BookingRulesTime>
                    { getTiming() }
                </BookingRulesStyle.BookingRulesTime>
            }
            <ul style = {{paddingTop : '1rem'}}>
                { getPropertyRules() }
            </ul>
        </BookingRulesStyle.BookingRules>
       
    )
}

export default BookingLPRules;