import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { format } from 'date-fns';

//Style and MUI Imports
import * as MobileCheckoutStyle from '../../../styles/BookingMobile/mobileCheckoutStyle';
import * as MCStyle from '../../../styles/BookingMobile/mobileCancelStyle';
import * as BookingHIStyle from '../../../styles/Booking/bookingLPStyle';
import * as MobileHIStyle from '../../../styles/Booking/mobileLPStyle';

//Internal Imports
import MobileFooter from '../LandingPageDetails/LandingPageMobile/MobileFooter';
import MobileCheckoutDuration from '../CheckoutMobile/MobileCheckoutDuration';
import API from '../../../api';
import { SNSAPI } from '../../../api';


const MobileCancelDetails = (props) => {

    const [showMessage, setShowMessage] = useState(false);

    let hotel_id = useSelector(state => state.bookingDetails.hotelId);

    const hotelDetails = useSelector(state => state.bookingDetails.hotelDetails);

    const contactDetails = useSelector(state => state.bookingDetails.contactDetails);

    const hotelEmployees = useSelector(state => state.bookingDetails.hotelEmployees);

    const bookingInfo = props.location.state;

    const hotelImages = useSelector(state => state.bookingDetails.hotelPhotos);

    const contactInfo = useSelector(state => state.bookingDetails.contactDetails?.find(contact => contact.contact_type == 'Phone'));

    const hotelInfo = useSelector((state) => {
        return state.bookingDetails.hotelDetails;
    });

    const confirmCancellationHandler = (event) => {
        API.post('cancelBooking', {
            mobile:bookingInfo.guest_mobile_number,
            booking_id:bookingInfo.generated_booking_id,
        }).then((res) => {
            sendMail()
        })
    }

    const sendMail = () => {
        let mailParams = {
            subject:`New reservation received | Guest Name: ${bookingInfo.guest_name} | Booking ID: ${bookingInfo.generated_booking_id}`,
            heading:`Date: ${dayjs(bookingInfo.check_in_date).format('MMM DD')} - ${dayjs(bookingInfo.check_out_date).format('MMM DD')}, ${(new Date()).getFullYear()} | Booking ID: ${bookingInfo.generated_booking_id}`,
            guestName:bookingInfo.guest_name,
            checkIn:`${dayjs(bookingInfo.check_in_date).format('MMM DD YYYY')}`,
            checkInTime:`From 2:00 PM`,
            checkOut:`${dayjs(bookingInfo.check_out_date).format('MMM DD YYYY')}`,
            checkOutTime:`To 11:00 AM`,
            noOfNights: (dayjs((new Date(bookingInfo.check_out_date)))).diff(dayjs((new Date(bookingInfo.check_in_date))),'day'),
            bookingId:bookingInfo.generated_booking_id.toUpperCase(),
            hotelName:hotelDetails.name,
            hotelAddress:hotelDetails.address,
            hotelContactNo:contactDetails.find(ct => ct.contact_type == 'Phone').contact_value,
            occupancy:bookingInfo[1],
            roomCat:bookingInfo[2],
            amount:bookingInfo.total_amount,
            plan:'',
            payReceived:'0',
            mobileNumber:bookingInfo.guest_mobile_number,
            email:bookingInfo.guest_email_id,
            guestEmail:bookingInfo.guest_email_id,
            advancePaid:0
            // guestCount:getGuestCount(),
            // noOfRooms:getRoomBookingInfo(),
            // mealSelected:isMealSelected(),
            //items:roomItems
        }


        let hotelEmailTo = '';
        hotelEmployees.map(emp => {
            hotelEmailTo += (emp.email_id + ',');
        })
        hotelEmailTo = hotelEmailTo.substring(0,hotelEmailTo.length - 1);

        let startDate = dayjs(bookingInfo.check_in_date).format('DD MMM YY');
        let userMailParams = { ...mailParams}
        //userMailParams.subject = `Booking Voucher | ${hotelDetails.name}, ${hotelDetails.city} | ${dayjs(selectionCriteria.dateRange[0]).format('MMM DD YYYY')} - ${dayjs(selectionCriteria.dateRange[1]).format('MMM DD YYYY')}`;
        userMailParams.heading = `Date: ${dayjs(bookingInfo.check_in_date).format('MMM DD')} - ${dayjs(bookingInfo.check_out_date).format('MMM DD')}, ${(new Date()).getFullYear()} | Booking ID: ${bookingInfo.generated_booking_id} | Amount: INR ${bookingInfo.total_amount}`;
        userMailParams.greeting = `${bookingInfo.guest_name}, Thank you for booking with us!`
        mailParams.email = hotelEmailTo;

        SNSAPI.post('/sendNotification',{
            notificationType:'EMAIL',
            mailParams,
            templateType:'HOTELCANCELTEMPLATE',
            userMailParams:userMailParams,
            userMailTemplateType:'USERCANCELTEMPLATE'
        }).then(res => {
            setShowMessage(true);
        });
    }

    const getImg = () => {
        return (hotelImages.length > 0) ? hotelImages[0].photo : '';
    }

    const getHotelAddressText = () => {
        if(Object.keys(hotelInfo).length > 0) {
            if(hotelInfo.address.trim().endsWith(','))
                return `${hotelInfo.address} ${hotelInfo.city}, ${hotelInfo.state} - ${hotelInfo.pincode}`
            else
                return `${hotelInfo.address}, ${hotelInfo.city}, ${hotelInfo.state} - ${hotelInfo.pincode}`
        }
    }


    const getNoOfNight = () => {
        let noOfDays = (dayjs((new Date(bookingInfo.check_out_date)))).diff(dayjs((new Date(bookingInfo.check_in_date))),'day');
        if(noOfDays > 1) 
            return `${noOfDays} nights`
        return `${noOfDays} night`
    }

    const getCheckInText = () => {
        return (
            <MobileCheckoutStyle.CheckinTime>
                Check-in : 
                <span>2 PM</span>
            </MobileCheckoutStyle.CheckinTime>
        )
    }

    const getCheckOutText = () => {
        return (
            <MobileCheckoutStyle.CheckinTime>
                Check-out : 
                <span>11 AM</span>
            </MobileCheckoutStyle.CheckinTime>
        )
    }

    const getCheckinDate = () => {
        return formatDate(bookingInfo.check_in_date, 'dd MMM yy');
    }

    const getCheckoutDate = () => {
        return formatDate(bookingInfo.check_out_date, 'dd MMM yy');
    }

    const formatDate = (date,formatString) => {
        console.log(format(new Date(date), formatString));
        return format(new Date(date), formatString);
    }

    const getDatesInfo = () => {
        return (
            <MobileCheckoutStyle.CheckoutInfoParent>
                <MobileCheckoutStyle.CheckInfInfo>
                    { getCheckInText() }
                    <MobileCheckoutStyle.CheckinDate> { getCheckinDate() }</MobileCheckoutStyle.CheckinDate>
                    <MobileCheckoutStyle.CheckinDay> { formatDate(bookingInfo.check_in_date, 'EEEE') } </MobileCheckoutStyle.CheckinDay>
                </MobileCheckoutStyle.CheckInfInfo>
                <MobileCheckoutStyle.DurationInfo>
                    { getNoOfNight() }
                </MobileCheckoutStyle.DurationInfo>
                <MobileCheckoutStyle.CheckOutInfo>
                    { getCheckOutText() }
                    <MobileCheckoutStyle.CheckinDate> { getCheckoutDate() } </MobileCheckoutStyle.CheckinDate>
                    <MobileCheckoutStyle.CheckinDay> { formatDate(bookingInfo.check_out_date, 'EEEE') } </MobileCheckoutStyle.CheckinDay>
                </MobileCheckoutStyle.CheckOutInfo>
            </MobileCheckoutStyle.CheckoutInfoParent>
        )
    }

    return (
        <MCStyle.MobleCancelDetailsParent>
            { showMessage && 
                <MCStyle.ConfHeader>
                    <MobileCheckoutStyle.CongHeaderChild>
                        <MCStyle.CacncelImg></MCStyle.CacncelImg>
                        <p>
                            Your booking is cancelled.<br/>
                            Confirmation will be sent to your email.
                        </p>
                    </MobileCheckoutStyle.CongHeaderChild>
                </MCStyle.ConfHeader>
            }
            <MCStyle.DummyParentImg>
                <MobileCheckoutStyle.AddressImgParent>
                    <MobileCheckoutStyle.AddressParent>
                        <BookingHIStyle.Address>
                            <MobileHIStyle.HotelName>
                                { hotelInfo.name }
                            </MobileHIStyle.HotelName>
                            <MobileHIStyle.HotelAddressBox>
                                <MobileHIStyle.HotelAddressImg></MobileHIStyle.HotelAddressImg>
                                <BookingHIStyle.HotelAdress>
                                    { getHotelAddressText() }
                                </BookingHIStyle.HotelAdress>
                            </MobileHIStyle.HotelAddressBox>
                            <MobileHIStyle.HotelAddressBox>
                                <MobileHIStyle.HotelNumberImg></MobileHIStyle.HotelNumberImg>
                                <BookingHIStyle.HotelNumber onClick = {() => window.open(`tel:+91${contactInfo?.contact_value}`,'_blank')}>
                                    { contactInfo?.contact_value }
                                </BookingHIStyle.HotelNumber>
                            </MobileHIStyle.HotelAddressBox>
                        </BookingHIStyle.Address>
                    </MobileCheckoutStyle.AddressParent>
                    <MobileCheckoutStyle.ConfirmHotelImg src = { getImg() }></MobileCheckoutStyle.ConfirmHotelImg>
                </MobileCheckoutStyle.AddressImgParent>
            </MCStyle.DummyParentImg>
            { getDatesInfo() }
            <MobileCheckoutStyle.RoomsGuestParent>
                <MobileCheckoutStyle.RGHeader>Booking ID</MobileCheckoutStyle.RGHeader>
                <MobileCheckoutStyle.BIDText>{ bookingInfo.generated_booking_id }</MobileCheckoutStyle.BIDText>
            </MobileCheckoutStyle.RoomsGuestParent>
            {/* <MobileCheckoutStyle.RoomsGuestParent>
                <MobileCheckoutStyle.RGHeader>Rooms & Guests</MobileCheckoutStyle.RGHeader>
                <MobileCheckoutStyle.RGText> { PropertyDetailsParentBox. }</MobileCheckoutStyle.RGText>
            </MobileCheckoutStyle.RoomsGuestParent> */}
            <MobileCheckoutStyle.PaymentInfoParent>
                <MobileCheckoutStyle.PaymentLabel>
                    Payment Details
                </MobileCheckoutStyle.PaymentLabel>
                <MobileCheckoutStyle.PaymentInfo>
                    <MobileCheckoutStyle.PriceMode>
                        Total Booking Amount
                    </MobileCheckoutStyle.PriceMode>
                    <MobileCheckoutStyle.Price>
                        <span>&#x20B9;</span>
                        { numeral(bookingInfo.total_amount).format('0,0') }
                    </MobileCheckoutStyle.Price>
                </MobileCheckoutStyle.PaymentInfo>
                <MobileCheckoutStyle.PaymentInfo>
                    <MobileCheckoutStyle.PriceMode>
                        Amount Paid
                    </MobileCheckoutStyle.PriceMode>
                    <MobileCheckoutStyle.Price>
                        <span>&#x20B9;</span>
                        { 0 }
                    </MobileCheckoutStyle.Price>
                </MobileCheckoutStyle.PaymentInfo>
                <MobileCheckoutStyle.PaymentInfo>
                    <MobileCheckoutStyle.PriceMode>
                        Refund Amount
                    </MobileCheckoutStyle.PriceMode>
                    <MobileCheckoutStyle.Price>
                        <span>&#x20B9;</span>
                        { 0 }
                    </MobileCheckoutStyle.Price>
                </MobileCheckoutStyle.PaymentInfo>
            </MobileCheckoutStyle.PaymentInfoParent>
            { !showMessage && 
                <MCStyle.CancellationParent>
                    <MCStyle.Cancellation variant = "contained" size = "small" onClick = { confirmCancellationHandler }>
                        Confirm Cancellation
                    </MCStyle.Cancellation>
                </MCStyle.CancellationParent>
            }
            <MobileFooter/>
        </MCStyle.MobleCancelDetailsParent>
    )
}

export default MobileCancelDetails