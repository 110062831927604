import { createSlice } from "@reduxjs/toolkit";
import { nextDay } from 'date-fns'; 
import { OVERNIGHT_STAY } from "../config/constants";

const dateRange = [];


const today = new Date()
let tomorrow =  new Date()
tomorrow.setDate(today.getDate() + 1)

dateRange.push(today.toISOString());
dateRange.push(tomorrow.toISOString());

const initialState = {
    STAY_TYPE:OVERNIGHT_STAY,
    hotelDetails:{},
    hotelPhotos:[],
    hotelfacilities:[],
    hotelRules:[],
    hotelRooms:[],
    hotelCategory:[],
    hotelRoomImages:[],
    selectionCriteria:{
        dateRange,
        hourlySelectionInfo:{
            startDate:today.toISOString(),
        },
        roomInfo:{
            Adults:1,
            Children:0,
            Rooms:1,
            Infant:0
        },
        roomTypeInfo:{
        },
        guestDetails:[]
    },
    //hotelId:(window.location.origin.indexOf('localhost') > -1 ? 1 : -1),
    hotelId:-1,
    cart:{},
    selectedRooms:[],
    cartMobile:{}
};

const bookingDetailsSlice = createSlice({
    name:'bookingDetails',
    initialState,
    reducers:{
        setBookingDetails:(state,action) => {
            state.hotelDetails = action.payload.hotelDetails;
            state.hotelPhotos = action.payload.hotelPhotos;
            state.hotelfacilities = action.payload.hotelfacilities;
            state.hotelRules = action.payload.hotelRules;
            state.contactDetails = action.payload.contactDetails;
            state.hotelEmployees = action.payload.hotelEmployees;
            if(state.hotelId != action.payload.currentHotelId)
                state.hotelId = action.payload.currentHotelId;
        },
        setHotelRoomDetails:(state,action) => {
            state.hotelRooms = action.payload.hotelRooms;
            state.hotelCategory = action.payload.hotelCategory;
            state.hotelRoomImages = action.payload.hotelRoomImages;
            state.cart = {};
            state.hotelRoomPricesByCategory = action.payload.hotelRoomPricesByCategory;
            state.mealDetails = action.payload.mealDetails;
        },
        setCriteriaValue:(state,action) => {
            state.selectionCriteria.roomInfo = action.payload;
        },
        setDateRange:(state,action) => {
            state.selectionCriteria.dateRange = action.payload;
        },
        setRoomTypeInfo:(state,action) => {
            state.selectionCriteria.roomTypeInfo.roomCategory = action.payload.roomCategory;
            state.selectionCriteria.roomTypeInfo.hotelRoom = action.payload.hotelRoom;
        },
        setGuestDetails:(state,action) => {
            state.selectionCriteria.guestDetails = action.payload;
        },
        addRoomsToCart:(state,action) => {
            state.cart[action.payload.roomType] = action.payload.roomCards;
        },
        updateMealsInRoom:(state,action) =>{
            let room = state.cart[action.payload.name][action.payload.occupancy].find(room => room.id == action.payload.uuid);
            room.mealInfo = action.payload.mealInfo;
        },
        removeMealTypeInRoom:(state,action) => {
            let room = state.cart[action.payload.name][action.payload.occupancy].find(room => room.id == action.payload.uuid);
            room.mealInfo = action.payload.mealInfo;
        },
        setHotelId:(state,action) => {
            state.hotelId = action.payload.hotelId;
        },
        addSelectedRooms:(state,action) => {
            state.selectedRooms.push(action.payload);
        },
        addRoomsToCartMobile:(state,action) => {
            state.cartMobile = action.payload;
        },
        clearCart:(state,action) => {
            state.cart = {}
        },
        setOverNightStay:(state,action) => {
            state.STAY_TYPE = action.payload;
        },
        setStartDate:(state,action) => {
            state.selectionCriteria.hourlySelectionInfo.startDate = action.payload;
        },
        setCheckinTime:(state,action) => {
            state.selectionCriteria.hourlySelectionInfo.checkInTime = action.payload;
        }
    }
})

export const {
    setBookingDetails,
    setHotelRoomDetails,
    setCriteriaValue,
    setDateRange,
    setRoomTypeInfo,
    setGuestDetails,
    addRoomsToCart,
    updateMealsInRoom,
    removeMealTypeInRoom,
    setHotelId,
    addSelectedRooms,
    addRoomsToCartMobile,
    clearCart,
    setOverNightStay,
    setCheckinTime,
    setStartDate
} = bookingDetailsSlice.actions;

export default bookingDetailsSlice.reducer;