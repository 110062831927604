import styled from "styled-components";
import Box from '@mui/material/Box';

export const MobileFooter = styled(Box)`
&& {
    width:100%;
    background: #E4E4E4;
    height:40px;
    display:flex;
}`

export const FooterText = styled(Box)`
&& {
    color: #141414;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Red Hat Display;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height:40px;
    width: 50%;
    display:flex;
    align-items:center;
    justify-content: flex-end;
}`

export const FooterImg = styled(Box)`
&& {
    width:50%;
    height: 40px;
    background-image: url(styles/assets/hoteliologo.svg);
    background-repeat: no-repeat; 
    background-position: 0% -60%;
    background-size:35%;
    @media(max-width:576px) {
        background-size:50%;
        background-position: 0% 130%;
    }
}`