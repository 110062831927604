import styled from 'styled-components';
import Button from '@mui/material/Button';

export const ComfirmButton = styled(Button)`
&& {
    text-transform: capitalize;
    font-family: 'Red Hat Display';
    font-size: 1rem;
    font-weight: 500;
    color: black;
}`